import styled from 'styled-components';

interface ButtonInterface {
  opacity?: number,
}

export const Button = styled.button<ButtonInterface>`
  width:100%;
  height: 100px;
  background: #FFFFFF;
  box-shadow: 0px 14px 19px rgba(32, 32, 32, 0.07), 0px 3.12708px 4.56854px rgba(32, 32, 32, 0.0384537), 0px 0.931014px 1.20809px rgba(32, 32, 32, 0.0229063);
  border-radius: 24px;
  display:flex;
  align-items:center;
  padding: 0px 16px;
  border-width:0px;
  margin-bottom:16px;
  text-align:start;
  opacity: ${props => props.opacity?? 1};

  p{
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 30px;
    color: #ED1E79;
    margin-left:24px;
  }

  transition: 0.5s;
  
  :hover{
    background:rgb(237 30 121 / 17%)
  }

  :disabled{
    opacity: 0.2
  }
`;

export const BorderIcon = styled.button`
  display:flex;
  justify-content:center;
  align-items:center;
  width: 68px;
  height: 68px;
  background: #ED1E79;
  border-radius: 24px;
  border-width:0px;
`;