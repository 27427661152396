import { Box, Grid } from "@material-ui/core";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { useState } from "react";

import { useHistory } from "react-router";

//Redux
import { useDispatch, useSelector } from "react-redux";

//Local Imports
import { IsaButton } from "../../shared/components/IsaButton/IsaButton";
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { IsaDialog } from "../../shared/components/IsaDialog/isaDialog";
import { IsaH3Text } from "../../shared/components/IsaH3Text/IsaH3Text";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";
import { IsaInput } from "../../shared/components/IsaInput/isaInput";
import IsaLoader from "../../shared/components/IsaLoader/isaLoader";
import { IsaPText } from "../../shared/components/IsaPText/IsaPText";
import { validateEmail } from "../../shared/validators/emailValidator";

//Logo
import IcoBack from "../../shared/assets/ico_back.svg";
import { sendEmailToRecover } from "./redux/resetPasswordActions";

//Css
import "./resetPassword.css";

interface MessageInterface {
  messageBody: string;
  messageButton: string;
  messageHeader: string;
}

export function ResetPassword() {
  const history = useHistory();
  const dispatch = useDispatch();

  const wrongPass = useSelector(
    (state: any) => state.CreateNewPasswordReducer?.wrongPass
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [validEmail, setValidEmail] = useState({
    background: "#FFF",
    borderColor: "#DDDDDD",
    border: "#DDDDDD",
    color: "#000000",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [openToggle, setOpenToggle] = useState(false);

  const [email, setEmail] = useState("");

  const [message] = useState<MessageInterface>({
    messageBody: "",
    messageButton: "",
    messageHeader: "",
  });

  const Disabled = () => {
    if (validateEmail(email)) {
      return false;
    } else {
      return true;
    }
  };

  const emailValidate = (email: any) => {
    setEmail(email);
    if (validateEmail(email) && email !== "") {
      setValidEmail({
        background: "rgba(0,198,67,0.05)",
        borderColor: "#00C643",
        border: "#00C643",
        color: "#000000",
      });
    } else if (email !== "") {
      setValidEmail({
        background: "rgba(233, 59, 90, 0.05)",
        borderColor: "#E93B5A",
        border: "#E93B5A",
        color: "#E93B5A",
      });
    }
  };

  const handleContinue = () => {
    setIsOpen(true);
    let obj = {
      email: email.trim(),
    };

    dispatch(
      sendEmailToRecover(obj, (res: any) => {
        const error =
          (res?.data?.errors || []).length > 0
            ? res?.data?.errors[0].message
            : "";

        let lowercaseString = error.toLowerCase();
        const msgError =
          lowercaseString.charAt(0).toUpperCase() + lowercaseString.slice(1);

        if (res?.data?.message) {
          setValidEmail({
            background: "rgba(233, 59, 90, 0.05)",
            borderColor: "#E93B5A",
            border: "#E93B5A",
            color: "#E93B5A",
          });
          setEmail("Email não cadastrado. Informe um email válido");
          setIsOpen(false);
        } else if (error) {
          setValidEmail({
            background: "rgba(233, 59, 90, 0.05)",
            borderColor: "#E93B5A",
            border: "#E93B5A",
            color: "#E93B5A",
          });
          setEmail(
            msgError || "Ocorreu um erro ao enviar o link de reset da senha"
          );
          setIsOpen(false);
        } else {
          localStorage.setItem("loginEmail", email);
          setOpenToggle(true);
          setIsOpen(false);
        }
      })
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IsaLoader open={isOpen} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openToggle}
        autoHideDuration={null}
        onClose={() => setOpenToggle(false)}
        message="Você receberá no seu email as instruções para redefinir sua senha"
        action={
          <>
            <IconButton
              style={{ color: "#00C643" }}
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() => setOpenToggle(false)}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </>
        }
      />
      <IsaDialog
        open={isDialogOpen}
        header={message.messageHeader}
        bodyText={message.messageBody}
        buttonText={message.messageButton}
        closeDialog={() => setIsDialogOpen(false)}
      />
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/conta")}
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName="Sua conta"
      />
      <Grid
        style={{ height: "100%" }}
        container
        direction="column"
        justifyContent="start"
        alignItems="center"
      >
        <IsaContainer>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <div style={{ marginTop: 67 }} />
            <Grid
              container
              maxWidth={720}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <IsaH3Text color="#004474" textAlign="start">
                Redefinição de senha
              </IsaH3Text>
              <div style={{ marginBottom: 24 }} />
              <IsaPText color="#666666" textAlign="start">
                {!wrongPass ? (
                  <>
                    Insira o email que você usou no cadastro. Nós mandaremos um
                    email com um link para você redefinir sua senha.
                  </>
                ) : (
                  <>
                    <span style={{ color: "#ED1E79", fontSize: 16 }}>
                      Seu acesso para redefinir sua senha expirou
                    </span>
                    , insira novamente o email que você usou no cadastro. Nós
                    mandaremos outro email com um link para você redefinir sua
                    senha.
                  </>
                )}
              </IsaPText>
              <div style={{ marginBottom: 24 }} />
              <IsaInput
                background={validEmail.background}
                borderColor={validEmail.borderColor}
                border={validEmail.border}
                color={validEmail.color}
                inputTitle="Email"
                value={email}
                onChange={(e: any) =>
                  emailValidate(e.target.value.toLowerCase())
                }
                onClick={() => setEmail("")}
              />
              <div style={{ marginTop: 200 }} />
              <IsaButton disabled={Disabled()} onClick={() => handleContinue()}>
                Enviar
              </IsaButton>
              <div style={{ marginTop: 38 }} />
            </Grid>
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
