import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root {
    --background: #ffffff
  }
  * {
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
  }

  html {
    @media (max-width: 1080px){
      font-size: 93.75%;
    }

    @media (max-width: 720px){
      font-size: 87.5%;
    }
  }

  body {
    background: var(--background);
    -webkit-font-smoothing: antialiased;
  }

  h3 {
    font-family:'Titillium Web';
    font-weight:600;
  }

  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    color: #666666;
  }

  p {
    font-weight: 600;
    font-size: 1.3rem;
  }

  span {
    font-size:0.875rem;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    cursor: not-allowed;
  }
  input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    
  }
  input[type=number] { 
    -moz-appearance: textfield;
    appearance: textfield;

  }
  
`;
