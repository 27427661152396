import { apiIsa,authToken } from '../../../core/services/api';
import { 
  CHECK_TOKEN,
  SAVE_WRONG_PASS
} from './createNewPasswordConstants';

const setCheckTokenEnd = (dispatch:any, data:any) => {
  dispatch({type: CHECK_TOKEN, data})
};

export const saveWrongPass = (data:boolean,callback:any) => {
  return (dispatch:any) => {
    dispatch({type: SAVE_WRONG_PASS, data})
    callback('salvo');
  }
};

export const setCheckToken = (token:any,callback: any) => {
	return (dispatch:any) => {
    apiIsa.post('/user/password/checkToken',token, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${authToken}`,
      }
    })
    .then((responseJson) => {
      setCheckTokenEnd(dispatch,responseJson?.data)
      callback(responseJson?.data)
    })
    .catch((err) => {
      callback(err.response);
    });
	};
};

export const changePassword = (token:string, payload:any, callback: any) => {
	return (dispatch:any) => {
    apiIsa.put(`/user/password/token?token=${token}`,payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${authToken}`,
      }
    })
    .then((responseJson) => {
      setCheckTokenEnd(dispatch,responseJson?.data)
      callback(responseJson?.data)
    })
    .catch((err) => {
      callback(err.response);
    });
	};
};