import {
  SAVE_PATIENT_DATA
} from './patientConstants';

const INITIAL_STATE = {
  patientData: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SAVE_PATIENT_DATA:
      var _patientData
      _patientData = action.data
      const data = JSON.stringify(action.data)
      localStorage.setItem('patientData',data)
      return {
        ...state,
        patientData: _patientData
      }
  }

  return state;
};