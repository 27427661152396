//Css
import { Button, HighlightButton } from "./IsaButtonStyle";

import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { IsaHighlightProps } from "./isaButtonInterface";

export function IsaButton(props: any) {
  return (
    <Button
      onClick={props.onClick}
      disabled={props.disabled}
      background={props.background}
      borderColor={props.borderColor}
      color={props.color}
      data-test={props.dataTest}
    >
      <div></div>
      {props.children}
      <KeyboardArrowRightRoundedIcon />
    </Button>
  );
}

export function IsaHighlightButton({
  onClick,
  disabled,
  background,
  showIcon,
  color,
  shadow,
  border,
  icon,
  children,
}: IsaHighlightProps) {
  return (
    <HighlightButton
      onClick={onClick}
      disabled={disabled}
      background={background}
      showIcon={showIcon}
      color={color}
      shadow={shadow}
      border={border}
    >
      {showIcon ? (
        <>
          {icon}
          <div style={{ marginRight: 16 }} />
        </>
      ) : (
        ""
      )}
      {children}
      {showIcon ? <div></div> : ""}
    </HighlightButton>
  );
}
