import styled from 'styled-components';

interface InputProps {
  borderColor: string,
  background: string,
  border: string,
  color: string
}

export const Input = styled.div<InputProps>`
  width: 100%;
  height: 50px;
  background: ${props => props.background};
  border: 1px solid ${props => props.border ?? '#DDDDDD'} ;
  border-color: ${props => props.borderColor ?? '#DDDDDD'};
  box-sizing: border-box;
  border-radius: 4px;
  padding:0px 10px;
  align-content:center;
  display:flex;
  justify-content:space-between;
  color: ${props => props.color};

  input {
    border-width:0px;
    margin:10px 0px;
    outline: none;
    width:90%;
    background: transparent;
  }

  img{
    cursor:pointer;
  }

  :disabled {
    background: #f3f3f3;
  }
`;

export const ButtonAnchor = styled.button`
  color:#F1569B;
  font-weight:bold;
  background:transparent;
  border-width:0px;
  text-decoration: underline;
`;

export const DivInput = styled.div`
  display:flex;
  justify-content:space-between;
  width:100%;

  p{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #666666;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  bottom: 32px;
  position: fixed;
  max-width: 720px;
  padding: 0px 26px
`;