/* eslint-disable react-hooks/exhaustive-deps */
import { Box,Grid } from "@material-ui/core";

//Redux
import { useSelector } from "react-redux";

//Local Imports
import { IsaContainer } from '../IsaContainer/IsaContainer';

//Css
import {
  Breadcrumbs,
  ContainerBreadcrumbs,
  DivBalls
} from './isaBreadcrumbsStyle'


export function IsaBreadcrumbs(props: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setServiceAttributes = useSelector((state:any) => state.SelectedReducer?.setServiceAttributes?? [])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        style={{height:'100%',marginTop:40}}
        container
        direction="column"
        justifyContent="start"
        alignItems="center"
      >
        <IsaContainer>
          <ContainerBreadcrumbs>
            {props.children}
          </ContainerBreadcrumbs>
        </IsaContainer>
      </Grid>
    </Box>
  );
}

export function Balls(props: any) {
  return (
    <DivBalls marginRight={props.marginRight}>
      <h5 style={{textAlign:'start',width:'100%'}}>
        {props.isHaveHeader ? props.header : ''}
      </h5>
      <Breadcrumbs 
        width={props.width}
        background={props.isHaveHeader ? '#00D2D4' : '#CCCCCC'}
      >
      </Breadcrumbs>
  </DivBalls>
  );
}