import {
  SAVE_WRONG_PASS
} from './createNewPasswordConstants';

const INITIAL_STATE = {
  wrongPass: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SAVE_WRONG_PASS:
      let _wrongPass = action.data
      return {
        ...state,
        wrongPass: _wrongPass
      }
  }

  return state;
};