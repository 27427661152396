/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";
import { useHistory } from "react-router";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { setProductsToCart } from "../../shared/redux/shop/shopActions";

//Local Imports
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";

//Logo
import IcoBack from "../../shared/assets/ico_back.svg";

import { CreditOrHealthInsuranceConfirmation } from "./components/creditOrHealthInsuranceConfirmation";
import { PixConfirmation } from "./components/pixConfirmation";

export function ConfirmedAppointment() {
  const history = useHistory();
  const dispatch = useDispatch();

  const setServiceAttributesStorage = localStorage.getItem(
    "setServiceAttributes"
  );
  const setServiceAttributes = setServiceAttributesStorage
    ? JSON.parse(setServiceAttributesStorage || "{}")
    : "";

  const dataAppointments = useSelector(
    (state: any) => state.ConfirmationReducer?.dataAppointments ?? []
  );

  const resetValues = () => {
    localStorage.setItem("route", "");
    localStorage.setItem("setServiceAttributes", "");
    localStorage.setItem("getAddressData", "");
    localStorage.setItem("planInfos", "");
    localStorage.setItem("patientData", "");
    localStorage.setItem("token", "");
    localStorage.setItem("exp", "");
    localStorage.setItem("subRoute", "");
    localStorage.setItem("listOfProducts", "");
    localStorage.setItem("priceValue", "");
    localStorage.setItem("getDateData", "");
    localStorage.setItem("partnerInfo", "");
    dispatch(setProductsToCart([]));

    history.push("/");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => resetValues()}
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName={setServiceAttributes.pageName ?? ""}
      />
      <Grid
        style={{ height: "100%" }}
        container
        direction="column"
        justifyContent="start"
        alignItems="center"
      >
        <IsaContainer>
          {dataAppointments?.pixQrCode ? (
            <PixConfirmation />
          ) : (
            <CreditOrHealthInsuranceConfirmation />
          )}
        </IsaContainer>
      </Grid>
    </Box>
  );
}
