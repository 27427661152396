import { useEffect, useState } from "react";
import { Alert, Box, Grid, Typography } from "@material-ui/core";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import { useHistory } from "react-router";

//Redux
import { useDispatch } from "react-redux";
import {
  changePassword,
  saveWrongPass,
  setCheckToken,
} from "./redux/createNewPasswordActions";

//Local Imports
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";
import { IsaButton } from "../../shared/components/IsaButton/IsaButton";
import { IsaH3Text } from "../../shared/components/IsaH3Text/IsaH3Text";
import { IsaPText } from "../../shared/components/IsaPText/IsaPText";
import IsaLoader from "../../shared/components/IsaLoader/isaLoader";
import { IsaDialog } from "../../shared/components/IsaDialog/isaDialog";

//Logo
import IcoBack from "../../shared/assets/ico_back.svg";
import { isExpValid, loginUser } from "../account/redux/accountActions";
import IcoEyeOpen from "../../shared/assets/ico_eyeOpen.svg";
import IcoEyeClosed from "../../shared/assets/ico_eyeClosed.svg";

//Css
import { Input } from "./createNewPasswordStyle";

interface MessageInterface {
  messageBody: string;
  messageButton: string;
  messageHeader: string;
}

export function CreateNewPassword() {
  const history = useHistory();
  const dispatch = useDispatch();

  const loginEmail = localStorage.getItem("loginEmail");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [colorInput, setColorInput] = useState({
    background: "#FFF",
    borderColor: "#DDDDDD",
    border: "#DDDDDD",
    color: "#DDDDDD",
  });

  const [isOpen, setIsOpen] = useState(false);
  const [openToggle, setOpenToggle] = useState(false);
  const [feedBack, setFeedBack] = useState("");
  const [feedBackError, setFeedBackError] = useState(false);
  const [isEqualPass, setIsEqualPass] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [urlToken, setUrlToken] = useState("");

  const [isShowEye, showEye] = useState(true);
  const [isShowEyeConfirm, setShowEyeConfirm] = useState(true);

  const [message, setMessage] = useState<MessageInterface>({
    messageBody: "",
    messageButton: "",
    messageHeader: "",
  });

  useEffect(() => {
    disableEqual();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword, confirmPass]);

  useEffect(() => {
    setColorInput({
      background: "#FFFFFF",
      borderColor: "#DDDDDD",
      border: "#DDDDDD",
      color: "#000000",
    });
    let _url = window.location.href.split("/");
    const token = _url.pop() || "";
    const tokenClean = token.replace("?new_user=false", "").replace("?new_user=true", "")
    setUrlToken(tokenClean ?? "");

    dispatch(isExpValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreatePassword = (password: any, type: string) => {
    if (type === "newPass") {
      setNewPassword(password);
    } else {
      setConfirmPass(password);
    }
  };

  const disableEqual = () => {
    if (newPassword !== "" && confirmPass !== "") {
      if (newPassword !== confirmPass) {
        setColorInput({
          background: "rgba(233, 59, 90, 0.05)",
          borderColor: "#E93B5A",
          border: "#E93B5A",
          color: "#E93B5A",
        });
        setIsEqualPass(false);
      } else if (newPassword === confirmPass && newPassword.length >= 6) {
        setColorInput({
          background: "rgba(0,198,67,0.05)",
          borderColor: "#00C643",
          border: "#00C643",
          color: "#666666",
        });
        setIsEqualPass(true);
      }
    }
  };

  const handleChangePass = () => {
    setIsOpen(true);
    let obj = {
      password: newPassword,
      confirmPassword: confirmPass,
    };

    dispatch(
      changePassword(urlToken, obj, (res: any) => {
        setIsOpen(false);
        if (res?.data?.message) {
          setOpenToggle(true);
          setFeedBack(res?.data?.message || "");
          setFeedBackError(true);
          dispatch(
            saveWrongPass(true, (res: string) => {
              setTimeout(() => {
                history.push("/resetar-senha");
              }, 2000);
            })
          );
        } else {
          setOpenToggle(true);
          setFeedBack("Senha alterada com sucesso. Permaneça na tela para realizar login.");
          setFeedBackError(false);
          setTimeout(() => {
            doLogin();
          }, 2000);
        }
      })
    );
  };

  const doLogin = () => {
    setIsOpen(true);
    const data = {
      email: loginEmail,
      password: newPassword,
    };
    dispatch(
      loginUser(data, (res: any) => {
        setIsOpen(false);
        if (res?.data?.message) {
          setIsDialogOpen(true);
          setMessage({
            messageBody: res.data.message,
            messageButton: "Fechar",
            messageHeader: "Algo deu errado",
          });
        } else {
          history.push("/");
        }
      })
    );
  };

  const handleSetPassword = (e: any, pass: string) => {
    if (pass === "newPass") {
      handleCreatePassword(e, "newPass");
    } else if (pass === "againstPassword") {
      handleCreatePassword(e, "againstPassword");
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IsaLoader open={isOpen} />
        {openToggle && (
          <Alert 
            severity={feedBackError ? "error" : "success"} 
            style={{
              maxWidth: "600px",
              position: "absolute",
              top: 40,
              left: 0,
              right: 0,
              margin: "0 auto"
            }}
          >
            <Box style={{ width: "100%", display: "flex" }}>
              <Typography>
                {feedBack || "Senha alterada com sucesso. Permaneça na tela para realizar login."}
              </Typography>
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={() => setOpenToggle(false)}
              >
                <CancelOutlinedIcon />
              </IconButton>
            </Box>
          </Alert>
        )}
      <IsaDialog
        open={isDialogOpen}
        header={message.messageHeader}
        bodyText={message.messageBody}
        buttonText={message.messageButton}
        closeDialog={() => setIsDialogOpen(false)}
      />
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/conta")}
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName="Sua conta"
      />
      <Grid
        style={{ height: "100%" }}
        container
        direction="column"
        justifyContent="start"
        alignItems="center"
      >
        <IsaContainer>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <div style={{ marginTop: 67 }} />
            <Grid
              container
              maxWidth={720}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <IsaH3Text color="#004474" textAlign="start">
                Redefinição de senha
              </IsaH3Text>
              <div style={{ marginBottom: 24 }} />
              <IsaPText color="#666666" textAlign="start">
                A nova senha deve conter no mínimo 6 caracteres
              </IsaPText>
              <div style={{ marginBottom: 24 }} />
              <h5 style={{ textAlign: "start", width: "100%" }}>Nova senha</h5>
              <div style={{ marginBottom: 8 }} />
              <Input
                background={colorInput.background}
                borderColor={colorInput.borderColor}
                border={colorInput.border}
                color={colorInput.color}
              >
                <input
                  type={isShowEye === true ? "password" : "text"}
                  onChange={(e: any) =>
                    handleSetPassword(e.target.value, "newPass")
                  }
                  value={newPassword}
                />
                <img
                  onClick={() => showEye(!isShowEye)}
                  alt="icon"
                  src={isShowEye === true ? IcoEyeOpen : IcoEyeClosed}
                />
              </Input>
              <div style={{ marginBottom: 24 }} />
              <h5 style={{ textAlign: "start", width: "100%" }}>
                Confirmar senha
              </h5>
              <div style={{ marginBottom: 8 }} />
              <Input
                background={colorInput.background}
                borderColor={colorInput.borderColor}
                border={colorInput.border}
                color={colorInput.color}
              >
                <input
                  type={isShowEyeConfirm === true ? "password" : "text"}
                  onChange={(e: any) =>
                    handleSetPassword(e.target.value, "againstPassword")
                  }
                  value={confirmPass}
                />
                <img
                  onClick={() => setShowEyeConfirm(!isShowEyeConfirm)}
                  alt="icon"
                  src={isShowEyeConfirm === true ? IcoEyeOpen : IcoEyeClosed}
                />
              </Input>
              <div style={{ marginTop: 170 }} />
              <IsaButton
                disabled={!isEqualPass}
                onClick={() => handleChangePass()}
              >
                Alterar senha
              </IsaButton>
              <div style={{ marginTop: 38 }} />
            </Grid>
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
