import styled from 'styled-components';
interface SelectProps {
  borderColor: string,
  background: string,
  justifyContent: string
}

export const Select = styled.button<SelectProps>`
  width: 100%;
  height: 50px;
  display:flex;
  align-items:center;
  justify-content:${props => props.justifyContent};
  background: ${props => props.background};
  border: 1px solid ${props => props.borderColor};
  box-sizing: border-box;
  border-radius: 4px;
  padding:0px 10px;
  text-align:start;
`;

export const ContainerSelect = styled.div`
  margin-top:8px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 6px;
  width:100%;
`;

export const OptionDiv = styled.div`
  padding-top:8px;
  padding-bottom:8px;
  padding-left:26px;
  transition: 0.3s;
  cursor:pointer;  

  :hover {
    background: #F1F3F4;
  }
`;