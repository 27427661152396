
//Css
import {
  Text
} from './IsaPTextStyle'

export function IsaPText(props: any) {
  return (
    <Text
      fontSize={props.fontSize}
      color={props.color}
      textAlign={props.textAlign}
      fontWeight={props.fontWeight ? props.fontWeight : 'normal'}
    >
      {props.children}
    </Text>
  );
}