import { Select, MenuItem, FormHelperText } from "@mui/material";
import * as S from "./style";
import { PatientRelationSelectProps } from "./types";

export const FormSelect = ({
  label,
  name,
  register,
  errors,
  options,
  defaultValue,
  unregister,
}: PatientRelationSelectProps) => {
  return (
    <>
      <S.Label>{label}</S.Label>
      <Select
        {...register(name)}
        error={!!errors[name]}
        variant="outlined"
        fullWidth={true}
        sx={{
          background: errors[name] ? "#FEF5F6" : "transparent",
        }}
        value={defaultValue}
        data-test={`${name}-test-id`}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
      {errors[name] && (
        <FormHelperText error sx={{ marginTop: "-6px" }}>
          {errors[name].message}
        </FormHelperText>
      )}
    </>
  );
};
