import { format, isAfter, isValid, parse } from "date-fns";
import * as yup from "yup";

export function dateMask(value: any) {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, "$1/$2") // captura 2 grupos de numero o primeiro de 2 e o segundo de 1, apos capturar o primeiro grupo ele adiciona a primeira barra
    .replace(/(\d{2})(\d)/, "$1/$2") // captura 2 grupos de numero o primeiro de 2 e o segundo de 1, apos capturar o primeiro grupo ele adiciona a segunda barra
    .replace(/(\/\d{4})\d+?$/, "$1"); // captura 4 numeros seguidos de uma barra e não deixa ser digitado mais nada
}

export function dateMaskMMYY(value: any) {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, "$1/$2") // captura 2 grupos de numero o primeiro de 2 e o segundo de 1, apos capturar o primeiro grupo ele adiciona a primeira barra
    .replace(/(\d{2})(\d)/, "$1/$2"); // captura 2 grupos de numero o primeiro de 2 e o segundo de 1, apos capturar o primeiro grupo ele adiciona a segunda barra
}

export function dataValidate(date: string) {
  const dataMinima = parse("01/01/1890", "dd/MM/yyyy", new Date());
  const data = parse(date, "dd/MM/yyyy", new Date());

  if (!isValid(data)) {
    return false;
  }

  if (!isAfter(data, dataMinima)) {
    return false;
  }
  return true;
}
