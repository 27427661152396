import styled from 'styled-components';

interface ButtonInterface {
  background?: string;
  color?: string;
  highlight?: boolean;
  showIcon?: boolean;
  shadow?: boolean;
  border?: string;
  borderColor?: string;
}


export const Button = styled.button<ButtonInterface>`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width: 100%;
  height: 48px;
  background: ${props => props.background ?? '#00D2D4'} ;
  border-radius: 24px;
  border-width: ${props => props.borderColor ? '1px' : '0px'};
  border-color: ${props => props.borderColor ?? ''};
  font-size:0.875rem;
  color: ${props => props.color ?? '#fff'};
  font-weight: bold;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  box-shadow: 0px 14px 19px rgba(32, 32, 32, 0.07), 0px 3.12708px 4.56854px rgba(32, 32, 32, 0.0384537), 0px 0.931014px 1.20809px rgba(32, 32, 32, 0.0229063);
  transition: 0.5s;
  padding:0px 16px;
  
  :hover{
    opacity:0.5
  }

  :disabled {
    background: transparent;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 22px;
    color: #CCCCCC;
  }
`;

export const HighlightButton = styled.button<ButtonInterface>`
  display:flex;
  justify-content: center;
  align-items:center;
  max-width: 344px;
  width:100%;
  height: 58px;
  background: ${props => props.background ? props.background : '#ED1E79'};
  border-radius: 29px;
  font-size: 12px;
  color: ${props => props.color ? props.color : '#FFF'};
  font-weight: bold;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  box-shadow: ${props => props.shadow ? '0px 10px 20px 1px rgba(231, 36, 121, 0.2)' : ''};
  transition: 0.5s;
  border: ${props => props.border ? props.border : '0px'};;
  padding:0px 24px;
  
  :hover{
    color: #FFF;
    background: transparent;
    border: 1px solid #FFF;
    box-sizing: border-box;
    box-shadow: ${props => props.shadow ? '0px 10px 20px 1px rgba(231, 36, 121, 0.2)' : ''};
  }

  :disabled {
    background: transparent;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    color: #CCCCCC;
    box-shadow: 0px 0px 0px 0px
  }
`;