import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { CepMask } from "../../validators/cepMask";
import {
  FormAutoCompleteMapsProps,
  ILocationApi,
  IOptionLocationPrediction,
} from "./types";

export function FormAutoCompleteMaps({
  input,
  loading,
  options,
  onChange,
  onSelect,
}: FormAutoCompleteMapsProps) {
  const [selectedOption, setSelectedOption] = useState(null);

  const [inputValue, setInputValue] = useState<string>("");

  const handleSelectOption = (value: any) => {
    setSelectedOption({ ...value, ...options });
    onSelect({ ...value, ...options });
  };

  const formatedLocation = (
    option: ILocationApi
  ): IOptionLocationPrediction[] => {
    const { id, postalCode, street, neighborhood, city, state } = option;

    const streetStr = street ? `${street}, ` : "";
    const neighborhoodStr = neighborhood ? `${neighborhood}, ` : "";
    const cityStr = city ? `${city} - ` : "";

    const locationDescription = `${streetStr} ${neighborhoodStr} ${cityStr} ${
      state ?? ""
    }`;

    return [{ label: locationDescription, value: CepMask(postalCode) }];
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputCep = event?.target?.value ?? "";

    const numericCep = inputCep ? inputCep.replace(/\D/g, "") : "";

    if (numericCep.length < 9) {
      const formattedCep = CepMask(numericCep);

      setInputValue(formattedCep);
      onChange(inputCep);
    }
  };

  return (
    <Autocomplete
      id="tags-outlined"
      options={options ? formatedLocation(options) : []}
      loading={loading}
      loadingText="Carregando..."
      noOptionsText="Nenhum endereço encontrado..."
      getOptionLabel={(option) => option?.label}
      filterOptions={(x) => x}
      value={selectedOption}
      inputValue={inputValue}
      onChange={(_, value) => handleSelectOption(value)}
      onInputChange={(e: any) => handleInputChange(e)}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          onClick={() => setSelectedOption(null)}
          {...params}
          variant="outlined"
        />
      )}
    />
  );
}
