import { apiIsaV2, authToken } from '../../../core/services/api';
import {
  SAVE_DATE_DATA,
  GET_SCHEDULE_HOUR,
} from './schedulingConstants';

const getScheduleHourEnd = (dispatch: any, data: any) => {
  dispatch({ type: GET_SCHEDULE_HOUR, data })
};

type scheduleHourParams = {
  date: string;
  squareId: string;
  operatorId?: string;
  partnerId?: string;
  categoryId?: string;
}

const slots = [
  {
    id: 12,
    from: "06:00:00",
    to: "06:55:00",
    isAvailable: false
  },
  {
    id: 11,
    from: "07:00:00",
    to: "07:55:00",
    isAvailable: false
  },
  {
    id: 1,
    from: "08:00:00",
    to: "08:55:00",
    isAvailable: false
  },
  {
    id: 2,
    from: "09:00:00",
    to: "09:55:00",
    isAvailable: false
  },
  {
    id: 3,
    from: "10:00:00",
    to: "10:55:00",
    isAvailable: false
  },
  {
    id: 4,
    from: "11:00:00",
    to: "11:55:00",
    isAvailable: false
  },
  {
    id: 5,
    from: "12:00:00",
    to: "12:55:00",
    isAvailable: false
  },
  {
    id: 6,
    from: "13:00:00",
    to: "13:55:00",
    isAvailable: false
  },
  {
    id: 7,
    from: "14:00:00",
    to: "14:55:00",
    isAvailable: false
  },
  {
    id: 8,
    from: "15:00:00",
    to: "15:55:00",
    isAvailable: false
  },
  {
    id: 9,
    from: "16:00:00",
    to: "16:55:00",
    isAvailable: false
  },
  {
    id: 10,
    from: "17:00:00",
    to: "17:55:00",
    isAvailable: false
  }
]
export const getScheduleHour = (params: scheduleHourParams, callback: any) => {
  return (dispatch: any) => {
    apiIsaV2.get(`operations/slot-times`, {
      params: {
        'filter[date]': params.date,
        'filter[operation.id]': params.squareId,
        ...(params.operatorId && { 'filter[operator.id]': params.operatorId }),
        ...(params.partnerId && { 'filter[partner.id]': params.partnerId }),
        ...(params.categoryId && { 'filter[category.id]': params.categoryId })
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${authToken}`,
      }
    })
      .then((responseJson) => {
        const slotsData = responseJson?.data?.data

        slots.forEach((slot) => {
          slot.isAvailable = false;

          if (slotsData.find((slotData: any) => slotData.attributes.from === slot.from)) {
            slot.isAvailable = true;
          }
        })

        getScheduleHourEnd(dispatch, slots)
        callback(dispatch, slots)
      })
      .catch((err) => {
        callback(err.message);
      });
  };
};

export const saveDateData = (data: any) => {
  return (dispatch: any) => {
    dispatch({ type: SAVE_DATE_DATA, data })
  }
};

