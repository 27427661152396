import styled from "styled-components";

interface BagContainerProps {
  showBag: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 105px;
  background: #004474;
  border-radius: 0px 0px 24px 24px;
`;

export const HeaderText = styled.h3`
  font-size: 1.37rem;
  color: #ffffff;
  margin-left: -10px;
`;

export const BagNumber = styled.div`
  width: 24px;
  height: 24px;
  background: #ed1e79;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: white;
  margin-left: -13px;
  margin-bottom: 20px;
`;

export const BagContainer = styled.button<BagContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
  border: 0px;
  cursor: ${(props) => (props.showBag ? "pointer" : "not-allowed")};
  opacity: ${(props) => (props.showBag ? null : 0.5)};
`;

export const FloatButton = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  background: #ed1e79;
  border-radius: 24px;
  width: 145px;
  height: 48px;
  z-index: 9999;
  right: 16px;
  bottom: 100px;
  position: fixed;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
`;
