import styled from 'styled-components';

export const Button = styled.div`
  width:100%;
  height: 100px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  border-width:0px;
  background:transparent;
  border-bottom-width:1px;
  border-color:#C4C4C4;
  border-style:solid;

  p{
    text-align:start;
    font-style: bold;
    color: #004474;
    margin-top:8px;
  }
`;

export const RemoveProduct = styled.button`
  display:flex;
  justify-content:flex;
  align-items:center;
  width:30px;
  height:30px;
  padding:8px;
  border: 1.5px solid #004474;
  border-radius: 10px;
  transition: 0.5s;
  background: transparent;

  :hover {
    background: rgba(0, 115, 195, 0.15);
    
  }
  
`;

export const TotalContainer = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:flex-end;
  width:100%;
  margin-top:32px;
  color: #004474;
  
  p{
    font-size:1.5rem;
  }

  h2{
    font-size:2.2rem;
    font-weight:bold;
  }

  span{
    font-size:1.5rem
  }
`;