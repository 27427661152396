import {
  SET_PRODUCTS,
  TESTE,
  PRICE_VALUE
} from './shopConstants';

const INITIAL_STATE = {
  listOfProducts: [],
  teste:0
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_PRODUCTS:
      var _listOfProducts
      _listOfProducts = action.data
      const data = JSON.stringify(_listOfProducts)
      localStorage.setItem('listOfProducts', data)

      return {
        ...state,
        listOfProducts: _listOfProducts,
      }
    case TESTE:
      var _teste
      _teste = action.data

      return {
        ...state,
        teste: _teste,
      }
    case PRICE_VALUE:
      var _priceValue
      _priceValue = action.data
      localStorage.setItem('priceValue', action.data)

      return {
        ...state,
        priceValue: _priceValue,
      }
  }

  return state;
};