import { Box, Grid } from "@material-ui/core";

import IcoBack from "../../../shared/assets/ico_back.svg";

import { useHistory } from "react-router";

//Css
import { useDispatch, useSelector } from "react-redux";
import { IsaContainer } from "../../../shared/components/IsaContainer/IsaContainer";
import { IsaH3Text } from "../../../shared/components/IsaH3Text/IsaH3Text";
import { IsaHeader } from "../../../shared/components/IsaHeader/isaHeader";
import { changePageView } from "../redux/accountActions";
import { CodeValidate } from "./components/codeValidate";
import { SendCodeValidate } from "./components/sendCodeValidate";

export enum DisplayPage {
  CODE_VALIDATION = "sendCodeValidate",
  SMS_VALIDATE = "smsValidate",
}

export function TelAuth() {
  const history = useHistory();
  const dispatch = useDispatch();
  const validationPage = useSelector(
    (state: any) => state.AccountReducer?.validationPage
  );

  const page = () => {
    switch (validationPage) {
      case DisplayPage.CODE_VALIDATION:
        return <SendCodeValidate />;

      case DisplayPage.SMS_VALIDATE:
      default:
        return <CodeValidate />;
    }
  };
  const changeRoute = () => {
    return validationPage === DisplayPage.SMS_VALIDATE
      ? history.push("/conta")
      : dispatch(changePageView("", DisplayPage.SMS_VALIDATE));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => changeRoute()}
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName="Autenticação"
      />
      <Grid
        style={{ height: "100%" }}
        container
        direction="column"
        justifyContent="start"
        alignItems="center"
      >
        <IsaContainer>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <div style={{ marginTop: 67 }} />
            <Grid
              container
              maxWidth={720}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <IsaH3Text color="#004474" textAlign="start">
                Validação
              </IsaH3Text>
              <div style={{ marginBottom: 24 }} />
              {page()}
            </Grid>
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
