import { Box } from "@material-ui/core";
import { IsaButton } from "../IsaButton/IsaButton";
import { FloatingButtonProps } from "./types";

export const FloatingButton = ({
  buttonName,
  dataTest,
  disabled,
  onClick,
}: FloatingButtonProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "158px",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          maxWidth: "720px",
          bottom: "50px",
          padding: "0px 21px",
        }}
      >
        <IsaButton
          type="submit"
          dataTest={dataTest}
          disabled={disabled}
          onClick={onClick}
        >
          {buttonName}
        </IsaButton>
      </Box>
    </Box>
  );
};
