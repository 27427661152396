import { Grid } from "@material-ui/core";
import { IsaContainer } from "../IsaContainer/IsaContainer";
import { useHistory } from "react-router";

//Redux
import { useDispatch } from "react-redux";
import { setProductsToCart } from "../../redux/shop/shopActions";

//Logo
import IcoCompra from "../../../shared/assets/ico_compra.svg";

//Css
import {
  Container,
  HeaderText,
  BagNumber,
  BagContainer,
  FloatButton,
} from "./isaHeaderStyle";
import { useEffect, useState } from "react";

export function IsaHeader(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const listOfProducts = JSON.parse(
    localStorage.getItem("listOfProducts") ?? "[]"
  );
  const planInfos = JSON.parse(localStorage.getItem("planInfos") ?? "{}");
  const subRoute = localStorage.getItem("subRoute") ?? "";
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [urlParams, setUrlParams] = useState("");
  useEffect(() => {
    let _url = window.location.href.split("/");
    const url = _url[3].replaceAll("#", "");
    setUrlParams(url);
  }, []);

  const setSum = () => {
    var _sum = 0;
    for (var i = 0; i < listOfProducts.length; i++) {
      _sum += listOfProducts[i].count;
    }
    return _sum;
  };

  const resetValues = () => {
    localStorage.setItem("route", "");
    localStorage.setItem("setServiceAttributes", "");
    localStorage.setItem("getAddressData", "");
    localStorage.setItem("planInfos", "");
    localStorage.setItem("patientData", "");
    localStorage.setItem("token", "");
    localStorage.setItem("exp", "");
    localStorage.setItem("subRoute", "");
    localStorage.setItem("listOfProducts", "");
    localStorage.setItem("priceValue", "");
    localStorage.setItem("getDateData", "");
    localStorage.setItem("partnerInfo", "");
    dispatch(setProductsToCart([]));
    history.push("/");
  };

  return (
    <>
      <Container>
        <IsaContainer>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid>{props.backButton}</Grid>
            <HeaderText>{props.pageName}</HeaderText>
            <Grid>
              {subRoute === "particular"
                ? setSum() > 0 && (
                  <BagContainer
                    showBag={setSum() > 0 ? true : false}
                    disabled={setSum() > 0 ? false : true}
                    onClick={() => history.push("/carrinho-inicial")}
                  >
                    <img alt="compra" src={IcoCompra} />
                    <BagNumber>{setSum()}</BagNumber>
                  </BagContainer>
                )
                : ""}
            </Grid>
          </Grid>
        </IsaContainer>
      </Container>
      {planInfos?.cnpj === "44649812000138" ? (
        <FloatButton onClick={() => resetValues()}>
          iniciar novo agendamento
        </FloatButton>
      ) : (
        ""
      )}
    </>
  );
}
