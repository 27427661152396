import styled from 'styled-components';

export const DivLogo = styled.div`
  display: flex;
  flex-direction:row;
  margin-top:104px;
`;

export const HourDateContainer = styled.div`
  height: 50px;
  width: 185px;
  background: #ED1E79;
  border-radius: 26px;
  display:flex;
  align-items: center;
  justify-content: center;

  p{
    margin-left:17px;
    color:white;
    font-size:16px;
    font-weight: bold;
  }
`;

export const CopyPixButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items:center;
  height: 50px;
  width:100%;
  padding:15px;
  background: transparent;
  border: 1px solid #DDDDDD;
  border-radius: 4px;

  p{
    color: #666666 !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; 
  }
`

export const QrCodeContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 17px 399px rgba(0, 0, 0, 0.07), 0px 3.79717px 89.1219px rgba(0, 0, 0, 0.0417275), 0px 1.13052px 26.5339px rgba(0, 0, 0, 0.0282725);
  border-radius: 16px;
  padding:8px;
`