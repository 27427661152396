import styled from 'styled-components';

interface BreadcrumbsProps {
  width: string,
  background: string
}

interface DivBallsProps {
  marginRight:string
}

export const Breadcrumbs = styled.div<BreadcrumbsProps>`
  width: ${props => props.width};
  height: 10px;
  background:${props => props.background};
  border-radius: 5px;
`;

export const ContainerBreadcrumbs = styled.div`
  display:flex;
  justify-content: center;
`;

export const DivBalls = styled.div<DivBallsProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  align-content: center;
  margin-right:${props => props.marginRight};

  h5{
    margin-bottom:8px;
  }
`;