import styled from 'styled-components';

export const Text: any = styled.p`
  font-size: ${(props: any) => props.fontSize ?? '16px'};
  color: ${props => props.color};
  text-align: center;
  text-align: ${(props: any) => props.textAlign};
  font-weight: ${(props: any) => props.fontWeight};
  width:100%;
`;
