import styled from 'styled-components';

interface SelectorRowProps {
  width: string,
  marginRight: string
}

export const RowDiv = styled.div`
  display:flex;
  flex-direction:row;
  width:100%;
`;
export const SelectorRow = styled.div<SelectorRowProps>`
  width: ${props => props.width};
  margin-right: ${props => props.marginRight};
`;
