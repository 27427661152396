import React from 'react'
import Routes from "./core/routes";
import { GlobalStyle } from "./core/styles/global";

import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import reducers from "./core/reducers/index";
import TagManager from "react-gtm-module";
import { Fab } from "@material-ui/core";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const store = createStore(reducers, applyMiddleware(ReduxThunk));
const tagManagerArgs = {
  gtmId: "GTM-T98KBZV",
};

TagManager.initialize(tagManagerArgs);

function App() {
  const url = window.location.href;
  const partnerPagueMenos = url.includes("PAGUE_MENOS") || url.includes("pague_menos");

  return (
    <Provider store={store}>
      <GlobalStyle/>
      <Routes />
      {partnerPagueMenos && (
        <Fab
          onClick={() =>
            (window.location.href =
              "https://api.whatsapp.com/send?phone=5511991961033")
          }
          sx={{
            backgroundColor: "#25D366",
            color: "#fff",
            position: "fixed",
            bottom: 56,
            right: 16,
            "&:hover": {
              bgcolor: "#1ea850",
            },
          }}
          aria-label="Whatsapp ISA"
        >
          <WhatsAppIcon sx={{ fontSize: '28px' }} />
        </Fab>
      )}
    </Provider>
  );
}

export default App;
