import styled from 'styled-components';

interface InputProps {
  borderColor: string,
  background: string,
  border: string,
}

export const DivInput = styled.div<InputProps>`
  display: flex;
  justify-content:space-between;
  width: 100%;
  height: 50px;
  background: ${props => props.background};
  border: 1px solid ${props => props.border};
  border-color: ${props => props.borderColor};
  box-sizing: border-box;
  border-radius: 4px;
  padding:0px 16px;

  input {
    border: 0px;
    width:100%;
  }
  input:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
  }

  img{
    cursor:pointer;
  }

  :disabled {
    background: #f3f3f3;
  }
`;
