import { 
  SAVE_PARTNER_INFO
} from "./initialConstants";

import { apiIsa, authToken } from '../../../core/services/api'

export const getPartnerToken = (hash: string,callback: any) => {
	return (dispatch:any) => {
    apiIsa.get(`/partner/token/${hash}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${authToken}`,
      }
    })
    .then((responseJson) => {
      // doLoginEnd(dispatch,responseJson?.data)
      callback(responseJson)
    })
    .catch((err) => {
      callback(err.response);
    });
	};
};

export const savePartnerInfo = (data: string) => {
	return (dispatch:any) => {
    dispatch({type: SAVE_PARTNER_INFO, data})
	};
};
