/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getOperator,
  setDestinationPath,
  savePlanInfos,
} from "./redux/selectHealthInsuranceActions";

//Local Imports
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { useWindowSize } from "../../shared/components/hooks/useWindowSize";
import { IsaPText } from "../../shared/components/IsaPText/IsaPText";

//Logo
import InitialImg from "../../shared/assets/initial_img.svg";
import IcoDown from "../../shared/assets/ico_down.svg";
import IcoBack from "../../shared/assets/ico_back.svg";

//Css
import "./selectHealthInsurance.css";
import { ButtonDrop, ContainerDrop } from "./selectHealthInsuranceStyle";
import IsaLoader from "../../shared/components/IsaLoader/isaLoader";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";
import { isExpValid } from "../account/redux/accountActions";
import mixpanel from "mixpanel-browser";
import { PlanListProps } from "./selectHealthInsuranceInterface";
import { apiIsaV2, authToken, mixPanelOn } from "../../core/services/api";

enum ModalitiesId {
  ONLY_PRIVATE = 2,
  ONLY_HEALTH_INSURANCE = 1,
}

interface buttonToDisplayProps {
  healthInsurance: boolean;
  private: boolean;
}
declare const window: any;

export function SelectHealthInsurance() {
  const size = useWindowSize();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpenDropDownPlans, setIsOpenDropDownPlans] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [buttonsToDisplay, setButtonsToDisplay] = useState({
    healthInsurance: false,
    private: false,
  });
  const listOperators = useSelector(
    (state: any) => state.SelectHealthInsuranceReducer.listOperators ?? []
  );
  const isTokenValid = useSelector(
    (state: any) => state.AccountReducer?.isTokenValid
  );
  const partnerInfo = JSON.parse(localStorage.getItem("partnerInfo") ?? "");
  const operationInfo = JSON.parse(localStorage.getItem("operationInfo")
    ?? JSON.stringify({ id: process.env.REACT_APP_STANDARD_SQUARE }))
  const partnerName = localStorage.getItem("partnerName");
  const mixToken: string = localStorage.getItem("mixPanelToken") ?? "";

  useEffect(() => {
    dispatch(isExpValid());
  }, []);

  useEffect(() => {
    if (operationInfo) getCoverage();
  }, []);

  useEffect(() => {
    setIsOpen(true);
    if (isTokenValid) history.push("/");
    dispatch(
      getOperator(() => {
        setIsOpen(false);
      })
    );
  }, []);

  const getCoverage = async () => {
    setIsOpen(true);
    try {
      const res = await apiIsaV2.get(`/operations/${operationInfo.id}`, {
        params: {
          include: "modalities,product_groups,products,schedule,schedule.slot,slot.slot_time,date_unavailable,date_unavailable.slot_unavailable,slot_unavailable.slot_time",
        },
        headers: {
          Authorization: authToken,
        },
      });

      const modalities = partnerInfo && partnerInfo.modalities 
        ? partnerInfo.modalities 
        : res.data?.data?.relationships?.modalities?.data;

      const findHealthInsurance = modalities.find(
        (item: any) => item.id === ModalitiesId.ONLY_HEALTH_INSURANCE
      );
      const findPrivate = modalities.find(
        (item: any) => item.id === ModalitiesId.ONLY_PRIVATE
      );

      setButtonsToDisplay({
        healthInsurance: findHealthInsurance ? true : false,
        private: findPrivate ? true : false,
      });
    } catch (err: any) {
      console.log(err.response);
    } finally {
      setIsOpen(false);
    }
  };

  const handleContinue = (path: any, plan: PlanListProps | any) => {
    if (path === "particular") {
      if (mixPanelOn === "true") {
        mixpanel.identify(mixToken);
        mixpanel.track("Escolha Plano de saúde/Particular", {
          tipo: "Particular",
          fluxo: `${partnerName ?? "Isa lab"}`,
        });
        mixpanel.people.set({
          "Escolha Plano de saúde/Particular": "Particular",
        });
        let dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "escolha_plano_de_saude_ou_particular",
          ecommerce: {
            tipo: "Particular",
            fluxo: `${partnerName ?? "Isa lab"}`,
          },
        });
      }
    } else {
      if (mixPanelOn === "true") {
        mixpanel.identify(mixToken);
        mixpanel.track("Escolha Plano de saúde/Particular", {
          tipo: "Plano de saúde",
          "nome do plano": ` ${plan.description}`,
          fluxo: `${partnerName ?? "Isa lab"}`,
        });
        mixpanel.people.set({
          "Escolha Plano de saúde/Particular": "Plano de saúde",
        });
        let dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "escolha_plano_de_saude_ou_particular",
          ecommerce: {
            tipo: "Plano de saúde",
            fluxo: `${partnerName ?? "Isa lab"}`,
          },
        });
      }
    }
    localStorage.setItem("subRoute", path);
    dispatch(setDestinationPath(path));
    dispatch(savePlanInfos(plan));
    if (partnerName === "OYA Care") {
      return history.push("/servico-selecionado");
    }
    history.push("/categorias");
  };

  const privateOrHealthInsurance = () => {
    const modalities =
      partnerInfo?.modalities?.length === 1
        ? partnerInfo.modalities[0].id
        : null;
    switch (modalities) {
      case ModalitiesId.ONLY_PRIVATE:
        return "Private";
      case ModalitiesId.ONLY_HEALTH_INSURANCE:
        return "Health insurance";
      default:
        return "Both";
    }
  };

  const partnerFlux = () => {
    return (
      <>
        {partnerInfo !== "" && buttonsToDisplay.healthInsurance ? (
          !partnerInfo?.operatorId ? (
            <ButtonDrop
              onClick={() => setIsOpenDropDownPlans(!isOpenDropDownPlans)}
            >
              Plano de saúde
              <img
                alt="ico"
                src={IcoDown}
                className={isOpenDropDownPlans ? "buttonDropStyle" : ""}
              />
            </ButtonDrop>
          ) : (
            <ButtonDrop
              onClick={() => handleContinue("plano de saude", partnerInfo)}
            >
              {partnerInfo.document !== "35268347000102"
                ? `Convênio -  ${partnerInfo.tradeName}`
                : partnerInfo.tradeName}
            </ButtonDrop>
          )
        ) : (
          ""
        )}

        {isOpenDropDownPlans ? (
          <ContainerDrop className="dropAnimation">
            {listOperators.map((item: any) => {
              return (
                <button onClick={() => handleContinue("plano de saude", item)}>
                  {item.description}
                </button>
              );
            })}
          </ContainerDrop>
        ) : (
          <div />
        )}
        <div style={{ marginTop: 16 }} />
        {privateOrHealthInsurance() === "Both" ||
          privateOrHealthInsurance() === "Private" ||
          partnerInfo == "0" ? (
          <ButtonDrop onClick={() => handleContinue("particular", "")}>
            Particular
          </ButtonDrop>
        ) : (
          ""
        )}
      </>
    );
  };

  const healthInsuranceFlux = (buttonsToDisplay: buttonToDisplayProps) => {
    return (
      <>
        <Box style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          {!buttonsToDisplay.healthInsurance && !buttonsToDisplay.private ? <CircularProgress /> : <></>}
        </Box>

        <ButtonDrop
          style={{
            marginTop: "64px",
            display: `${buttonsToDisplay.healthInsurance ? "flex" : "none"}`,
          }}
          onClick={() => setIsOpenDropDownPlans(!isOpenDropDownPlans)}
        >
          Plano de saúde
          <img
            alt="ico"
            src={IcoDown}
            className={isOpenDropDownPlans ? "buttonDropStyle" : ""}
          />
        </ButtonDrop>
        {isOpenDropDownPlans ? (
          <ContainerDrop className="dropAnimation">
            {listOperators.map((item: any) => {
              return (
                <button onClick={() => handleContinue("plano de saude", item)}>
                  {item.description}
                </button>
              );
            })}
          </ContainerDrop>
        ) : (
          <div />
        )}
        <ButtonDrop
          style={{
            marginTop: "16px",
            display: `${buttonsToDisplay.private ? "flex" : "none"}`,
          }}
          onClick={() => handleContinue("particular", "")}
        >
          Particular
        </ButtonDrop>
      </>
    );
  };

  return (
    <Box
      style={{
        height: "100vh",
      }}
      sx={{ flexGrow: 1 }}
    >
      <IsaLoader open={isOpen} />
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/")}
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName={"Agendamento"}
      />
      <Grid
        className="gridContainerStyle"
        container
        direction="row"
        justifyContent="start"
        alignItems="center"
      >
        <Grid
          style={{ width: "50%" }}
          sx={{ display: { lg: "flex", md: "none", sm: "none", xs: "none" } }}
        >
          <img alt="img" src={InitialImg} />
        </Grid>
        <IsaContainer
          width={size.width < 940 && size.width !== 0 ? "100%" : "35%"}
          display="flex"
          alignContent="center"
          alignItems="center"
          flexDirection="column"
          justifyContent={
            size.width < 940 && size.width !== 0 ? "start" : "center"
          }
        >
          <div style={{ marginTop: 64 }} />
          <Grid
            container
            maxWidth={720}
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            {partnerInfo.tradeName === "GNDI" ? (
              <IsaPText color="#666666" textAlign="start">
                Em parceria com o laboratório digital ISA, agora oferece coletas
                a domicílio, cobertas pelo seu convênio. Quer fazer exames em
                casa? Aproveita!
              </IsaPText>
            ) : partnerInfo.document !== "35268347000102" ? (
              <IsaPText color="#666666" textAlign="start">
                Selecione seu Modelo de agendamento.
              </IsaPText>
            ) : (
              <IsaPText color="#666666" textAlign="start">
                Clique abaixo para seguir no seu agendamento.
              </IsaPText>
            )}

            <div style={{ marginTop: 18 }} />
            {partnerName !== "Isa lab"
              ? partnerFlux()
              : healthInsuranceFlux(buttonsToDisplay)}
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
