import { Box, TextField } from "@material-ui/core";
import styled from "styled-components";

export const Input = styled(TextField)`
  background: transparent;
  & .MuiOutlinedInput-root {
    background: ${(props) => (props.error ? "#FEF5F6" : "transparent")};
  }
  & .MuiFormHelperText-root {
    margin-left: 0px;
  }
  background: ${(props) => (props.disabled ? "#ECECEC" : "")};
  border-radius: 4px;
`;
export const Label = styled.label`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #666666;
`;

export const ErrorMessage = styled.span`
  color: #d32f2f;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

export const LabelBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const LabelHelper = styled.label`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #cccccc;
  text-transform: uppercase;
`;
