/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";

import DatePicker, { utils } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import { useHistory } from "react-router";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { getScheduleHour, saveDateData } from "./redux/schedulingActions";

//Local Imports
import { myCustomLocale } from "../../shared/components/DatePickerProps/myCustomLocale";
import {
  Balls,
  IsaBreadcrumbs,
} from "../../shared/components/IsaBreadcrumbs/isaBreadcrumbs";
import { IsaButton } from "../../shared/components/IsaButton/IsaButton";
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";
import IsaLoader from "../../shared/components/IsaLoader/isaLoader";
import { IsaSelector } from "../../shared/components/IsaSelector/IsaSelector";

//Logo
import IcoBack from "../../shared/assets/ico_back.svg";

//Css
import { addDays } from "date-fns";
import mixpanel from "mixpanel-browser";
import { apiIsaV2, authToken, mixPanelOn } from "../../core/services/api";
import { IsaPText } from "../../shared/components/IsaPText/IsaPText";
import { formatHour } from "../../shared/validators/formatHour";
import "./scheduling.css";
import { OptionDiv } from "./schedulingStyle";

declare const window: any;

export function Scheduling() {
  const history = useHistory();
  const dispatch = useDispatch();

  const setServiceAttributesStorage = localStorage.getItem(
    "setServiceAttributes"
  );
  const setServiceAttributes = setServiceAttributesStorage
    ? JSON.parse(setServiceAttributesStorage ?? "")
    : "";

  const listScheduleHour = useSelector(
    (state: any) => state.SchedulingReducer?.listScheduleHour ?? []
  );
  const operationInfo = JSON.parse(
    localStorage.getItem("operationInfo") ??
    JSON.stringify({ id: process.env.REACT_APP_STANDARD_SQUARE })
  );
  const partnerName = localStorage.getItem("partnerName") ?? "";
  const route = localStorage.getItem("route") ?? "";
  const subRoute = localStorage.getItem("subRoute") ?? "";
  const [selectedDay, setSelectedDay] = useState<any>(null);
  const [hour, setHour] = useState<any>("");
  const [openSelector, setOpenSelector] = useState(false);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [idHour, setIdHour] = useState(null);
  const [actualDate, setActualDate] = useState<any>([]);
  const mixToken: string = localStorage.getItem("mixPanelToken") ?? "";
  const planInfos = JSON.parse(localStorage.getItem("planInfos") ?? "{}");
  const partnerInfo = JSON.parse(localStorage.getItem("partnerInfo") ?? "{}");
  const listOfProducts = JSON.parse(localStorage.getItem("listOfProducts") ?? "[]");
  const categoryIdForOperator = localStorage.getItem("categoryIdForOperator") ?? "";
  const isOperatorSami = planInfos.id === 27;
  const isSami = isOperatorSami || partnerName === "SAMI";

  useEffect(() => {
    let date = new Date().toLocaleDateString("pt-BR");
    setActualDate(date.split("/"));
  }, []);

  useEffect(() => {
    if (operationInfo) getListOfProductsByModalities();
  }, []);

  const getListOfProductsByModalities = async () => {
    setIsLoaderOpen(true);
    try {
      const res = await apiIsaV2.get(`/operations/${operationInfo.id}`, {
        params: {
          include:
            "modalities,product_groups,products,schedule,schedule.slot,slot.slot_time,date_unavailable,date_unavailable.slot_unavailable,slot_unavailable.slot_time",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken ?? "",
        },
      });

      let includedData = res.data.included;
      const lockedDaysByModalities = includedData
        .filter(
          (include: { type: string }) => include.type === "date_unavailable"
        )
        .map((item: any) => {
          if (item.attributes.is_fully_closed) {
            return item.attributes.date;
          }
        });
      const formatBlockedDays = lockedDaysByModalities.map((item: any) => {
        let splitableDate = item.split("-");
        return {
          year: splitableDate.year,
          month: splitableDate.month,
          day: splitableDate.day,
        };
      });
    } catch (err: any) {
      console.log(err.response);
    } finally {
      setIsLoaderOpen(false);
    }
  };

  const Disabled = () => {
    if (hour !== "" && selectedDay !== null) {
      return false;
    } else {
      return true;
    }
  };

  const handleSelect = (item: any) => {
    let format = item.from.split(":");
    let formatTo = item.to.split(":");
    const newDate = `${format[0]}:${format[1]} às ${formatTo[0]}:${formatTo[1]}`;

    setHour(newDate);
    setIdHour(item.id);
    setOpenSelector(false);
  };

  const handleSelectDate = (e: any) => {
    setIsLoaderOpen(true);
    setHour("");
    let month = e.month < 10 ? `0${e.month}` : e.month;
    let day = e.day < 10 ? `0${e.day}` : e.day;

    const productGroupIds = listOfProducts?.map((item: any) => item.productGroupId);
    const listGroupIds = [...new Set(productGroupIds)];
    const categoryId = listGroupIds.toString() || categoryIdForOperator;

    const scheduleParams = {
      date: `${e.year}-${month}-${day}T00:00:00.000Z`,
      squareId: operationInfo.id,
      ...(planInfos && planInfos.id && { operatorId: planInfos.id }),
      ...(partnerInfo && partnerInfo.id && { partnerId: partnerInfo.id }),
      ...(categoryId && { categoryId })
    };

    setSelectedDay(e);
    dispatch(
      getScheduleHour(scheduleParams, (res: any) => {
        setIsLoaderOpen(false);
      })
    );
  };

  function handleInvisibleButton() {
    const dateWithExtraDays = addDays(Date.now(), 3);
    const formattedDate = {
      day: dateWithExtraDays.getUTCDate(),
      month: dateWithExtraDays.getMonth() + 1,
      year: dateWithExtraDays.getFullYear(),
    };

    handleSelectDate(formattedDate);
  }

  const handleNext = () => {
    let month =
      selectedDay.month < 10 ? `0${selectedDay.month}` : selectedDay.month;
    let day = selectedDay.day < 10 ? `0${selectedDay.day}` : selectedDay.day;
    const fullDate = `${day}/${month}/${selectedDay.year}`;
    const data = {
      date: fullDate,
      hour: hour,
      dateId: idHour,
    };
    dispatch(saveDateData(data));
    if (mixPanelOn === "true") {
      mixpanel.identify(mixToken);
      mixpanel.track("Agendamento horário", {
        dia: fullDate,
        horário: hour,
        fluxo: `${partnerName ?? "Isa lab"}`,
      });
      mixpanel.people.set({ "Agendamento horário": `${fullDate} - ${hour}` });
      let dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "agendamento_horario",
        ecommerce: {
          dia: fullDate,
          horário: hour,
          fluxo: `${partnerName ?? "Isa lab"}`,
        },
      });
    }
    history.push("/conta");
  };

  const formatInputValue = () => {
    if (!selectedDay) return "";
    else {
      let month =
        selectedDay.month < 10 ? `0${selectedDay.month}` : selectedDay.month;
      let day = selectedDay.day < 10 ? `0${selectedDay.day}` : selectedDay.day;
      return `${day}/${month}/${selectedDay.year}`;
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IsaLoader open={isLoaderOpen} />
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() =>
                history.push(
                  route === "isaUser" && subRoute !== "plano de saude"
                    ? "/carrinho-inicial"
                    : "/pre-cadastro"
                )
              }
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName={setServiceAttributes?.pageName ?? ""}
      />
      <Grid
        style={{ height: "100%" }}
        container
        direction="column"
        justifyContent="start"
        alignItems="center"
      >
        <IsaContainer>
          <IsaBreadcrumbs>
            <Balls
              marginRight="0px"
              isHaveHeader={true}
              header=""
              width="10px"
            />
            <Balls
              marginRight="0px"
              isHaveHeader={true}
              header="Agendamento"
              width="20px"
            />
            <Balls
              marginRight="43px"
              isHaveHeader={false}
              header=""
              width="10px"
            />
            <Balls
              marginRight="43px"
              isHaveHeader={false}
              header=""
              width="10px"
            />
            <Balls
              marginRight="0px"
              isHaveHeader={false}
              header=""
              width="10px"
            />
          </IsaBreadcrumbs>
          <div style={{ marginTop: 40 }} />
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              container
              maxWidth={720}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <h5 style={{ textAlign: "start", width: "100%" }}>Dia</h5>
              <div style={{ marginBottom: 8 }} />
              <button
                onClick={handleInvisibleButton}
                style={{
                  width: "0px",
                  height: "0px",
                  padding: "0px",
                  visibility: "hidden",
                }}
                data-test={`set-date-test-id`}
              ></button>
              <DatePicker
                value={selectedDay}
                colorPrimary="#ED1E79"
                onChange={(e: any) => handleSelectDate(e)}
                inputPlaceholder=""
                calendarPopperPosition="bottom"
                calendarClassName="custom-calendar" // and this
                calendarTodayClassName="custom-today-day" // also this
                minimumDate={
                  isSami
                    ? {
                      year: actualDate[2],
                      month: parseInt(actualDate[1]),
                      day: parseInt(actualDate[0]) + 2,
                    }
                    : {
                      ...utils("en").getToday(),
                      day: utils("en").getToday().day + 1,
                    }
                }
                locale={myCustomLocale}
                formatInputText={formatInputValue}
              />
              <div style={{ marginTop: 24 }} />
              <IsaPText textAlign="start" color="#666666">
                Qual o intervalo de horário funciona melhor para você?
              </IsaPText>
              <div style={{ marginTop: 24 }} />
              <IsaSelector
                inputTitle="Horário"
                value={hour}
                onClick={() => setOpenSelector(!openSelector)}
                openSelector={openSelector}
                disabled={selectedDay ? false : true}
              >
                {openSelector === true ? (
                  <>
                    {listScheduleHour.map((item: any, index: number) => {
                      return (
                        <OptionDiv
                          key={index}
                          disabled={item.isAvailable ? false : true}
                          onClick={() => handleSelect(item)}
                        >
                          {`${formatHour(item.from)} às ${formatHour(item.to)}`}
                        </OptionDiv>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </IsaSelector>
              <div style={{ marginTop: 270 }} />
              <IsaButton onClick={() => handleNext()} disabled={Disabled()}>
                Avançar
              </IsaButton>
              <div style={{ marginTop: 38 }} />
            </Grid>
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
