import {
  GET_ACCESS_TOKEN,
  GET_WHITE_LIST,
  DO_LOGIN,
  SAVE_PARTNER_INFO
} from './initialConstants';

const INITIAL_STATE = {
  getAccessToken: '',
  getWhiteList: [],
  responseLogin: {},
  partnerInfo: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_ACCESS_TOKEN:
      var _getAccessToken
      _getAccessToken = action.data.user

      return {
        ...state,
        getAccessToken: _getAccessToken
      }
    case GET_WHITE_LIST:
      var _getWhiteList
      _getWhiteList = action.data

      return {
        ...state,
        getWhiteList: _getWhiteList
      }
    case DO_LOGIN:
      var _responseLogin
      _responseLogin = action.data
      // cnpj: null
      // codeANS: null
      // companyName: null
      // description: "Bradesco"
      // id: 19
      // index: 1
      //localStorage.setItem('planInfos', action.data.token);
      
      return {
        ...state,
        responseLogin: _responseLogin

      }
    case SAVE_PARTNER_INFO:
      var _partnerInfo
      _partnerInfo = action.data
      const obj = JSON.stringify({
        cnpj: action.data.document,
        codeANS: null,
        companyName: null,
        description: action.data.tradeName,
        id: action.data.operatorId
      })
      const data = JSON.stringify(_partnerInfo)
      localStorage.setItem('partnerInfo',data)
      localStorage.setItem('listOfProducts','[]')
      localStorage.setItem('patientData','""')
      localStorage.setItem('planInfos',obj)
      localStorage.setItem('getAddressData','[]')
      localStorage.setItem('discount','""')

      return {
        ...state,
        partnerInfo: _partnerInfo
      }
  }

  return state;
};