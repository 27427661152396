import { Controller, UseFormRegister } from "react-hook-form";
import InputMask from "react-input-mask";
import * as S from "./style";

interface FormInputProps {
  label: string;
  name: string;
  register: UseFormRegister<any>;
  errors: any;
  mask?: string;
  control?: any;
  maskChar?: string;
  alwaysShowMask?: boolean;
  labelHelper?: string;
  unregister?: boolean;
  disabled?: boolean;
}

export const FormInput = ({
  label,
  name,
  register,
  errors,
  control,
  mask,
  maskChar = "",
  alwaysShowMask = true,
  labelHelper,
  unregister,
  disabled,
}: FormInputProps) => {
  return (
    <>
      <S.LabelBox>
        <S.Label>{label}</S.Label>
        <S.LabelHelper>{labelHelper}</S.LabelHelper>
      </S.LabelBox>
      {control ? (
        <>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <InputMask
                {...field}
                {...register(name)}
                mask={mask ?? ""}
                maskChar=" "
                alwaysShowMask={alwaysShowMask}
                data-test={`${name}-test-id`}
                disabled={disabled}
              >
                {(inputProps: any) => (
                  <S.Input
                    disabled={disabled}
                    error={!!errors[name]}
                    {...inputProps}
                  />
                )}
              </InputMask>
            )}
          />
          {errors[name] && (
            <S.ErrorMessage>{errors[name].message}</S.ErrorMessage>
          )}
        </>
      ) : (
        <S.Input
          {...register(name)}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          variant="outlined"
          fullWidth={true}
          data-test={`${name}-test-id`}
        />
      )}
    </>
  );
};
