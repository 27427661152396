import {
  SAVE_DOCUMENTS_IMAGE
} from './sendDocumentsConstants';

const INITIAL_STATE = {
  listDocumentsImage: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SAVE_DOCUMENTS_IMAGE:
      var _listDocumentsImage
      _listDocumentsImage = action.data
      const data = JSON.stringify(action.data)
      localStorage.setItem('listDocumentsImage',data)

      return {
        ...state,
        listDocumentsImage: _listDocumentsImage
      }
  }

  return state;
};