//Css
import {
  DivInput
} from './IsaSearchStyle'

//Svg
import IcoSearchWhite from '../../assets/ico_search_white.svg'

export function IsaSearch(props: any) {
  return (
    <>
      <h5 style={{textAlign:'start',width:'100%'}}>
        {props.inputTitle}
      </h5>
      <div style={{marginBottom:8}} />
      <DivInput
        background={props.background ? props.background : '#ffffff'}
        borderColor={props.borderColor ? props.borderColor : '#DDDDDD'}
        border={props.border ? props.border : '#DDDDDD'}
      >
        <img
          alt='ico' 
          src={IcoSearchWhite}
          onClick={props.onClick}
        />
        <input
          type={props.type ?? 'text'}
          onChange={props.onChange} 
          value={props.value}
          pattern={props.pattern}
          placeholder={props.placeholder}
        >
          {props.children}
        </input>
      </DivInput>
    </>
  );
}