import styled from 'styled-components';

export const ButtonDrop = styled.button`
  width: 100%;
  height: 48px;
  padding:0px 21px;
  background: #00D2D4;
  border-radius: 24px;
  border-width:0px;
  color:white;
  font-weight: bold;
  text-transform: uppercase;
  display:flex;
  align-items:center;
  justify-content:space-between;
  z-index:99;
  filter: drop-shadow(0px 46px 80px rgba(0, 0, 0, 0.07)) 
  drop-shadow(0px 10.2747px 17.869px rgba(0, 0, 0, 0.0417275)) 
  drop-shadow(0px 3.05905px 5.32008px rgba(0, 0, 0, 0.0282725));
`;

export const ContainerDrop = styled.div`
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 0px 0px 24px 24px;
  display:flex;
  flex-direction:column;
  margin-top:-25px !important;
  padding-top:25px;
  overflow:auto;

  button{
    border-width:0px;
    background:transparent;
    display:flex;
    justify-content:start;
    padding:20px 24px;
    font-weight: bold;
    font-size:0.875rem;
    text-transform: uppercase;
    transition: 0.5s;

    :hover{
      background:#F1F1F1
    }
  }
`;