//Css
import {
  DivInput
} from './IsaInputWButtonStyle'

//Svg
import IcoSearch from '../../assets/ico_search.svg'

export function IsaInputWButton(props: any) {
  return (
    <>
      <h5 style={{textAlign:'start',width:'100%'}}>
        {props.inputTitle}
      </h5>
      <div style={{marginBottom:8}} />
      <DivInput
        background={props.background ? props.background : '#ffffff'}
        borderColor={props.borderColor ? props.borderColor : '#DDDDDD'}
        border={props.border ? props.border : '#DDDDDD'}
      >
        <input
          type={props.type ?? 'text'}
          onChange={props.onChange} 
          value={props.value}
          pattern={props.pattern}
          placeholder={props.placeholder}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        >
          {props.children}
        </input>
        {props.justIcon ?
          props.icon ?
          <img 
            alt='ico' 
            src={props.icon}
            style={{width:'25px'}}
            onClick={props.onClick}
          /> : ''
          :
          props.disabled ? 
          <img 
            alt='ico' 
            src={IcoSearch}
            style={{opacity:0.5,cursor:'no-drop',width:'14px'}}
          />
          : 
          <img 
            alt='ico' 
            src={IcoSearch}
            onClick={props.onClick}
            style={{width:'14px'}}
          />
        }
        
        
      </DivInput>
    </>
  );
}