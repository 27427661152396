import styled from 'styled-components';

interface InputProps {
  borderColor: string,
  background: string,
  border: string,
  color: string
}

interface LabelTitleProps {
  secondLabelTitle: boolean,
}

export const Input = styled.input<InputProps>`
  width: 100%;
  height: 50px;
  background: ${props => props.background};
  border: 1px solid ${props => props.border};
  border-color: ${props => props.borderColor};
  color: ${props => props.color};
  box-sizing: border-box;
  border-radius: 4px;
  padding:0px 10px;

  :disabled {
    background: #f3f3f3;
  }
`;

export const CheckBoxDiv = styled.label`
  display: flex;
  align-items: center;
`;

export const LabelTitle = styled.label<LabelTitleProps>`
  display: flex;
  width: 100%;
  justify-content: ${props => props.secondLabelTitle ? 'space-between' : 'flex-start'};
`