/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";
import AWS from "aws-sdk";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";

import { useHistory } from "react-router";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { createAppointment } from "./redux/confirmationActions";

//Logo
import IcoBack from "../../shared/assets/ico_back.svg";
import IcoSucess from "../../shared/assets/ico_sucess.svg";

//Local Imports
import {
  Balls,
  IsaBreadcrumbs,
} from "../../shared/components/IsaBreadcrumbs/isaBreadcrumbs";
import { IsaButton } from "../../shared/components/IsaButton/IsaButton";
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { IsaDialog } from "../../shared/components/IsaDialog/isaDialog";
import { IsaH3Text } from "../../shared/components/IsaH3Text/IsaH3Text";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";
import { IsaCheckbox } from "../../shared/components/IsaInput/isaInput";
import IsaLoader from "../../shared/components/IsaLoader/isaLoader";
import { IsaPText } from "../../shared/components/IsaPText/IsaPText";
import { formatMoney } from "../../shared/validators/formatMoney";
import { isExpValid } from "../account/redux/accountActions";

//Css

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_AWS,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY_AWS,
});

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_AWS;
const REGION = process.env.REACT_APP_REGION_AWS;

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

declare const window: any;

export function Confirmation(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  const getAddressData = JSON.parse(
    localStorage.getItem("getAddressData") ?? ""
  );

  const setServiceAttributesStorage = localStorage.getItem(
    "setServiceAttributes"
  );
  const setServiceAttributes = setServiceAttributesStorage
    ? JSON.parse(setServiceAttributesStorage ?? "")
    : "";

  const listDocumentsImage = useSelector(
    (state: any) => state.SendDocumentsReducer?.listDocumentsImage ?? []
  );
  const operationInfo = JSON.parse(
    localStorage.getItem("operationInfo") ??
    JSON.stringify({ id: process.env.REACT_APP_STANDARD_SQUARE })
  );
  const sessionToken = localStorage.getItem("token") ?? "";
  const getDateData = JSON.parse(localStorage.getItem("getDateData") ?? "");
  const patientData = JSON.parse(localStorage.getItem("patientData") ?? "");
  const planInfos = JSON.parse(localStorage.getItem("planInfos") ?? "");
  const partnerInfo = JSON.parse(localStorage.getItem("partnerInfo") ?? "");
  const priceValue = localStorage.getItem("priceValue") ?? "";
  const subRoute = localStorage.getItem("subRoute") ?? "";
  const walletNumber = localStorage.getItem("walletNumber") ?? "";
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [checkBoxOption, setCheckBoxOption] = useState(false);
  const isTokenValid = useSelector(
    (state: any) => state.AccountReducer?.isTokenValid
  );
  const listOfProducts = JSON.parse(
    localStorage.getItem("listOfProducts") ?? "[]"
  );
  const [appointmentItems, setAppointmentItems] = useState<any>([]);
  const discount = JSON.parse(localStorage.getItem("discount") ?? "");
  const finalPriceValue = parseInt(priceValue);

  useEffect(() => {
    dispatch(isExpValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isTokenValid) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAppointmentItems([]);
    const oldArray = appointmentItems;

    for (var i = 0; i < listOfProducts.length; i++) {
      for (var x = 0; x < listOfProducts[i].count; x++) {
        const newArray = {
          examId: listOfProducts[i].productExam?.examId,
          description: listOfProducts[i].productExam?.name,
        };
        oldArray.push(newArray);
        setAppointmentItems(oldArray);
      }
    }
  }, []);

  const handleConfirm = () => {
    setIsLoaderOpen(true);

    var fullDate = getDateData != "" ? getDateData?.date.split("/") : "";
    var birth = patientData ? patientData?.birthDate.split("/") : "";
    if (subRoute === "particular") {
      if (priceValue > "0") {
        history.push("/pagamento");
      } else {
        const obj = {
          date: `${fullDate[2]}-${fullDate[1]}-${fullDate[0]}T00:00:00Z`,
          scheduleId: getDateData?.dateId,
          type: "private",
          partnerId: partnerInfo?.id ?? undefined,
          voucherId: discount !== "" ? discount?.voucherId : undefined,
          customer: {
            type: patientData?.customerType,
            fullName: patientData?.customer?.fullName,
            documentType: patientData?.customer?.documentType,
            document:
              patientData?.customer?.document !== ""
                ? patientData?.customer?.document
                  .replaceAll(".", "")
                  .replaceAll("-", "")
                : undefined,
            ddd: patientData?.customer?.ddd,
            phone: patientData?.customer?.phone,
            email: patientData?.customer?.email,
          },
          person: {
            fullName: patientData?.fullName,
            birthDate: `${birth[2]}-${birth[1]}-${birth[0]}T00:00:00Z`,
            documentType:
              patientData?.documentType === "Passaporte"
                ? "PASSPORT"
                : patientData?.documentType,
            document:
              patientData?.document !== ""
                ? patientData?.document.replaceAll(".", "").replaceAll("-", "")
                : undefined,
            ddd:
              patientData?.ddd !== ""
                ? patientData?.ddd
                : patientData?.customer?.ddd,
            phone:
              patientData?.phone !== ""
                ? patientData?.phone
                : patientData?.customer?.phone,
            email:
              patientData?.email !== ""
                ? patientData?.email
                : patientData?.customer?.email,
            zipCode: patientData?.zipCode.replaceAll("-", ""),
            street: patientData?.street,
            streetNumber: patientData?.streetNumber,
            neighborhood: patientData?.neighborhood,
            complement: patientData?.complement,
            city: patientData?.city,
            state: patientData?.state,
            gender: patientData?.gender,
            insuranceId: walletNumber || null,
          },
          appointmentItems: listOfProducts.map((item: any) => {
            return {
              productId: item.id,
              quantity: 1,
            };
          }),
          privateData: {},
          files: files,
          squareId: operationInfo.id,
        };
        dispatch(
          createAppointment(
            JSON.stringify(obj),
            sessionToken,
            (response: any) => {
              setIsLoaderOpen(false);
              if (response?.status === 200 || response?.status === 201) {
                history.push("/agendamento-confirmado");
              } else {
                setIsDialogOpen(true);
              }
            }
          )
        );
      }
    } else {
      handleFinishHealthInsuranceAppointment();
    }
  };

  const handleFinishHealthInsuranceAppointment = async () => {
    const appointmentDateISO = DateTime.fromFormat(
      getDateData?.date,
      "dd/MM/yyyy"
    ).toISO();
    const birthDateISO = patientData
      ? DateTime.fromFormat(patientData?.birthDate, "dd/MM/yyyy").toISO()
      : "";

    if (listDocumentsImage.length !== 0) {
      if (listDocumentsImage?.medicRequest.length > 0) {
        for (var i = 0; i < 3; i++) {
          if (i === 0 && listDocumentsImage.medicRequest.length != 0) {
            listDocumentsImage.medicRequest.map(
              async (item: any, index: number) => {
                const resS3Upload: any = await s3Upload("medicRequest", item, `${index + 1}`);
                const data = {
                  path: `https://documentos-isa.s3.sa-east-1.amazonaws.com${resS3Upload?.request?.httpRequest?.path.replaceAll(
                    "%20",
                    "+"
                  )}`,
                  description:
                    partnerInfo.document !== "35268347000102"
                      ? `pedido médico - ${index + 1}`
                      : "documento",
                };
                const newArray = files;
                newArray.push(data);
                setFiles(newArray);
              }
            );
          } else if (i === 1 && listDocumentsImage.walletFront.length != 0) {
            setIsLoaderOpen(true);
            const resS3Upload: any = await s3Upload(
              "walletFront",
              listDocumentsImage.walletFront[0]
            );
            const data = {
              path: `https://documentos-isa.s3.sa-east-1.amazonaws.com${resS3Upload?.request?.httpRequest?.path.replaceAll(
                "%20",
                "+"
              )}`,
              description:
                partnerInfo.document !== "35268347000102"
                  ? "carteirinha frente"
                  : "convite",
            };
            const newArray = files;
            newArray.push(data);
            setFiles(newArray);
          } else if (i === 2 && listDocumentsImage.walletBack.length != 0) {
            setIsLoaderOpen(true);
            const resS3Upload: any = await s3Upload(
              "walletBack",
              listDocumentsImage.walletBack[0]
            );
            const data = {
              path: `https://documentos-isa.s3.sa-east-1.amazonaws.com${resS3Upload?.request?.httpRequest?.path.replaceAll(
                "%20",
                "+"
              )}`,
              description: "carteirinha verso",
            };
            const newArray = files;
            newArray.push(data);
            setFiles(newArray);
          } else if (
            i === 3 &&
            listDocumentsImage.vaccinationCard.length != 0
          ) {
            setIsLoaderOpen(true);
            const resS3Upload: any = await s3Upload(
              "vaccinationCard",
              listDocumentsImage.vaccinationCard[0]
            );
            const data = {
              path: `https://documentos-isa.s3.sa-east-1.amazonaws.com${resS3Upload?.request?.httpRequest?.path.replaceAll(
                "%20",
                "+"
              )}`,
              description: "carteirinha vacinacao",
            };
            const newArray = files;
            newArray.push(data);
            setFiles(newArray);
          }
        }

        const appointmentData = {
          date: appointmentDateISO,
          scheduleId: getDateData?.dateId,
          type: "healthInsurance",
          operatorId: partnerInfo?.operatorId
            ? partnerInfo?.operatorId
            : planInfos?.id,
          partnerId: partnerInfo?.id ?? undefined,
          customer: {
            type: patientData?.customerType,
            fullName: patientData?.customer?.fullName,
            documentType: patientData?.customer?.documentType,
            document:
              patientData?.customer?.document !== ""
                ? patientData?.customer?.document
                  .replaceAll(".", "")
                  .replaceAll("-", "")
                : undefined,
            ddd: patientData?.customer?.ddd,
            phone: patientData?.customer?.phone,
            email: patientData?.customer?.email,
          },
          person: {
            fullName: patientData?.fullName,
            birthDate: birthDateISO,
            documentType:
              patientData?.documentType === "Passaporte"
                ? "PASSPORT"
                : patientData?.documentType,
            document:
              patientData?.document !== ""
                ? patientData?.document.replaceAll(".", "").replaceAll("-", "")
                : undefined,
            ddd:
              patientData?.ddd !== ""
                ? patientData?.ddd
                : patientData?.customer?.ddd,
            phone:
              patientData?.phone !== ""
                ? patientData?.phone
                : patientData?.customer?.phone,
            email:
              patientData?.email !== ""
                ? patientData?.email
                : patientData?.customer?.email,
            zipCode: patientData?.zipCode.replaceAll("-", ""),
            street: patientData?.street,
            streetNumber: patientData?.streetNumber,
            neighborhood: patientData?.neighborhood,
            complement: patientData?.complement,
            city: patientData?.city,
            state: patientData?.state,
            gender: patientData?.gender,
            insuranceId: walletNumber || null,
          },
          files: files,
          squareId: operationInfo.id,
        };

        dispatch(
          createAppointment(
            JSON.stringify(appointmentData),
            sessionToken,
            (response: any) => {
              setIsLoaderOpen(false);
              if (response?.status === 200 || response?.status === 201) {
                setIsLoaderOpen(false);
                ReactPixel.trackCustom("Confirmation", {
                  confirmation: "health insurance",
                });
                let dataLayer = window.dataLayer || [];
                dataLayer.push({
                  event: "confirmacao",
                  ecommerce: {
                    tipo: "convenio",
                  },
                });
                history.push("/agendamento-confirmado");
              } else {
                setIsLoaderOpen(false);
                setIsDialogOpen(true);
              }
            }
          )
        );
      } else {
        const appointmentData = {
          date: appointmentDateISO,
          scheduleId: getDateData?.dateId,
          type: "healthInsurance",
          operatorId: partnerInfo?.operatorId
            ? partnerInfo?.operatorId
            : planInfos?.id,
          partnerId: partnerInfo?.id ?? undefined,
          customer: {
            type: patientData?.customerType,
            fullName: patientData?.customer?.fullName,
            documentType: patientData?.customer?.documentType,
            document:
              patientData?.customer?.document !== ""
                ? patientData?.customer?.document
                  .replaceAll(".", "")
                  .replaceAll("-", "")
                : undefined,
            ddd: patientData?.customer?.ddd,
            phone: patientData?.customer?.phone,
            email: patientData?.customer?.email,
          },
          person: {
            fullName: patientData?.fullName,
            birthDate: birthDateISO,
            documentType:
              patientData?.documentType === "Passaporte"
                ? "PASSPORT"
                : patientData?.documentType,
            document:
              patientData?.document !== ""
                ? patientData?.document.replaceAll(".", "").replaceAll("-", "")
                : undefined,
            ddd:
              patientData?.ddd !== ""
                ? patientData?.ddd
                : patientData?.customer?.ddd,
            phone:
              patientData?.phone !== ""
                ? patientData?.phone
                : patientData?.customer?.phone,
            email:
              patientData?.email !== ""
                ? patientData?.email
                : patientData?.customer?.email,
            zipCode: patientData?.zipCode.replaceAll("-", ""),
            street: patientData?.street,
            streetNumber: patientData?.streetNumber,
            neighborhood: patientData?.neighborhood,
            complement: patientData?.complement,
            city: patientData?.city,
            state: patientData?.state,
            gender: patientData?.gender,
            insuranceId: walletNumber || null,
          },
          files: files,
          squareId: operationInfo.id,
        };

        dispatch(
          createAppointment(
            JSON.stringify(appointmentData),
            sessionToken,
            (response: any) => {
              setIsLoaderOpen(false);
              if (response?.status === 200 || response?.status === 201) {
                setIsLoaderOpen(false);
                history.push("/agendamento-confirmado");
              } else {
                setIsLoaderOpen(false);
                setIsDialogOpen(true);
              }
            }
          )
        );
      }
    } else {
      const appointmentData = {
        date: appointmentDateISO,
        scheduleId: getDateData?.dateId,
        type: "healthInsurance",
        operatorId: partnerInfo?.operatorId
          ? partnerInfo?.operatorId
          : planInfos?.id,
        partnerId: partnerInfo?.id ?? undefined,
        customer: {
          type: patientData?.customerType,
          fullName: patientData?.customer?.fullName,
          documentType: patientData?.customer?.documentType,
          document:
            patientData?.customer?.document !== ""
              ? patientData?.customer?.document
                .replaceAll(".", "")
                .replaceAll("-", "")
              : undefined,
          ddd: patientData?.customer?.ddd,
          phone: patientData?.customer?.phone,
          email: patientData?.customer?.email,
        },
        person: {
          fullName: patientData?.fullName,
          birthDate: birthDateISO,
          documentType:
            patientData?.documentType === "Passaporte"
              ? "PASSPORT"
              : patientData?.documentType,
          document:
            patientData?.document !== ""
              ? patientData?.document.replaceAll(".", "").replaceAll("-", "")
              : undefined,
          ddd:
            patientData?.ddd !== ""
              ? patientData?.ddd
              : patientData?.customer?.ddd,
          phone:
            patientData?.phone !== ""
              ? patientData?.phone
              : patientData?.customer?.phone,
          email:
            patientData?.email !== ""
              ? patientData?.email
              : patientData?.customer?.email,
          zipCode: patientData?.zipCode.replaceAll("-", ""),
          street: patientData?.street,
          streetNumber: patientData?.streetNumber,
          neighborhood: patientData?.neighborhood,
          complement: patientData?.complement,
          city: patientData?.city,
          state: patientData?.state,
          gender: patientData?.gender,
          insuranceId: walletNumber || null,
        },
        files: files,
        squareId: operationInfo.id,
      };

      dispatch(
        createAppointment(
          JSON.stringify(appointmentData),
          sessionToken,
          (response: any) => {
            setIsLoaderOpen(false);
            if (response?.status === 200 || response?.status === 201) {
              setIsLoaderOpen(false);
              history.push("/agendamento-confirmado");
            } else {
              setIsLoaderOpen(false);
              if (response?.status === 200 || response?.status === 201) {
                setIsLoaderOpen(false);
                history.push("/agendamento-confirmado");
              } else {
                setIsLoaderOpen(false);
                setIsDialogOpen(true);
              }
            }
          }
        )
      );
    }
  };

  const s3Upload = async (fileName: string, fileDoc: any, index: string = '') => {
    var bucketName = "";
    if (fileName === "medicRequest") {
      bucketName =
        partnerInfo.document !== "35268347000102"
          ? `/pedido medico ${index}`
          : `/documento`;
    } else {
      bucketName =
        partnerInfo.document !== "35268347000102" ? "/carteirinha" : "/convite";
    }
    const params = {
      Body: fileDoc.file,
      Bucket: `${S3_BUCKET}/${patientData.fullName}${bucketName}`,
      Key: fileDoc.file.name,
    };

    return myBucket
      .putObject(params)
      .on("httpDone", async (res: any) => { })
      .send((err) => { });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IsaLoader open={isLoaderOpen} />
      <IsaDialog
        open={isDialogOpen}
        header="Algo deu errado"
        bodyText="Infelizmente algo deu errado com a conexão, tente novamente mais tarde"
        buttonText="Fechar"
        closeDialog={() => setIsDialogOpen(false)}
      />
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() =>
                subRoute === "particular"
                  ? history.push("/carrinho")
                  : history.push("/enviar-documentos")
              }
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName={setServiceAttributes.pageName ?? ""}
      />
      <Grid
        style={{ height: "100%" }}
        container
        direction="column"
        justifyContent="start"
        alignItems="center"
      >
        <IsaContainer>
          <IsaBreadcrumbs>
            <Balls
              marginRight="43px"
              isHaveHeader={true}
              header=""
              width="10px"
            />
            <Balls
              marginRight="43px"
              isHaveHeader={true}
              header=""
              width="10px"
            />
            <Balls
              marginRight="43px"
              isHaveHeader={true}
              header=""
              width="10px"
            />
            <Balls
              marginRight="8px"
              isHaveHeader={true}
              header=""
              width="10px"
            />
            <Balls
              marginRight="0px"
              isHaveHeader={true}
              header="Resumo"
              width="20px"
            />
          </IsaBreadcrumbs>
          <div style={{ marginTop: 40 }} />
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              container
              maxWidth={720}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <img alt="ico" src={IcoSucess} />
              <div style={{ marginBottom: 48 }} />

              <IsaH3Text color="#004474" textAlign="center">
                Resumo
              </IsaH3Text>
              <div style={{ marginBottom: 24 }} />

              <IsaPText textAlign="center" color="#666666">
                Confirme os dados do seu agendamento <br />
                para confirmar a coleta
              </IsaPText>
              <div style={{ marginBottom: 16 }} />

              <IsaPText textAlign="center" color="#666666">
                <span style={{ fontWeight: "bold" }}>Nome:</span>{" "}
                {patientData?.fullName}
              </IsaPText>
              <IsaPText textAlign="center" color="#666666">
                <span style={{ fontWeight: "bold" }}>Endereço: </span>
                {patientData?.street}, {patientData?.streetNumber}{" "}
                {patientData?.complement} - {patientData?.city},{" "}
                {patientData?.SP}
              </IsaPText>
              <IsaPText textAlign="center" color="#666666">
                <span style={{ fontWeight: "bold" }}>Data:</span>{" "}
                {getDateData.date}
              </IsaPText>
              <IsaPText textAlign="center" color="#666666">
                <span style={{ fontWeight: "bold" }}>Chegada:</span> Entre{" "}
                {getDateData.hour}
              </IsaPText>
              {subRoute === "particular" ? (
                <IsaPText textAlign="center" color="#666666">
                  <span style={{ fontWeight: "bold" }}>Valor:</span> R${" "}
                  {priceValue != "0" ? formatMoney(finalPriceValue) : "0,00"}
                </IsaPText>
              ) : (
                <div />
              )}
              <div style={{ marginTop: 24 }} />
              <IsaCheckbox
                onChange={(e: any) => setCheckBoxOption(e.target.checked)}
              />

              <div style={{ marginTop: 100 }} />
              <IsaButton
                onClick={() => handleConfirm()}
                disabled={!checkBoxOption}
              >
                {subRoute !== "particular" || priceValue == "0"
                  ? "confirmar agendamento"
                  : "proceder para pagamento"}
              </IsaButton>
              <div style={{ marginTop: 38 }} />
            </Grid>
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
