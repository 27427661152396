import {
  GET_LOGIN_USER,
  IS_EXP_VALID,
  SET_DISPLAY_PAGE,
} from "./accountConstants";

const INITIAL_STATE = {
  getLoginUser: [],
  isTokenValid: false,
  validationPage: "smsValidate",
  telephoneNumber: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_LOGIN_USER:
      var _getLoginUser;
      _getLoginUser = action.data;

      localStorage.setItem("token", action.data.token);
      localStorage.setItem("exp", action.data.exp);
      return {
        ...state,
        getLoginUser: _getLoginUser,
      };

    case IS_EXP_VALID:
      var _isTokenValid;

      var date1: any = new Date(parseInt(localStorage.getItem("exp") ?? "0"));
      let loginExp = Math.round(date1);
      let dateNow: any = new Date();
      let dateNowRound = Math.round(dateNow / 1000);

      if (date1 !== "Invalid Date") {
        if (loginExp < dateNowRound) {
          localStorage.setItem("exp", "");
          localStorage.setItem("getAddressData", "[]");
          localStorage.setItem("listOfProducts", "[]");
          localStorage.setItem("token", "");
          localStorage.setItem("planInfos", '""');
          localStorage.setItem("loginEmail", "");
          localStorage.setItem("getDateData", "");
          localStorage.setItem("partnerInfo", "0");
          _isTokenValid = true;
        }
      }

      return {
        ...state,
        isTokenValid: _isTokenValid,
      };

    case SET_DISPLAY_PAGE:
      var _validationPage;
      var _telephoneNUmber;

      _validationPage = action.data.page;
      _telephoneNUmber = action.data.phoneNumber;

      return {
        ...state,
        validationPage: _validationPage,
        telephoneNumber: _telephoneNUmber,
      };
  }

  return state;
};
