import {
  GET_LOGIN_USER,
  IS_EXP_VALID,
  SET_DISPLAY_PAGE,
} from "./accountConstants";

import { apiIsa, authToken } from "../../../core/services/api";

const loginUserEnd = (dispatch: any, data: any) => {
  dispatch({ type: GET_LOGIN_USER, data });
};

export const createUser = (data: any, callback: any) => {
  return (dispatch: any) => {
    apiIsa
      .post("/user", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken}`,
        },
      })
      .then((responseJson) => {
        //createUserEnd(dispatch,responseJson?.data)
        callback(responseJson?.data);
      })
      .catch((err) => {
        callback(err.response);
      });
  };
};

export const loginUser = (data: any, callback: any) => {
  return (dispatch: any) => {
    apiIsa
      .post("/token", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken}`,
        },
      })
      .then((responseJson) => {
        loginUserEnd(dispatch, responseJson?.data);
        callback(responseJson?.data);
      })
      .catch((err) => {
        callback(err.response);
      });
  };
};

export const isExpValid = () => {
  return (dispatch: any) => {
    dispatch({ type: IS_EXP_VALID });
  };
};

export const changePageView = (phoneNumber: string, page: string) => {
  return (dispatch: any) => {
    dispatch({ type: SET_DISPLAY_PAGE, data: { phoneNumber, page } });
  };
};
