import { apiIsaV2 } from "./api";

class LocationService {
  async searchLocationByCep(params?: any): Promise<any> {
    const { data } = await apiIsaV2.get(`/location/addresses/${params?.cep}`);
    return data;
  }
}

export default LocationService;
