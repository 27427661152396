import styled from 'styled-components';

export const DialogContainer = styled.div`
  display:flex;
  justify-content:center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  height:100%;
  width:100%;

  p{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    width:100%
  }

  h5{
    color: #FFFFFF;
  }
`;