import { Box } from "@mui/material";
import { FormInput } from "../../../shared/components/FormInput/FormInput";
import { IsaPText } from "../../../shared/components/IsaPText/IsaPText";
import { BoxWrapper } from "../patientDataStyle";
import {
  listOfDocumentTypesByLegalResponsible,
  listOfDocumentTypes,
  listOfGender,
} from "../schema";
import { FormSelect } from "../../../shared/components/FormSelect/FormSelect";
import { DocProps, FormProps, MappingProps } from "./types";
import { showOrHideField } from "./utils";
import { useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { DefaultValueProps } from "../types";

enum ABOUT_PATIENT {
  PATIENT = "PATIENT",
  RESPONSIBLE = "RESPONSIBLE",
  OTHER_PERSON = "OTHER_PERSON",
}

export const PatientForm = ({
  register,
  errors,
  control,
  showOrHideInputs,
  defaultValue,
  changeLabels,
}: FormProps) => {
  const [defaultDocValue, setDefaultDocValue] = useState<
    DefaultValueProps | undefined
  >({
    value: defaultValue?.documentType,
    label: defaultValue?.documentType,
  });
  const selectedOption = useWatch({
    control,
    name: "documentType",
  });
  const selectedAboutPatientOption = useWatch({
    control,
    name: "patientOption",
  });

  const listToRender =
    selectedAboutPatientOption !== "PATIENT"
      ? listOfDocumentTypesByLegalResponsible
      : listOfDocumentTypes;

  useEffect(() => {
    const mapping: MappingProps[] | any = {
      CPF: listOfDocumentTypesByLegalResponsible,
      PASSPORT: listOfDocumentTypes,
      NO_DOC: listOfDocumentTypesByLegalResponsible,
    };

    const findValue = mapping[selectedOption]?.find(
      (item: DocProps) => item.value === selectedOption
    );
    setDefaultDocValue(findValue);
  }, [selectedOption]);

  const maskType = () => {
    const maskMap: any = {
      CPF: "999.999.999-99",
      // RG: "99.999.999-9",
      PASSPORT: "aa999999",
      NO_DOC: "",
    };
    return maskMap[selectedOption] || "";
  };

  return (
    <Box sx={{ marginTop: "27px" }}>
      <IsaPText
        color="#CCCCCC"
        textAlign="start"
        fontWeight="bold"
        fontSize="12px"
      >
        DADOS DO PACIENTE
      </IsaPText>
      <BoxWrapper
        showOrHide={showOrHideField(showOrHideInputs, "fullName")}
        sx={{
          marginTop: "21px",
        }}
      >
        <FormInput
          label="Nome completo"
          name="fullName"
          register={register}
          errors={errors}
          unregister={!showOrHideField(showOrHideInputs, "fullName")}
        />
      </BoxWrapper>
      <Box sx={{ display: "flex", gap: "24px", marginTop: "21px" }}>
        <BoxWrapper
          showOrHide={showOrHideField(showOrHideInputs, "birthday")}
          width="236px"
        >
          <FormInput
            label="Data de nascimento"
            name="birthday"
            register={register}
            errors={errors}
            control={control}
            mask="99/99/9999"
            unregister={!showOrHideField(showOrHideInputs, "birthday")}
          />
        </BoxWrapper>
        <BoxWrapper
          showOrHide={showOrHideField(showOrHideInputs, "gender")}
          width="100%"
        >
          <FormSelect
            label="Gênero"
            name="genderOption"
            register={register}
            errors={errors}
            options={listOfGender}
            defaultValue={defaultValue?.gender ?? undefined}
            unregister={!showOrHideField(showOrHideInputs, "gender")}
          />
        </BoxWrapper>
      </Box>
      <Box sx={{ display: "flex", gap: "24px", marginTop: "21px" }}>
        <BoxWrapper
          showOrHide={showOrHideField(showOrHideInputs, "documentType")}
          width="236px"
        >
          <FormSelect
            label="Documento"
            name="documentType"
            register={register}
            errors={errors}
            options={listToRender}
            defaultValue={defaultDocValue?.value}
            unregister={!showOrHideField(showOrHideInputs, "gender")}
          />
        </BoxWrapper>
        <BoxWrapper
          showOrHide={showOrHideField(showOrHideInputs, "document")}
          width="100%"
        >
          <p>{showOrHideField(showOrHideInputs, "document")}</p>
          <FormInput
            label="Número do documento"
            name="document"
            register={register}
            errors={errors}
            control={control}
            mask={maskType()}
            disabled={selectedOption === "NO_DOC"}
            unregister={!showOrHideField(showOrHideInputs, "document")}
          />
        </BoxWrapper>
      </Box>
      <Box
        sx={{
          marginTop: "21px",
          display:
            changeLabels === ABOUT_PATIENT.OTHER_PERSON ? "flex" : "none",
        }}
      >
        <IsaPText
          color="#CCCCCC"
          textAlign="start"
          fontWeight="bold"
          fontSize="12px"
        >
          CONTATO DO PACIENTE
        </IsaPText>
      </Box>
      <BoxWrapper
        showOrHide={showOrHideField(showOrHideInputs, "email")}
        sx={{
          marginTop: "21px",
        }}
      >
        <FormInput
          label="E-mail"
          name="email"
          register={register}
          errors={errors}
          unregister={true}
        />
      </BoxWrapper>
      <BoxWrapper
        showOrHide={showOrHideField(showOrHideInputs, "cellphone")}
        sx={{
          marginTop: "21px",
        }}
      >
        <FormInput
          label="Celular"
          name="cellphone"
          register={register}
          errors={errors}
          control={control}
          mask="(99) 99999-9999"
          unregister={!showOrHideField(showOrHideInputs, "cellphone")}
        />
      </BoxWrapper>
    </Box>
  );
};
