import { 
  SET_PRODUCTS,
	TESTE,
	PRICE_VALUE
} from "./shopConstants";

const setProductsToCartEnd = (dispatch:any, data:any) => {
  dispatch({type: SET_PRODUCTS, data})
};

export const setProductsToCart = (data: any) => {
	return (dispatch:any) => {
		setProductsToCartEnd(dispatch,data)
	};
};

export const setTeste = (data: any) => {
	return (dispatch:any) => {
		dispatch({type: TESTE, data})
	};
};

export const savePriceValue = (data: any) => {
	return (dispatch:any) => {
		dispatch({type: PRICE_VALUE, data})
	};
};
