/* eslint-disable react-hooks/exhaustive-deps */
import Drawer from "@mui/material/Drawer";
import { MoreDatailsProps, ShowMoreProps } from "./showMoreInterface";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

//Css
import "./showMore.css";
import {
  DialogContainer,
  Container,
  ContainerButton,
  MarginCButton,
} from "./showMoreStyle";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setProductsToCart } from "../../../../shared/redux/shop/shopActions";
import { formatMoney } from "../../../../shared/validators/formatMoney";
import { IsaContainer } from "../../../../shared/components/IsaContainer/IsaContainer";
import { IsaHighlightButton } from "../../../../shared/components/IsaButton/IsaButton";
import { DiscountContainer } from "../../selectedServiceStyle";

export function ShowMoreDialog({ open, handleClose }: ShowMoreProps) {
  const dispatch = useDispatch();
  const getListProducts = useSelector(
    (state: any) => state.SelectedReducer?.listProducts ?? []
  );
  const currentProduct = useSelector(
    (state: any) => state.SelectedReducer?.listFindProduct
  );
  const listFindExam = currentProduct?.exams[0];
  const list = JSON.parse(localStorage.getItem("listOfProducts") ?? "[]");
  const partnerDiscount: any = localStorage.getItem("partnerDiscount");
  const partnerName = localStorage.getItem("partnerName") ?? null;
  const [moreDetails, setMoreDetails] = useState<MoreDatailsProps | any>();

  useEffect(() => {
    const found =
      listFindExam !== undefined
        ? getListProducts.findIndex(
            (item: any) => item.exams[0].ProductExam?.examId === listFindExam.id
          )
        : [];
    setMoreDetails({
      ...getListProducts[found],
      price: currentProduct?.price
    });
    disableBuyButton();
  }, [listFindExam]);

  const disableBuyButton = () => {
    const findProductOnCart = list.find(
      (item: any) => item.id === currentProduct?.id
    );
    return !!findProductOnCart;
  };

  const handleAddToCart = () => {
    const found = list.find((item: any) => item.id === currentProduct.id);

    if (!found) {
      const array = list;
      array.push({
        id: currentProduct.id,
        name: currentProduct.name,
        price: currentProduct.price,
        productExam: currentProduct.exams[0],
        count: 1,
      });
      dispatch(setProductsToCart(list));
    }
    handleClose();
  };

  const setValue = (item: any) => {
    if (item > 0) {
      return formatMoney(item - item * (partnerDiscount / 100));
    } else {
      return "0,00";
    }
  };

  return (
    <div>
      <Drawer elevation={16} anchor="bottom" open={open} onClose={handleClose}>
        <IsaContainer display="flex" justifyContent="center">
          <DialogContainer>
            <div style={{ marginTop: 73 }} />
            <Container justifyContent="flex-end">
              <CancelOutlinedIcon
                style={{ cursor: "pointer", color: "white" }}
                onClick={handleClose}
              />
            </Container>
            <div style={{ marginTop: 24 }} />
            <Container justifyContent="flex-start" flexDirection="column">
              {currentProduct?.description ? (
                <>
                  <h3>{currentProduct?.name}</h3>
                  <div style={{ marginTop: 16 }} />
                  <p>{currentProduct?.description}</p>
                </>
              ) : (
                <>
                  <h3>{currentProduct?.name}</h3>
                  <div style={{ marginTop: 16 }} />
                  <p>{listFindExam?.description}</p>
                  <div style={{ marginTop: 16 }} />
                  {listFindExam?.prevention ? (
                    <p>
                      <b>O que previne: </b>
                      {listFindExam.prevention}
                    </p>
                  ) : (
                    ""
                  )}
                  {listFindExam?.methodApplication ? (
                    <p>
                      <b>Como é aplicado: </b>
                      {listFindExam.methodApplication}
                    </p>
                  ) : (
                    ""
                  )}
                  {listFindExam?.indication ? (
                    <p>
                      <b>Indicação: </b>
                      {listFindExam.indication}
                    </p>
                  ) : (
                    ""
                  )}
                  {listFindExam?.againstIndication ? (
                    <p>
                      <b>Contra indicação: </b>
                      {listFindExam.againstIndication}
                    </p>
                  ) : (
                    ""
                  )}
                  {listFindExam?.preparation ? (
                    <p>
                      <b>Preparação: </b>
                      {listFindExam.preparation}
                    </p>
                  ) : (
                    ""
                  )}
                  {listFindExam?.resultTime ? (
                    <p>
                      <b>Resultado em: </b>
                      {listFindExam.resultTime}
                    </p>
                  ) : (
                    ""
                  )}
                  {listFindExam?.isRequiredPrescription ? (
                    <p>
                      <b>Preciso de prescrição: </b>
                      {listFindExam.isRequiredPrescription}
                    </p>
                  ) : (
                    ""
                  )}
                </>
              )}
              <div style={{ marginTop: 32 }} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 18,
                }}
              >
                <p style={{ fontSize: 32, fontWeight: "bold" }}>
                  <span>R$</span>
                  {partnerDiscount !== "null" && partnerDiscount !== "0"
                    ? setValue(moreDetails?.price)
                    : formatMoney(moreDetails?.price)}
                </p>
                {partnerDiscount !== "null" && partnerDiscount !== "0" ? (
                  <DiscountContainer>
                    {`${partnerDiscount}% OFF ${partnerName?.toLowerCase()}`}
                  </DiscountContainer>
                ) : (
                  ""
                )}
              </div>
              <div style={{ marginTop: 32 }} />
              <ContainerButton>
                <div style={{ marginTop: 16 }} />
                <MarginCButton>
                  <IsaHighlightButton
                    showIcon={true}
                    icon={<ShoppingBagOutlinedIcon />}
                    background="#FFFFFF"
                    color="#004474"
                    shadow={false}
                    border="1px solid #FFFFFF"
                    onClick={handleAddToCart}
                    disabled={disableBuyButton()}
                  >
                    comprar
                  </IsaHighlightButton>
                </MarginCButton>
                <MarginCButton>
                  <IsaHighlightButton
                    showIcon={false}
                    background="transparent"
                    color="#FFFFFF"
                    shadow={false}
                    border="1px solid #FFFFFF"
                    onClick={handleClose}
                  >
                    voltar
                  </IsaHighlightButton>
                </MarginCButton>
              </ContainerButton>
            </Container>
          </DialogContainer>
        </IsaContainer>
      </Drawer>
    </div>
  );
}
