import { SAVE_ADDRESS_DATA } from "./addressConstants";

import { apiIsa } from "../../../core/services/api";

const authToken = process.env.REACT_APP_AUTHTOKEN;

export const saveAddressData = (data: any) => {
  return (dispatch: any) => {
    dispatch({ type: SAVE_ADDRESS_DATA, data });
  };
};

export const postProspect = (data: any, callback: any) => {
  return (dispatch: any) => {
    apiIsa
      .post("/prospect", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken}`,
        },
      })
      .then((responseJson) => {
        callback(responseJson?.data);
      })
      .catch((err) => {
        callback(err.message);
      });
  };
};
