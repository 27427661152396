import React from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import { Categories } from '../../features/categories/categories';
import { Initial } from '../../features/initial/initial';
import { SelectedService } from '../../features/selectedService/selectedService';
import { Cart } from '../../features/cart/cart';
import { AddressCep } from '../../features/addressCep/addressCep';
import { Scheduling } from '../../features/scheduling/scheduling'
import { Account } from '../../features/account/account';
import { PatientData } from '../../features/patientData/patientData';
import { SendDocuments } from '../../features/sendDocuments/sendDocuments';
import { SendVaccinationCard } from '../../features/sendVaccinationCard/sendVaccinationCard';
import { Confirmation } from '../../features/confirmation/confirmation';
import { ConfirmedAppointment } from '../../features/confirmedAppointment/confirmedAppointment';
import { SelectHealthInsurance } from '../../features/selectHealthInsurance/selectHealthInsurance';
import { Payment } from '../../features/payment/payment';
import { InitialCart } from '../../features/initialCart/initialCart';
import { ResetPassword } from '../../features/resetPassword/resetPassword';
import { CreateNewPassword } from '../../features/createNewPassword/createNewPassword';
import { TelAuth } from '../../features/account/telAuth/telAuth';

//Routes
class Routes extends React.Component {

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route path="/" exact component={Initial} />
          <Route path="/selecionar-convenio" component={SelectHealthInsurance} />
          <Route path="/categorias" component={Categories} />
          <Route path="/pre-cadastro" component={AddressCep} />
          <Route path="/agendamento" component={Scheduling} />
          <Route path="/conta" component={Account} />
          <Route path="/dados-do-paciente" component={PatientData} />
          <Route path="/confirmacao" component={Confirmation} />
          <Route path="/agendamento-confirmado" component={ConfirmedAppointment} />

          {/* Autenticação via telefone */}
          <Route path="/auth" component={TelAuth} />

          {/* Reset senha */}
          <Route path="/resetar-senha" component={ResetPassword} />
          <Route path="/recuperar-senha" component={CreateNewPassword} />

          {/* Convenio */}
          <Route path="/enviar-documentos" component={SendDocuments} />
          <Route path="/enviar-caderneta-de-vacinas" component={SendVaccinationCard} />

          {/* Particular */}
          <Route path="/servico-selecionado" component={SelectedService} />
          <Route path="/carrinho-inicial" component={InitialCart} />
          <Route path="/carrinho" component={Cart} />
          <Route path="/pagamento" component={Payment} />
        </Switch>
      </HashRouter>
    );
  }
}

export default Routes;