/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { setServiceAttributes } from "../selectedService/redux/selectedActions";

//Local Imports
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";
import IsaLoader from "../../shared/components/IsaLoader/isaLoader";

//Logo
import IcoBack from "../../shared/assets/ico_back.svg";

//Css
import "./categories.css";
import { Button, BorderIcon } from "./categoriesStyle";
import { isExpValid } from "../account/redux/accountActions";
import mixpanel from "mixpanel-browser";
import { apiIsaV2, authToken, mixPanelOn } from "../../core/services/api";
import { deserialize, CaseType } from "jsonapi-fractal";
import {
  GroupsProps,
  ProductGroupByModalitiesProps,
  ProductGroupsItemsProps,
} from "./types";

declare const window: any;

export function Categories() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isTokenValid = useSelector(
    (state: any) => state.AccountReducer?.isTokenValid
  );
  const partnerInfo = JSON.parse(localStorage.getItem("partnerInfo") ?? "");
  const subRoute = localStorage.getItem("subRoute") ?? "";
  const mixToken: string = localStorage.getItem("mixPanelToken") ?? "";
  const partnerName = localStorage.getItem("partnerName");
  const operationInfo = JSON.parse(
    localStorage.getItem("operationInfo") ??
      JSON.stringify({ id: process.env.REACT_APP_STANDARD_SQUARE })
  );
  const [isOpen, setIsOpen] = useState(false);
  const [openToggle, setOpenToggle] = useState(false);
  const [productGroupsModalities, setProductGroupsModalities] = useState<any[]>(
    []
  );

  useEffect(() => {
    if (operationInfo) geCategoryByModalities();
  }, []);

  useEffect(() => {
    dispatch(isExpValid());
  }, []);
  useEffect(() => {
    if (isTokenValid) {
      history.push("/");
    }
  }, []);
  const geCategoryByModalities = async () => {
    setIsOpen(true);
    try {
      const res = await apiIsaV2.get(`/operations/${operationInfo.id}`, {
        params: {
          include:
            "modalities,product_groups,products,schedule,schedule.slot,slot.slot_time,date_unavailable,date_unavailable.slot_unavailable,slot_unavailable.slot_time",
          "filter[partnerId]": partnerInfo?.id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken ?? "",
        },
      });

      const dataReturn: any = deserialize(res.data, {
        changeCase: CaseType.camelCase,
      });

      const productGroups = [
        ...new Set(
          dataReturn.products.map(
            (item: ProductGroupsItemsProps) => item.productGroups.id
          )
        ),
      ];
      const groups = dataReturn.productGroups.filter((item: GroupsProps) =>
        productGroups.includes(item.id)
      );
      if (partnerInfo === "0") {
        const desiredAliases = [
          "vaccine",
          "blood",
          "urine_and_faeces",
          "checkups",
          "covid_19",
        ];
        const productGroupsByAlias = dataReturn?.productGroups;
        const filteredProducts = desiredAliases.map((alias) =>
          productGroupsByAlias.find((product: any) => product.alias === alias)
        );

        return setProductGroupsModalities(filteredProducts ?? []);
      }

      setProductGroupsModalities(groups ?? []);
    } catch (err: any) {
      console.log(err.response);
    } finally {
      setIsOpen(false);
    }
  };

  const handleSelectedByModalities = (
    product: ProductGroupByModalitiesProps
  ) => {
    setIsOpen(true);
    dispatch(setServiceAttributes({ product }));
    setIsOpen(false);
    if (mixPanelOn === "true") {
      mixpanel.identify(mixToken);
      mixpanel.track("Escolha de produto", {
        produto: product.description,
        fluxo: `${partnerName ?? "Isa lab"}`,
      });
      mixpanel.people.set({
        "Escolha produto": product.description,
      });
      let dataLayer = window.dataLayer || [];

      dataLayer.push({
        event: "escolha_de_produto",
        ecommerce: {
          produto: product.description,
          fluxo: `${partnerName ?? "Isa lab"}`,
        },
      });
    }

    if (subRoute === "particular") return history.push("/servico-selecionado");
    localStorage.setItem("categoryIdForOperator", product.id);

    history.push("/pre-cadastro");
  };

  const productGroupListByModalities = () => {
    return (
      <>
        {productGroupsModalities?.map(
          (productGroup: ProductGroupByModalitiesProps | any) => {
            return (
              <Button
                key={productGroup?.id}
                onClick={() => handleSelectedByModalities(productGroup)}
              >
                <BorderIcon>
                  <img src={productGroup?.icon} alt="vacinas" />
                </BorderIcon>
                <p>{productGroup?.description}</p>
              </Button>
            );
          }
        )}
      </>
    );
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <IsaLoader open={isOpen} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openToggle}
        autoHideDuration={3000}
        onClose={() => setOpenToggle(false)}
        message="Produto indisponível no momento"
        action={
          <>
            <IconButton
              style={{ color: "#E93B5A" }}
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() => setOpenToggle(false)}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </>
        }
      />
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() =>
                partnerInfo === "0"
                  ? history.push("/selecionar-convenio")
                  : history.push("/")
              }
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName="O que você precisa?"
      />
      <Grid
        style={{ height: "100%" }}
        container
        direction="column"
        justifyContent="start"
        alignItems="center"
      >
        <IsaContainer>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              maxWidth={720}
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 32 }}
            >
              {productGroupListByModalities()}
            </Grid>
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
