//Css
import {
  CheckBoxDiv,
  Input,
  LabelTitle
} from './isaInputStyle'

import Checkbox from '@mui/material/Checkbox';
import { CheckBoxOff, CheckBoxOn } from '../../assets/isaIcons';

export function IsaInput(props: any) {
  return (
    <>
      {props.inputTitle 
        ? <LabelTitle secondLabelTitle={props.secondLabelTitle}>
            <h5>
              {props.inputTitle}
            </h5>
            <h5 style={{
              color:'#CCCCCC',
              textTransform: 'uppercase',
              fontSize: 12,
            }}>
              {props.secondLabelTitle}
            </h5>
          </LabelTitle>
        :''
      }
      
      <div style={{marginBottom:8}} />
      <Input
        type={props.type ?? 'text'}
        onChange={props.onChange} 
        value={props.value}
        disabled={props.disabled}
        pattern={props.pattern}
        placeholder={props.placeholder}
        background={props.background ? props.background : '#ffffff'}
        borderColor={props.borderColor ? props.borderColor : '#DDDDDD'}
        border={props.border ? props.border : '#DDDDDD'}
        color={props.color ? props.color : '#000000'}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onClick={props.onClick}
      >
        {props.children}
      </Input>
    </>
  );
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export function IsaCheckbox(props:any){
  return (
    <CheckBoxDiv onChange={props.onChange}>
      <Checkbox {...label} icon={<CheckBoxOff />} checkedIcon={<CheckBoxOn />} />
      <label>
        Li e concordo com a <a target="_blank" href="https://isalab.com.br/politica-de-privacidade" rel="noreferrer">
          Política de Privacidade</a>
      </label>
    </CheckBoxDiv>
  )
}