import { Box } from "@mui/material";
import { FormInput } from "../../../shared/components/FormInput/FormInput";
import { IsaPText } from "../../../shared/components/IsaPText/IsaPText";
import { BoxWrapper } from "../patientDataStyle";
import { listOfDocumentTypes, listOfPatientRelationships } from "../schema";
import { FormSelect } from "../../../shared/components/FormSelect/FormSelect";
import { FormProps } from "./types";
import { showOrHideField } from "./utils";
import { useWatch } from "react-hook-form";

export const ResponsibleOrPaymentData = ({
  register,
  errors,
  control,
  showOrHideInputs,
}: FormProps) => {
  const selectedOption = useWatch({
    control,
    name: "responsibleDocumentType",
  });

  const maskType = () => {
    const maskMap: any = {
      CPF: "999.999.999-99",
      // RG: "99.999.999-9",
      PASSPORT: "aa999999",
    };
    return maskMap[selectedOption] || "";
  };
  return (
    <Box sx={{ marginTop: "27px" }}>
      <IsaPText
        color="#CCCCCC"
        textAlign="start"
        fontWeight="bold"
        fontSize="12px"
      >
        DADOS DO RESPONSÁVEL
      </IsaPText>
      <BoxWrapper
        sx={{ marginTop: "21px" }}
        showOrHide={showOrHideField(showOrHideInputs, "responsibleFullName")}
      >
        <FormInput
          label="Nome completo"
          name="responsibleFullName"
          register={register}
          errors={errors}
        />
      </BoxWrapper>
      <Box sx={{ display: "flex", gap: "24px", marginTop: "21px" }}>
        <BoxWrapper
          width="236px"
          showOrHide={showOrHideField(
            showOrHideInputs,
            "responsibleDocumentType"
          )}
        >
          <FormSelect
            label="Documento"
            name="responsibleDocumentType"
            register={register}
            errors={errors}
            options={listOfDocumentTypes}
          />
        </BoxWrapper>
        <BoxWrapper
          width="100%"
          showOrHide={showOrHideField(showOrHideInputs, "responsibleDocument")}
        >
          <FormInput
            label="Número do documento"
            name="responsibleDocument"
            register={register}
            errors={errors}
            control={control}
            mask={maskType()}
          />
        </BoxWrapper>
      </Box>
      <BoxWrapper
        sx={{ marginTop: "21px" }}
        showOrHide={showOrHideField(showOrHideInputs, "responsibleEmail")}
      >
        <FormInput
          label="E-mail"
          name="responsibleEmail"
          register={register}
          errors={errors}
        />
      </BoxWrapper>
      <BoxWrapper
        sx={{ marginTop: "21px" }}
        showOrHide={showOrHideField(showOrHideInputs, "responsibleCellphone")}
      >
        <FormInput
          label="Celular"
          name="responsibleCellphone"
          register={register}
          errors={errors}
          control={control}
          mask="(99) 99999-9999"
          labelHelper="para contato via whatsapp"
        />
      </BoxWrapper>
      <BoxWrapper
        sx={{ marginTop: "21px" }}
        showOrHide={showOrHideField(
          showOrHideInputs,
          "responsibleRelationship"
        )}
      >
        <FormSelect
          label="Qual sua relação com o paciente"
          name="responsibleRelationship"
          register={register}
          errors={errors}
          options={listOfPatientRelationships}
        />
      </BoxWrapper>
    </Box>
  );
};
