/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { saveDocumentsImage } from "./redux/sendDocumentsActions";

//Local Imports
import {
  Balls,
  IsaBreadcrumbs,
} from "../../shared/components/IsaBreadcrumbs/isaBreadcrumbs";
import { IsaButton } from "../../shared/components/IsaButton/IsaButton";
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";
import { IsaPText } from "../../shared/components/IsaPText/IsaPText";

//Logo
import IcoBack from "../../shared/assets/ico_back.svg";
import IcoDelete from "../../shared/assets/ico_delete.svg";
import IcoUpload from "../../shared/assets/ico_upload.svg";

//Css
import mixpanel from "mixpanel-browser";
import { mixPanelOn } from "../../core/services/api";
import { JpgIco, PdfIco, PngIco } from "../../shared/assets/isaIcons";
import { IsaInput } from "../../shared/components/IsaInput/isaInput";
import IsaLoader from "../../shared/components/IsaLoader/isaLoader";
import resizeImage from "../../shared/validators/resizeImage";
import { isExpValid } from "../account/redux/accountActions";
import {
  DragNDropContainer,
  SupportedFilesContainer,
} from "./sendDocumentsStyle";

declare const window: any;

export function SendDocuments(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const setServiceAttributes = useSelector(
    (state: any) => state.SelectedReducer?.setServiceAttributes ?? []
  );
  const partnerInfo = JSON.parse(localStorage.getItem("partnerInfo") ?? "");
  const [medicRequest, setMedicRequest] = useState<any>([]);
  const [walletFront, setWalletFront] = useState<any>([]);
  const [walletBack, setWalletBack] = useState<any>([]);
  const [walletNumber, setWalletNumber] = useState("");
  const [vaccinationCard, setVaccinationCard] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const isTokenValid = useSelector(
    (state: any) => state.AccountReducer?.isTokenValid
  );
  const mixToken: string = localStorage.getItem("mixPanelToken") ?? "";
  const partnerName = localStorage.getItem("partnerName");
  const subRoute = localStorage.getItem("subRoute") ?? "";

  const [walletNumberValidate, setWalletNumberValidate] = useState({
    background: "#FFF",
    borderColor: "#DDDDDD",
    border: "#DDDDDD",
    color: "",
  });

  useEffect(() => {
    dispatch(isExpValid());
    dispatch(saveDocumentsImage([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isTokenValid) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const medicalRequestEmpty = () => {
    if (setServiceAttributes?.medicalRequestNeeded) {
      if (medicRequest.length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (medicRequest.length > 0 && walletFront.length > 0) {
        if (partnerInfo.document === "36567721000125") {
          if (
            walletNumber === "" ||
            walletNumber === "Informe um número válido"
          ) {
            return true;
          } else {
            return false;
          }
        }

        return false;
      } else {
        return true;
      }
    }
  };

  const handleVaccinationCard = async (e: any) => {
    setIsOpen(true);
    let type = e.target.files[0].type.split("/");
    if (type[0] === "image") {
      resizeImage(e.target.files[0], (res: any) => {
        setVaccinationCard([...vaccinationCard, { file: res }]);
      });
    } else {
      setVaccinationCard([...vaccinationCard, { file: e.target.files[0] }]);
    }
    setIsOpen(false);
  };

  const handleMedicRequest = async (e: any) => {
    setIsOpen(true);
    let type = e.target.files[0].type.split("/");
    if (type[0] === "image") {
      resizeImage(e.target.files[0], (res: any) => {
        setMedicRequest([...medicRequest, { file: res }]);
      });
    } else {
      setMedicRequest([...medicRequest, { file: e.target.files[0] }]);
    }
    setIsOpen(false);
  };

  const handleWalletFront = (e: any) => {
    setIsOpen(true);
    let type = e.target.files[0].type.split("/");

    if (type[0] === "image") {
      resizeImage(e.target.files[0], (res: any) => {
        setWalletFront([{ file: res }]);
      });
    } else {
      setWalletFront([{ file: e.target.files[0] }]);
    }
    setIsOpen(false);
  };

  // const handleWalletBack = (e: any) => {
  //   setIsOpen(true)
  //   let type = e.target.files[0].type.split('/')

  //   if(type[0] === 'image'){
  //     resizeImage(e.target.files[0],(res: any) => {
  //       setWalletBack([{ file: res }]);
  //     })
  //   }else{
  //   setWalletBack([{ file: e.target.files[0] }])
  //   }
  //   setIsOpen(false)
  // };

  const handleNext = () => {
    let data = {
      medicRequest: medicRequest,
      walletFront: walletFront,
      walletBack: walletBack,
      vaccinationCard: vaccinationCard,
    };
    dispatch(saveDocumentsImage(data));
    if (mixPanelOn === "true") {
      mixpanel.identify(mixToken);
      mixpanel.track("Envio de documentos", {
        fluxo: `${partnerName ?? "Isa lab"}`,
      });
      mixpanel.people.set({ "Envio de documentos": "Envio de documentos" });
      let dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "envio_de_documentos",
        ecommerce: {
          fluxo: `${partnerName ?? "Isa lab"}`,
        },
      });
      if (walletNumber !== "") {
        mixpanel.track("Sami", {
          fluxo: `${partnerName ?? "Isa lab"}`,
        });
        mixpanel.people.set({ "Nro carteirinha": walletNumber });
        let dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "envio_de_documentos",
          ecommerce: {
            fluxo: `${partnerName ?? "Isa lab"}`,
            nro_carteirinha: walletNumber,
          },
        });
      }
    }
    if (setServiceAttributes?.medicalRequestNeeded) {
      history.push("/carrinho");
    } else {
      history.push("/confirmacao");
    }
  };

  const handleNextWithouWallet = () => {
    localStorage.setItem("walletNumber", "");
    if (mixPanelOn === "true") {
      mixpanel.identify(mixToken);
      mixpanel.track("Envio de documentos", {
        fluxo: `${partnerName ?? "Isa lab"}`,
      });
      mixpanel.people.set({ "Envio de documentos": "Sem documentos" });
      let dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "envio_de_documentos",
        ecommerce: {
          fluxo: `${partnerName ?? "Isa lab"}`,
          sem_documentos: "Sim",
        },
      });
    }
    if (setServiceAttributes?.medicalRequestNeeded) {
      history.push("/carrinho");
    } else {
      history.push("/confirmacao");
    }
  };

  const handleValidate = () => {
    if (walletNumber === "") {
      setWalletNumberValidate({
        background: "#FEF5F7",
        borderColor: "#E93B5A",
        border: "#E93B5A",
        color: "#E93B5A",
      });
      setWalletNumber("Informe um número válido");
    } else if (walletNumber.length > 0) {
      setWalletNumberValidate({
        background: "#F2FCF5",
        borderColor: "#00C643",
        border: "#00C643",
        color: "",
      });
    }
  };

  const handleRemoveVaccinationCard = (archive: any) => {
    setVaccinationCard(vaccinationCard.filter((res: any) => res !== archive));
  };

  const handleRemoveMedicRequest = (archive: any) => {
    setMedicRequest(medicRequest.filter((res: any) => res !== archive));
  };

  const Input = styled("input")({
    display: "none",
  });

  const saveWalletNumber = (wallet: string) => {
    setWalletNumber(wallet);
    localStorage.setItem("walletNumber", wallet);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IsaLoader open={isOpen} />
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/dados-do-paciente")}
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName={setServiceAttributes.pageName ?? ""}
      />
      <Grid
        style={{ height: "100%" }}
        container
        direction="column"
        justifyContent="start"
        alignItems="center"
      >
        <IsaContainer>
          <IsaBreadcrumbs>
            <Balls
              marginRight="43px"
              isHaveHeader={true}
              header=""
              width="10px"
            />
            <Balls
              marginRight="43px"
              isHaveHeader={true}
              header=""
              width="10px"
            />
            <Balls
              marginRight="-10px"
              isHaveHeader={true}
              header=""
              width="10px"
            />
            <Balls
              marginRight="-10px"
              isHaveHeader={true}
              header="Envio de documentos"
              width="20px"
            />
            <Balls
              marginRight="0px"
              isHaveHeader={false}
              header=""
              width="10px"
            />
          </IsaBreadcrumbs>
          <div style={{ marginTop: 40 }} />
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              container
              maxWidth={720}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <IsaPText color="#666666" textAlign="start">
                {setServiceAttributes?.medicalRequestNeeded ? (
                  <>
                    Use os campos abaixo para enviar o <b>pedido médico.</b>
                    <br />
                    Se o pedido médico tiver <b>mais de uma página</b>, envie
                    todas as páginas com os exames que gostaria de agendar e
                    certifique-se de que <b>as imagens estejam legíveis.</b>
                  </>
                ) : (
                  <>
                    Use os campos abaixo para enviar o <b>pedido médico</b> e a{" "}
                    <b>carteirinha do convênio. </b>
                    Se o pedido médico tiver <b>mais de uma página</b>, envie
                    todas as páginas com os exames que gostaria de agendar e
                    certifique-se de que <b>as imagens estejam legíveis.</b>
                  </>
                )}
              </IsaPText>
              <SupportedFilesContainer>
                <IsaPText color="#666666" textAlign="start">
                  Arquivos suportados
                </IsaPText>
                <PdfIco />
                <PngIco />
                <JpgIco />
              </SupportedFilesContainer>

              <div style={{ marginBottom: 24 }} />

              <h5 style={{ textAlign: "start", width: "100%" }}>
                {partnerInfo?.document === "36567721000125"
                  ? "Documento com foto"
                  : "Pedido médico"}
              </h5>
              <div style={{ marginBottom: 8 }} />
              <label htmlFor="medicRequest" style={{ width: "100%" }}>
                {medicRequest.length === 0 ? (
                  <Input
                    accept="image/png, image/jpeg, image/jpg,application/pdf"
                    id="medicRequest"
                    type="file"
                    onChange={(e: any) => handleMedicRequest(e)}
                  />
                ) : (
                  ""
                )}
                <DragNDropContainer
                  background={
                    medicRequest.length > 0
                      ? "rgba(0, 198, 67, 0.05);"
                      : "#ffffff"
                  }
                  borderColor={medicRequest.length > 0 ? "#00C643" : "#DDDDDD"}
                >
                  <p>
                    {medicRequest.length > 0
                      ? medicRequest[0]?.file.name
                      : "Selecionar arquivo"}
                  </p>
                  {medicRequest.length > 0 ? (
                    <img
                      alt="ico"
                      src={IcoDelete}
                      onClick={() => handleRemoveMedicRequest(medicRequest[0])}
                    />
                  ) : (
                    <img
                      alt="ico"
                      src={IcoUpload}
                      // onClick={onImageUpload}
                    />
                  )}
                </DragNDropContainer>
              </label>
              <div style={{ marginBottom: 24 }} />

              {medicRequest.map((archive: any, index: number) => {
                if (index >= 1)
                  return (
                    <label
                      htmlFor="medicRequest"
                      style={{ width: "100%", marginBottom: 24 }}
                    >
                      <Input
                        accept="image/png, image/jpeg, image/jpg,application/pdf"
                        id="medicRequest"
                        type="file"
                        onChange={(e: any) => handleMedicRequest(e)}
                      />
                      <DragNDropContainer
                        background="rgba(0, 198, 67, 0.05);"
                        borderColor="#00C643"
                      >
                        <p>{archive?.file.name}</p>
                        <img
                          alt="ico"
                          src={IcoDelete}
                          onClick={() => handleRemoveMedicRequest(archive)}
                        />
                      </DragNDropContainer>
                    </label>
                  );
              })}

              {medicRequest.length > 0 ? (
                <label htmlFor="medicRequest" style={{ width: "100%" }}>
                  <Input
                    accept="image/png, image/jpeg, image/jpg,application/pdf"
                    id="medicRequest"
                    type="file"
                    onChange={(e: any) => handleMedicRequest(e)}
                  />
                  <DragNDropContainer
                    background="#ffffff"
                    borderColor="#DDDDDD"
                  >
                    <p>Adicionar mais arquivos do pedido médico</p>
                    <AddCircleOutlineOutlinedIcon
                      style={{ color: "#666666", marginRight: "-3px" }}
                    />
                  </DragNDropContainer>
                </label>
              ) : (
                ""
              )}
              <div style={{ marginBottom: 24 }} />

              {/* <AdditionalMedicRequestButton >
                <p>Adicionar mais arquivos do pedido médico</p>
                <AddCircleOutlineOutlinedIcon style={{color:'#666666'}}/>
              </AdditionalMedicRequestButton> */}

              {!setServiceAttributes?.medicalRequestNeeded ? (
                <>
                  <h5 style={{ textAlign: "start", width: "100%" }}>
                    {partnerInfo?.document === "36567721000125"
                      ? "Pedido médico"
                      : "Frente da carteirinha "}
                  </h5>
                  <div style={{ marginBottom: 11 }} />

                  <IsaPText color="#666666" textAlign="start">
                    {partnerInfo?.document === "36567721000125"
                      ? ""
                      : "Caso sua carteirinha seja digital, basta enviar somente um print da mesma "}
                  </IsaPText>
                  <div style={{ marginBottom: 8 }} />
                  <label htmlFor="walletFront" style={{ width: "100%" }}>
                    {walletFront.length === 0 ? (
                      <Input
                        accept="image/*,application/pdf"
                        id="walletFront"
                        type="file"
                        onChange={(e: any) => handleWalletFront(e)}
                      />
                    ) : (
                      ""
                    )}
                    <DragNDropContainer
                      background={
                        walletFront.length > 0
                          ? "rgba(0, 198, 67, 0.05);"
                          : "#ffffff"
                      }
                      borderColor={
                        walletFront.length > 0 ? "#00C643" : "#DDDDDD"
                      }
                    >
                      <p>
                        {walletFront.length > 0
                          ? walletFront[0]?.file.name
                          : "Selecionar arquivo"}
                      </p>
                      {walletFront.length > 0 ? (
                        <img
                          alt="ico"
                          src={IcoDelete}
                          onClick={() => setWalletFront([])}
                        />
                      ) : (
                        <img alt="ico" src={IcoUpload} />
                      )}
                    </DragNDropContainer>
                  </label>
                  <div style={{ marginTop: 24 }} />
                </>
              ) : (
                <></>
              )}
              <div style={{ width: "100%" }}>
                <h5>Número da carteirinha</h5>
                <div style={{ marginTop: 8 }} />
                <IsaPText textAlign="start" fontSize="1rem" color="#666666">
                  Insira abaixo o número da carteirinha
                </IsaPText>
                <IsaInput
                  value={walletNumber}
                  onChange={(e: any) => saveWalletNumber(e.target.value)}
                  background={walletNumberValidate.background}
                  borderColor={walletNumberValidate.borderColor}
                  border={walletNumberValidate.border}
                  color={walletNumberValidate.color}
                  onBlur={() => handleValidate()}
                  onFocus={() => setWalletNumber("")}
                />
              </div>

              {/* Carteirinha de Vacinação */}

              {subRoute === "particular" &&
              setServiceAttributes.alias === "vaccine" ? (
                <>
                  <div style={{ marginBottom: 24 }} />
                  <h5 style={{ textAlign: "start", width: "100%" }}>
                    Foto da carteirinha de vacinação
                  </h5>
                  <div style={{ marginBottom: 8 }} />
                  <label htmlFor="vaccinationCard" style={{ width: "100%" }}>
                    {vaccinationCard.length === 0 ? (
                      <Input
                        accept="image/png, image/jpeg, image/jpg,application/pdf"
                        id="vaccinationCard"
                        type="file"
                        onChange={(e: any) => handleVaccinationCard(e)}
                      />
                    ) : (
                      ""
                    )}
                    <DragNDropContainer
                      background={
                        vaccinationCard.length > 0
                          ? "rgba(0, 198, 67, 0.05);"
                          : "#ffffff"
                      }
                      borderColor={
                        vaccinationCard.length > 0 ? "#00C643" : "#DDDDDD"
                      }
                    >
                      <p>
                        {vaccinationCard.length > 0
                          ? vaccinationCard[0]?.file.name
                          : "Selecionar arquivo"}
                      </p>
                      {vaccinationCard.length > 0 ? (
                        <img
                          alt="ico"
                          src={IcoDelete}
                          onClick={() =>
                            handleRemoveVaccinationCard(vaccinationCard[0])
                          }
                        />
                      ) : (
                        <img
                          alt="ico"
                          src={IcoUpload}
                          // onClick={onImageUpload}
                        />
                      )}
                    </DragNDropContainer>
                  </label>
                  <div style={{ marginBottom: 24 }} />
                </>
              ) : (
                <></>
              )}

              <div style={{ marginTop: 24 }} />
              <IsaButton
                disabled={!medicalRequestEmpty()}
                onClick={() => handleNextWithouWallet()}
              >
                Avançar sem pedido médico e carteirinha
              </IsaButton>
              <div style={{ marginTop: 32 }} />
              <IsaButton
                disabled={medicalRequestEmpty()}
                onClick={() => handleNext()}
              >
                Avançar
              </IsaButton>
              <div style={{ marginTop: 38 }} />
            </Grid>
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
