import styled from 'styled-components';

export const ContainerCepButton = styled.div`
  display:flex;
  flex-direction:row;
  width:100%;
  align-items: flex-end;

  div{
    width:100%;
  }

  button{
    width: 74px;
    height: 50px;
    background: #FAFAFA;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 16px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    color: #666666;
  }
`;

export const OptionDiv = styled.button`
  padding-top:8px;
  padding-bottom:8px;
  transition: 0.3s;
  border:0px;
  width:100%;
  background:transparent;
  cursor:pointer;  

  :hover {
    background: #F1F3F4;
  }
`;