/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Box, Grid, IconButton, Typography } from "@material-ui/core";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { isExpValid } from "../account/redux/accountActions";
import { postProspect, saveAddressData } from "./redux/addressActions";

//Local Imports
import {
  Balls,
  IsaBreadcrumbs,
} from "../../shared/components/IsaBreadcrumbs/isaBreadcrumbs";
import { IsaButton } from "../../shared/components/IsaButton/IsaButton";
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { IsaDialog } from "../../shared/components/IsaDialog/isaDialog";
import { IsaH4Text } from "../../shared/components/IsaH4Text/IsaH4Text";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";
import { IsaInput } from "../../shared/components/IsaInput/isaInput";
import IsaLoader from "../../shared/components/IsaLoader/isaLoader";
import { IsaPText } from "../../shared/components/IsaPText/IsaPText";
import { CepMask } from "../../shared/validators/cepMask";
import { validateEmail } from "../../shared/validators/emailValidator";
import { phoneMask } from "../../shared/validators/phoneMask";

//Logo
import IcoBack from "../../shared/assets/ico_back.svg";

//Css
import { debounce } from "lodash";
import mixpanel from "mixpanel-browser";
import LocationService from "../../core/services/Location";
import { apiIsaV2, authToken, mixPanelOn } from "../../core/services/api";
import { FormAutoCompleteMaps } from "../../shared/components/FormAutoCompleteMaps/FormAutoCompleteMaps";
import { ILocationApi } from "../../shared/components/FormAutoCompleteMaps/types";
import { IsaSelector } from "../../shared/components/IsaSelector/IsaSelector";
import { OptionDiv } from "../scheduling/schedulingStyle";
import "./addressCepStyle.css";

declare const window: any;

export function AddressCep() {
  const history = useHistory();
  const dispatch = useDispatch();

  const setServiceAttributesStorage = localStorage.getItem(
    "setServiceAttributes"
  );
  const setServiceAttributes = setServiceAttributesStorage
    ? JSON.parse(setServiceAttributesStorage ?? "")
    : "";

  const subRoute = localStorage.getItem("subRoute") ?? "";
  const partnerInfo = JSON.parse(localStorage.getItem("partnerInfo") ?? "{}");
  const operationInfo = JSON.parse(
    localStorage.getItem("operationInfo") ??
      JSON.stringify({ id: process.env.REACT_APP_STANDARD_SQUARE })
  );

  const [isOpen, setIsOpen] = useState(false);
  const [cep, setCep] = useState("");
  const [addressNumber, setAddressNumber] = useState("");
  const [street, setStreet] = useState("");
  const [addressComplement, setAddressComplement] = useState("");
  const [state, setState] = useState("");

  const listStates = [
    { label: "AC", value: "AC" },
    { label: "AL", value: "AL" },
    { label: "AP", value: "AP" },
    { label: "AM", value: "AM" },
    { label: "BA", value: "BA" },
    { label: "CE", value: "CE" },
    { label: "DF", value: "DF" },
    { label: "ES", value: "ES" },
    { label: "GO", value: "GO" },
    { label: "MA", value: "MA" },
    { label: "MT", value: "MT" },
    { label: "MS", value: "MS" },
    { label: "MG", value: "MG" },
    { label: "PA", value: "PA" },
    { label: "PB", value: "PB" },
    { label: "PR", value: "PR" },
    { label: "PE", value: "PE" },
    { label: "PI", value: "PI" },
    { label: "RJ", value: "RJ" },
    { label: "RN", value: "RN" },
    { label: "RS", value: "RS" },
    { label: "RO", value: "RO" },
    { label: "RR", value: "RR" },
    { label: "SC", value: "SC" },
    { label: "SP", value: "SP" },
    { label: "SE", value: "SE" },
    { label: "TO", value: "TO" },
  ];

  const [openSelector, setOpenSelector] = useState(false);

  const handleSelect = (state: string) => {
    setState(state);

    setLocationObjFormatted({
      ...locationObjFormatted,
      uf: state,
    });

    setOpenSelector(false);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");

  const [dontAnswer, setDontAnswer] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [textDialog, setTextDialog] = useState<any>({});

  const [proceed, setProceed] = useState(false);
  const [locationObjFormatted, setLocationObjFormatted] = useState<any>(null);
  const [searchBoxAddress, setSearchBoxAddress] = useState<string | any>("");

  const [optionsAddressPredictions, setOptionsAddressPredictions] =
    useState<ILocationApi | null>(null);
  const [loadingSearchBox, setLoadingSearchBox] = useState<boolean>(false);
  const mixToken: string = localStorage.getItem("mixPanelToken") ?? "";
  const partnerName = localStorage.getItem("partnerName");
  const getAddressData = JSON.parse(
    localStorage.getItem("getAddressData") ?? ""
  );

  const [isCepInvalid] = useState(false);

  //Validators
  const [isEmailValid, setIsEmailValid] = useState(true);
  const isTokenValid = useSelector(
    (state: any) => state.AccountReducer?.isTokenValid
  );

  useEffect(() => {
    dispatch(isExpValid());
  }, []);

  useEffect(() => {
    if (isTokenValid) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    setLoadingSearchBox(true);
    setOptionsAddressPredictions(null);
    if (getAddressData?.cep) setSearchBoxAddress(getAddressData?.cep);
  }, []);

  const [openToggle, setOpenToggle] = useState(false);
  const [feedBack, setFeedBack] = useState("");
  const [feedBackError, setFeedBackError] = useState(false);

  const getOptionsDelayed = useCallback(
    debounce(async (formatedCep, callback) => {
      setLoadingSearchBox(true);
      if (!formatedCep || !formatedCep.match(/^\d{5}-\d{3}$/)) return;

      setOptionsAddressPredictions(null);

      try {
        const location = await searchCepLocation(formatedCep);
        callback(location);
        if (getAddressData?.cep) handleSelectAddressPrediction(location);

      } catch (error) {
        setOpenToggle(true);
        setFeedBackError(true);
        setFeedBack(
          `Ocorreu um erro na busca do cep. Tente novamente. \n${error}`
        );
        setFeedBackError(true);

        callback(null);
      }
    }, 500),
    []
  );

  const searchCepLocation = async (formatedCep: string) => {
    try {
      const resLocation = await new LocationService().searchLocationByCep({
        cep: formatedCep,
      });

      return resLocation;
    } catch (error) {
      setOpenToggle(true);
      setFeedBackError(true);
      setFeedBack(`Ocorreu um erro ao buscar o CEP. ${error}`);

      throw error;
    }
  };

  // * sempre que há digitação/mudança de cep
  useEffect(() => {
    if (searchBoxAddress) {
      setSearchBoxAddress(CepMask(searchBoxAddress));

      getOptionsDelayed(searchBoxAddress, (response: any) => {
        setOptionsAddressPredictions(response || null);
        setProceed(true);

        setSearchBoxAddress("");
        setLoadingSearchBox(false);

        if (!response) return setProceed(false);
        setOpenToggle(false);
      });
    }
  }, [searchBoxAddress, getOptionsDelayed]);

  const handleSelectAddressPrediction = (prediction: ILocationApi | null) => {
    if (!prediction) return;

    setOptionsAddressPredictions(null); // * limpa options
    formatAddressFields(prediction);
  };

  // * Seta informações de endereço obtidas
  const formatAddressFields = (selectedLocation: ILocationApi) => {
    if (!selectedLocation) return;

    setProceed(true);

    setAddressNumber("");
    setAddressComplement("");
    setStreet(selectedLocation?.street ?? "");
    setState(selectedLocation?.state ?? "");

    setCep(selectedLocation?.postalCode ?? "");

    // * formatado para telas
    setLocationObjFormatted({
      bairro: selectedLocation?.neighborhood,
      cep: cep,
      localidade: selectedLocation?.city ?? selectedLocation?.neighborhood,
      logradouro: selectedLocation?.street,
      uf: selectedLocation?.state,
    });
  };

  const handleSetCep = (e: any) => {
    if (e.length < 9) setCep(e);

    setLocationObjFormatted({
      ...locationObjFormatted,
      cep: cep,
    });
  };

  const handleSetTelephone = (e: any) => {
    if (e.length < 16) {
      setTelephone(e.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1"));
    }
  };

  const setDisabled = () => {
    if (
      addressNumber !== "" &&
      locationObjFormatted?.bairro &&
      locationObjFormatted?.localidade &&
      street !== "" &&
      state !== "" &&
      cep !== ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const setWaitListButton = () => {
    if (
      name !== "" &&
      validateEmail(email) &&
      telephone.length >= 11 &&
      cep.length <= 9
    ) {
      return false;
    } else {
      return true;
    }
  };

  const setProspect = () => {
    setIsOpen(true);
    if (validateEmail(email)) {
      const _ddd = telephone.replace(/[^0-9]/g, "").substring(0, 2);
      const _phone = telephone.replace(/[^0-9]/g, "").substring(2, 99);
      const data = {
        fullName: name,
        ddd: _ddd,
        phone: _phone,
        zipCode: cep.replaceAll("-", ""),
        email: email,
      };
      dispatch(
        postProspect(data, (data: any) => {
          setIsOpen(false);
          setIsDialogOpen(true);
          if (data === "Request failed with status code 400") {
            setIsOpen(false);
            setTextDialog({
              header: "Algo deu errado com o pagamento",
              text: "Houve um problema com os dados enviado. Favor preencher os dados novamente.",
              buttonText: "Voltar",
            });
          } else {
            mixpanel.identify(mixToken);
            mixpanel.track("CEP sem cobertura");
            mixpanel.people.set({
              CEP: cep.replaceAll("-", ""),
              fluxo: `${partnerName ?? "Isa lab"}`,
              Tipo: subRoute,
              Estado: locationObjFormatted?.uf,
              Cidade: locationObjFormatted?.localidade,
            });

            let dataLayer = window?.dataLayer || [];

            dataLayer.push({
              event: "cep_sem_cobertura",
              ecommerce: {
                cep: cep.replaceAll("-", ""),
                fluxo: `${partnerName ?? "Isa lab"}`,
                tipo: subRoute,
              },
            });

            setTextDialog({
              header: "Sucesso",
              text: "Cadastro realizado com sucesso. Assim que sua área for coberta, entraremos em contato.",
              buttonText: "Concluir",
            });
          }
        })
      );
    } else {
      setIsOpen(false);
      setIsEmailValid(false);
    }
  };

  const handleClose = () => {
    if (textDialog.header === "Sucesso") {
      window.location.href = "https://isalab.com.br/";
    } else {
      setIsDialogOpen(false);
    }
  };

  const handleValidateEmail = (email: string) => {
    setEmail(email);
    setIsEmailValid(true);
  };

  const handleStart = () => {
    if (mixPanelOn === "true") {
      mixpanel.identify(mixToken);
      mixpanel.track("CEP");
      mixpanel.people.set({
        CEP: cep.replaceAll("-", ""),
        fluxo: `${partnerName ?? "Isa lab"}`,
      });
      let dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "cep",
        ecommerce: {
          cep: cep.replaceAll("-", ""),
        },
      });
    }

    locationObjFormatted.cep = cep;
    locationObjFormatted.logradouro = street;

    const data = {
      address: locationObjFormatted,
      complement: addressComplement !== "" ? addressComplement : null,
      number: addressNumber,
    };

    dispatch(saveAddressData(data));
    return history.push("/agendamento");
  };

  const handleSearchCep = async () => {
    setIsOpen(true);

    try {
      const res = await apiIsaV2.get(`/operations/`, {
        params: {
          "filter[cep]": cep.replaceAll("-", ""),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      });

      const id = res.data?.data[0].id;

      if (
        operationInfo?.origin === "partner" &&
        !partnerInfo.partnerSquares.some(
          (partnerSquare: any) => partnerSquare.squareId === id
        )
      ) {
        throw new Error(`CEP: ${cep} sem cobertura na praça ID: ${id}`);
      }

      if (operationInfo?.origin === "partner") {
        localStorage.setItem(
          "operationInfo",
          JSON.stringify({ id, origin: "partner" })
        );
      } else {
        localStorage.setItem(
          "operationInfo",
          JSON.stringify({ id, origin: "b2c" })
        );
      }

      setIsOpen(false);
      setProceed(true);
      setDontAnswer(false);
      handleStart();
    } catch (err: any) {
      console.log(err);
      setDontAnswer(true);
      setIsOpen(false);
      setProceed(false);
    } finally {
      setIsOpen(false);
    }
  };

  const handleBack = () => {
    let _url = window.location.href.split("/");
    const url = _url[3].replaceAll("#", "");
    if (url === "paguemenos") {
      history.push("/");
    } else {
      history.push("/categorias");
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IsaDialog
        textAlign="start"
        open={isDialogOpen}
        header={textDialog.header}
        buttonText={textDialog.buttonText}
        closeDialog={() => handleClose()}
        body={
          <>
            <p>{textDialog.text}</p>
            <div style={{ marginTop: 24 }} />
            {textDialog.input && (
              <IsaInput
                inputTitle="Número da residência"
                value={addressNumber}
                onChange={(e: any) => setAddressNumber(e.target.value)}
              />
            )}
          </>
        }
      />

      {openToggle && (
        <Alert
          severity={feedBackError ? "error" : "success"}
          style={{
            maxWidth: "600px",
            position: "absolute",
            top: 40,
            left: 0,
            right: 0,
            margin: "0 auto",
          }}
        >
          <Box style={{ width: "100%", display: "flex" }}>
            {feedBack && <Typography>{feedBack}</Typography>}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() => setOpenToggle(false)}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </Box>
        </Alert>
      )}

      <IsaLoader open={isOpen} />

      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => handleBack()}
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName="Busque seu Endereço"
      />
      <Grid
        style={{ height: "100%" }}
        container
        direction="column"
        justifyContent="start"
        alignItems="center"
      >
        <IsaContainer>
          <IsaBreadcrumbs>
            <Balls
              marginRight="20px"
              isHaveHeader={true}
              header="Endereço"
              width="20px"
            />
            <Balls
              marginRight="43px"
              isHaveHeader={false}
              header=""
              width="10px"
            />
            <Balls
              marginRight="43px"
              isHaveHeader={false}
              header="Dados do paciente"
              width="10px"
            />
            <Balls
              marginRight="43px"
              isHaveHeader={false}
              header=""
              width="10px"
            />
            <Balls
              marginRight="43px"
              isHaveHeader={false}
              header=""
              width="10px"
            />
          </IsaBreadcrumbs>
          <div style={{ marginTop: 40 }} />
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              maxWidth={720}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <div style={{ width: "100%", marginBottom: 8 }}>
                <h5>Busque pelo CEP</h5>
              </div>
              <div style={{ width: "100%" }}>
                <FormAutoCompleteMaps
                  loading={!!searchBoxAddress && loadingSearchBox}
                  options={optionsAddressPredictions}
                  onChange={(value: any) => setSearchBoxAddress(value)}
                  onSelect={(value) => handleSelectAddressPrediction(value)}
                />
              </div>
              <div style={{ marginTop: 24 }} />
              {proceed ? (
                <>
                  <Grid
                    container
                    direction="column"
                    sx={{ marginBottom: "24px" }}
                  >
                    <IsaInput
                      inputTitle="CEP"
                      value={CepMask(cep)}
                      onChange={(e: any) => handleSetCep(e.target.value)}
                      background={
                        isCepInvalid ? "rgba(233, 59, 90, 0.05)" : "#ffffff"
                      }
                      borderColor={isCepInvalid ? "#E93B5A" : "#DDDDDD"}
                      border={isCepInvalid ? "#E93B5A" : "#DDDDDD"}
                      color={isCepInvalid ? "#E93B5A" : ""}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    gap="24px"
                    sx={{ marginBottom: "24px" }}
                  >
                    <Grid container direction="column">
                      <IsaInput
                        inputTitle="Rua"
                        value={street}
                        onChange={(e: any) => setStreet(e.target.value)}
                      />
                    </Grid>
                    <Grid container direction="column" sx={{ width: "30%" }}>
                      <IsaInput
                        inputTitle="Número"
                        value={addressNumber}
                        onChange={(e: any) => setAddressNumber(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="column"
                    sx={{ marginBottom: "24px" }}
                  >
                    <IsaInput
                      inputTitle="Complemento"
                      value={addressComplement}
                      onChange={(e: any) =>
                        setAddressComplement(e.target.value)
                      }
                    />
                  </Grid>

                  <Grid
                    container
                    direction="column"
                    gap="24px"
                    sx={{ width: "100%", marginBottom: "24px" }}
                  >
                    <Grid container direction="column">
                      <IsaInput
                        inputTitle="Bairro"
                        value={locationObjFormatted?.bairro}
                        onChange={(e: any) =>
                          setLocationObjFormatted({
                            ...locationObjFormatted,
                            bairro: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="column"
                    gap="24px"
                    sx={{ width: "100%" }}
                  >
                    <Grid container direction="column">
                      <IsaInput
                        inputTitle="Cidade"
                        value={locationObjFormatted?.localidade}
                        onChange={(e: any) =>
                          setLocationObjFormatted({
                            ...locationObjFormatted,
                            localidade: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid container direction="column">
                      <IsaSelector
                        inputTitle="Estado"
                        value={state}
                        onClick={() => setOpenSelector(!openSelector)}
                        openSelector={openSelector}
                      >
                        {openSelector === true ? (
                          <>
                            {listStates.map((state: any, index: number) => {
                              return (
                                <OptionDiv
                                  key={index}
                                  onClick={() => handleSelect(state?.value)}
                                >
                                  {state?.label}
                                </OptionDiv>
                              );
                            })}
                          </>
                        ) : (
                          ""
                        )}
                      </IsaSelector>
                    </Grid>
                  </Grid>

                  <div style={{ marginTop: 45 }} />
                  <IsaButton
                    onClick={() => handleSearchCep()}
                    disabled={setDisabled()}
                  >
                    Avançar
                  </IsaButton>
                  <div style={{ marginTop: 38 }} />
                </>
              ) : (
                <div />
              )}
              {dontAnswer ? (
                <>
                  <IsaH4Text fontWeight={600} color="#ED1E79">
                    Ainda não
                    <br /> atendemos sua região
                  </IsaH4Text>
                  <div style={{ marginTop: 12 }} />
                  <IsaPText textAlign="start" ƒ color="#666666">
                    Deixe seu contato abaixo e avisaremos <br />
                    assim que chegarmos na sua região.
                  </IsaPText>

                  <div style={{ marginTop: 24 }} />
                  <IsaInput
                    inputTitle="Nome *"
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                  />

                  <div style={{ marginTop: 24 }} />
                  <IsaInput
                    background={isEmailValid ? "" : "rgba(233, 59, 90, 0.05)"}
                    borderColor={isEmailValid ? "" : "#E93B5A"}
                    inputTitle="Email *"
                    value={email}
                    onChange={(e: any) => handleValidateEmail(e.target.value)}
                  />

                  <div style={{ marginTop: 24 }} />
                  <IsaInput
                    inputTitle="Telefone *"
                    value={phoneMask(telephone)}
                    onChange={(e: any) => handleSetTelephone(e.target.value)}
                  />

                  <div style={{ marginTop: 83 }} />
                  <IsaButton
                    disabled={setWaitListButton()}
                    onClick={() => setProspect()}
                  >
                    AVISE-ME
                  </IsaButton>
                  <div style={{ marginTop: 21 }} />
                </>
              ) : (
                <div></div>
              )}
            </Grid>
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
