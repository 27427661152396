import { 
    SET_VALIDATE_COUPON
} from './cartConstants';

import { apiIsa,authToken } from '../../../core/services/api'

const setValidateCouponEnd = (dispatch:any, data:any) => {
  dispatch({type: SET_VALIDATE_COUPON, data})
};

export const setValidateCoupon = (voucher:string,callback: any) => {
	return (dispatch:any) => {
    apiIsa.post('/voucher/check',{
        "voucher": voucher
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${authToken}`,
      }
    })
    .then((responseJson) => {
      setValidateCouponEnd(dispatch,responseJson?.data)
      callback(responseJson)
    })
    .catch((err) => {
      callback(err.response);
    });
	};
};