import { Box as BoxMaterial } from "@material-ui/core";
import styled from "styled-components";

export const Box = styled(BoxMaterial)`
  background: #ebf7ff;
  border: 1px solid #004474;
  border-radius: 12px;
  padding: 16px;
  flex-direction: row;
  display: flex;
  gap: 16px;
  width: 100%;
`;
