import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function IsaLoader(props:any) {

  return (
    <div>
      <Backdrop open={props.open} style={{zIndex:999}}>
        <CircularProgress style={{color:"#00D2D4"}} />
      </Backdrop>
    </div>
  );
}