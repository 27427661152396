
//Css
import {
  Text
} from './IsaH3TextStyle'

export function IsaH3Text(props: any) {
  return (
    <Text color={props.color} style={{textAlign:props.textAlign}}>
      {props.children}
    </Text>
  );
}