import {
    SET_VALIDATE_COUPON
  } from './cartConstants';
  
  const INITIAL_STATE = {
    getDiscount: {}
  };
  
  // eslint-disable-next-line import/no-anonymous-default-export
  export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
      case SET_VALIDATE_COUPON:
        var _getDiscount
        let discount = action.data.isValid?JSON.stringify(action.data): '""'
        localStorage.setItem('discount',discount)
        _getDiscount = action.data
  
        return {
          ...state,
          getDiscount: _getDiscount
        }
    }
  
    return state;
  };