import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";

import ReactInputVerificationCode from "react-input-verification-code";
import { useDispatch, useSelector } from "react-redux";
import { IsaButton } from "../../../../shared/components/IsaButton/IsaButton";
import { IsaDialog } from "../../../../shared/components/IsaDialog/isaDialog";
import IsaLoader from "../../../../shared/components/IsaLoader/isaLoader";
import { IsaPText } from "../../../../shared/components/IsaPText/IsaPText";
import { ButtonContainer } from "../../accountStyle";

import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { apiIsa } from "../../../../core/services/api";
import { useWindowSize } from "../../../../shared/components/hooks/useWindowSize";
import { phoneMask } from "../../../../shared/validators/phoneMask";
import { LoginResponseProps } from "../../accountsInterface";
import { changePageView } from "../../redux/accountActions";
import { DisplayPage } from "../telAuth";
import "./codeValidate.css";

interface MessageInterface {
  messageBody: string;
  messageButton: string;
  messageHeader: string;
}

enum MessageDialog {
  TRY_AGAIN = "Tentar novamente",
  GO_AHEAD = "Prosseguir",
  CLOSE = "Fechar",
}

export function CodeValidate() {
  const token: string = localStorage.getItem("token") || "";
  const telephoneNumber: string = useSelector(
    (state: any) => state.AccountReducer?.telephoneNumber
  );

  const tokenDecript: LoginResponseProps = jwt_decode(token);
  const loginUserId = tokenDecript?.data?.id || null;

  const dispatch = useDispatch();
  const history = useHistory();
  const size = useWindowSize();

  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [message, setMessage] = useState<MessageInterface>({
    messageBody: "",
    messageButton: "",
    messageHeader: "",
  });

  const [seconds, setSeconds] = useState(60);
  const [started, setStarted] = useState(false);

  const startTimer = () => {
    setStarted(true);
    const savedStartTime = JSON.parse(
      localStorage.getItem("startTime") ?? "null"
    );

    if (savedStartTime) {
      const elapsedTime = Math.floor(Date.now() / 1000) - savedStartTime;
      const remainingTime = seconds - elapsedTime;

      if (remainingTime > 0) {
        setSeconds(remainingTime);
        return;
      }
    }

    setSeconds(60);
    localStorage.setItem(
      "startTime",
      JSON.stringify(Math.floor(Date.now() / 1000))
    );
  };

  useEffect(() => {
    let intervalId: string | number | NodeJS.Timeout | undefined;

    if (started) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds <= 0) {
            clearInterval(intervalId);
            setStarted(false);
            localStorage.removeItem("startTime");
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [started]);

  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  useEffect(() => {
    const savedStartTime = JSON.parse(
      localStorage.getItem("startTime") ?? "null"
    );

    if (savedStartTime) {
      const elapsedTime = Math.floor(Date.now() / 1000) - savedStartTime;
      const remainingTime = seconds - elapsedTime;

      if (remainingTime > 0) {
        setStarted(true);
        setSeconds(remainingTime);
      }
    }
  }, []);

  const disableSendButton = () => {
    return code.length !== 4;
  };

  const handleSendCodeToValidatePhone = async () => {
    setIsLoading(true);
    try {
      await apiIsa.post(
        "/user/validate/check",
        {
          code,
          userId: loginUserId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );
      setIsDialogOpen(true);
      setMessage({
        messageBody: "Código validado com sucesso",
        messageButton: MessageDialog.GO_AHEAD,
        messageHeader: "Sucesso",
      });
    } catch (err: any) {
      setCode("");
      setIsDialogOpen(true);
      setMessage({
        messageBody:
          "Não foi possível validar o seu código. Por favor, reenvie o código.",
        messageButton: MessageDialog.TRY_AGAIN,
        messageHeader: `${err?.response?.data?.message}`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const reSendCode = async () => {
    setCode("");
    setIsLoading(true);
    try {
      await apiIsa.post(
        "user/resend-validate",
        {
          userId: loginUserId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );
      setIsDialogOpen(true);
      setMessage({
        messageBody: "Mensagem reenviada com sucesso",
        messageButton: MessageDialog.CLOSE,
        messageHeader: "Sucesso",
      });

      startTimer();
    } catch (err: any) {
      setIsDialogOpen(true);
      setMessage({
        messageBody: err?.message ?? "Erro ao enviar código",
        messageButton: MessageDialog.TRY_AGAIN,
        messageHeader: "Erro ao enviar código",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const backToLastPage = () => {
    dispatch(changePageView("", DisplayPage.CODE_VALIDATION));
  };

  const closeButton = (message: string) => {
    switch (message) {
      case MessageDialog.GO_AHEAD:
        return (
          history.push("/dados-do-paciente"),
          dispatch(changePageView("", DisplayPage.CODE_VALIDATION))
        );
      case MessageDialog.TRY_AGAIN:
      case MessageDialog.CLOSE:
        return setIsDialogOpen(false);
    }
  };

  return (
    <>
      <IsaLoader open={isLoading} />
      <IsaDialog
        open={isDialogOpen}
        header={message.messageHeader}
        bodyText={message.messageBody}
        buttonText={message.messageButton}
        closeDialog={() => closeButton(message.messageButton)}
      />
      <IsaPText color="#666666" textAlign="start">
        Enviamos o código para o número {size.width < 436 ? <br /> : ""}
        <b>{phoneMask(telephoneNumber)}</b>, por favor, insira o código nos
        campos abaixo.
        {/* <br />O número está errado?{" "}
        <ButtonAnchor onClick={() => backToLastPage()}>
          {" "}
          Clique aqui{" "}
        </ButtonAnchor> */}
      </IsaPText>
      <div style={{ marginBottom: 26 }} />
      <div className="customReactInputVerificationCode">
        <ReactInputVerificationCode
          placeholder=""
          onChange={setCode}
          value={code}
        />
      </div>

      {started && (
        <IsaPText color="#666666" textAlign="center" fontSize="14px">
          Aguarde 1 minuto antes de reenviar o código.{" "}
          <span className="countdown-resend-validade">
            {formattedSeconds} restantes.
          </span>
        </IsaPText>
      )}

      <Button
        variant="text"
        style={{ fontFamily: "Open Sans", marginTop: "10px" }}
        onClick={() => reSendCode()}
        disabled={started}
      >
        Reenviar código
      </Button>

      {/* {started ? (
        <p>
          <span>Aguarde 1 minuto antes de reenviar o código.</span>{" "}
          {formattedSeconds} restantes.
        </p>
      ) : (
        // <button onClick={startTimer}>Iniciar Contador</button>
        <Button
          variant="text"
          style={{ fontFamily: "Open Sans", marginTop: "10px" }}
          onClick={() => reSendCode()}
        >
          Reenviar código
        </Button>
      )} */}

      <ButtonContainer>
        <IsaButton
          disabled={disableSendButton()}
          onClick={() => handleSendCodeToValidatePhone()}
        >
          Validar código
        </IsaButton>
      </ButtonContainer>
    </>
  );
}
