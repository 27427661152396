/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { setProductsToCart } from "../../../shared/redux/shop/shopActions";

//Local Imports
import { IsaButton } from "../../../shared/components/IsaButton/IsaButton";
import { IsaH3Text } from "../../../shared/components/IsaH3Text/IsaH3Text";
import { IsaPText } from "../../../shared/components/IsaPText/IsaPText";

//Logo
import IsaLogo from '../../../shared/assets/LOGO_ISA2022-02.png';
import IcoCalendar from '../../../shared/assets/ico_calendar.svg';
import IcoClock from '../../../shared/assets/ico_clock.svg';

//Css
import {
  DivLogo,
  HourDateContainer
} from '../confirmedAppointmentStyle'

import { formatMoney } from "../../../shared/validators/formatMoney";
import { mixPanelOn } from "../../../core/services/api";
import mixpanel from "mixpanel-browser";

declare const window: any;

export function CreditOrHealthInsuranceConfirmation() {
  const history = useHistory();
  const dispatch = useDispatch();

  const subRoute = localStorage.getItem('subRoute')?? ''
  const partnerInfo = JSON.parse(localStorage.getItem('partnerInfo') || '0')
  const partnerName = localStorage.getItem('partnerName')
  const getDateData = JSON.parse(localStorage.getItem('getDateData') || '{}')
  const listDocumentsImage = useSelector((state:any) => state.SendDocumentsReducer?.listDocumentsImage?? [])
  const [maskDay, setMaskDay] = useState('')
  const dataAppointments = useSelector((state:any) => state.ConfirmationReducer?.dataAppointments?? [])
  const listOfProducts = JSON.parse(localStorage.getItem('listOfProducts') || '[]')
  const priceValue = JSON.parse(localStorage.getItem('priceValue') || '0')
  const loginEmail = localStorage.getItem('loginEmail') || ''
  const mixToken: string  = localStorage.getItem('mixPanelToken')??''

  useEffect(() => {
    if(mixPanelOn === 'true'){
      mixpanel.identify(mixToken);
      mixpanel.track('Resumo',{
        'tipo de finalização': `${subRoute}`
      });
      mixpanel.people.set({ "Resumo": `${subRoute}` });
      let dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: 'confirmacao',
        ecommerce: {
          tipo: `${subRoute}`,
        }
      });
    }
  },[])
  
  useEffect(() => {
    if(getDateData){
      const maskDay = getDateData.date?.split('/')
      if (!maskDay) return;

      setMaskDay(`${maskDay[0]}/${maskDay[1]}`)
    }
  }, []);

  const resetValues = () => {
    localStorage.setItem('route','')
    localStorage.setItem('setServiceAttributes','')
    localStorage.setItem('getAddressData','')
    localStorage.setItem('planInfos','')
    localStorage.setItem('patientData','')
    localStorage.setItem('token','')
    localStorage.setItem('exp', '')
    localStorage.setItem('subRoute','')
    localStorage.setItem('listOfProducts','')
    localStorage.setItem('priceValue','')
    localStorage.setItem('getDateData','')
    localStorage.setItem('partnerInfo','')
    dispatch(setProductsToCart([]))

    history.push('/')
  }

  const goToPagueMenos = () => {
    resetValues()
    window.open('https://www.paguemenos.com.br/')
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <DivLogo>
        {partnerInfo?.logo?
          <img 
          alt='logo01'
          src={partnerInfo.logo}
          style={{marginRight:55, height:'49px'}}
        />
        : ''
        }
        <img 
          alt='logo02' 
          src={IsaLogo} 
          style={{height:'49px'}}
        />
      </DivLogo>
      <div style={{marginTop:32}} />
      <IsaH3Text 
        color='#004474'
        textAlign='center'
      >
        {subRoute === 'particular' ?  'Agendamento\nconfirmado': 'Aguardando a\naprovação do convênio '}
      </IsaH3Text>
      <div style={{marginTop:24}} />
      <Grid 
        container maxWidth={720}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <IsaPText 
          color={'#666666'}
          textAlign='center'
        >
          Nossos coletores estarão no local, horário<br/> 
          e data informados em seu agendamento.
        </IsaPText>

        <div style={{marginTop:16}} />
        {getDateData !== '' ?
          <>
            <HourDateContainer>
            <img alt='ico' src={IcoCalendar} />
            <p>{maskDay}</p>
            </HourDateContainer>
            <div style={{marginTop:16}} />
            <HourDateContainer>
              <img alt='ico' src={IcoClock} />
              <p>{getDateData.hour}</p>
            </HourDateContainer>
          </>
          :
          <div></div>
        }
        {/* <div style={{marginTop:24}} /> */}
        <div style={{position: 'absolute',visibility: 'hidden',left:'-9999px'}}>
        {/* <div style={{ margin: 24, }}> */}
          <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 0' }}>
            <label style={{ fontSize: 18 }}>Agendamento:</label>
            <input style={{ fontSize: 14, outline: 'none', border: 'none', margin: '0 5px' }} name='AgendamentoID' value={`${dataAppointments.id}`}></input>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 0' }}>
            <label style={{ fontSize: 18 }}>Email:</label>
            <input style={{ fontSize: 14, outline: 'none', border: 'none', margin: '0 5px' }} name='Email' value={`${loginEmail}`}></input>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 0' }}>
            <label style={{ fontSize: 18 }}>Produtos:</label>
            <div>
              {
                listOfProducts.map((product: any) => {
                  return <input style={{ fontSize: 14, display: 'block', outline: 'none', border: 'none', margin: '0 5px' }} name='Produtos' value={`${product.name} - R$ ${formatMoney(product.price)}`}></input>
                })
              }
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 0' }}>
            <label style={{ fontSize: 18 }}>Total:</label>
            <input style={{ fontSize: 14, outline: 'none', border: 'none', margin: '0 5px' }} name='Total' value={`R$ ${formatMoney(priceValue)}`}></input>
          </div>
        </div>
        {listDocumentsImage.length === 0 ?
          <>
          <div style={{marginTop:24}} />
          <IsaPText 
            color='#666666'
            textAlign='center'
          >
            Lembre-se <b>enviar seu pedido médico</b> para nossa equipe pelo Whatsapp.
            <br/><br/>
            Caso não envie, entraremos em contato pelo número de celular 
            cadastrado para lembrá-lo.
          </IsaPText>
          </>
          : ''
        }
        <div style={{marginTop:52}} />
        {partnerName === 'PAGUE MENOS' ?
          <>
            <p style={{ 
              fontWeight: 700,
              color: '#666666',
              fontSize: 16,
              marginBottom: 33 ,
              textAlign: 'center'
            }}>
              Precisa comprar o medicamento?<br></br>
              Clique para acessar o site da<br></br>
              PagueMenos!<br></br>
            </p>
            <IsaButton 
              onClick={() => goToPagueMenos()}
            >
              Comprar medicamento
            </IsaButton>
            <div style={{marginTop:16}} />
            <IsaButton 
              borderColor='#666666'
              color='#666666'
              background='transparent'
              onClick={() => resetValues()}
            >
              Voltar para home
            </IsaButton>
          </>
          :
          <IsaButton id='isaClose' onClick={() => resetValues()}>
            {partnerName === 'VIBE SAUDE VIP' || partnerName === 'VIBE SAUDE' ?
              'Voltar para home' : 'Fazer novo agendamento' 
            }
          </IsaButton>
        }
        <div style={{marginTop:38}} />
      </Grid>
    </Grid>
  );
}