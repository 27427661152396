import styled from 'styled-components';

interface ContainerProps {
  justifyContent: string,
  flexDirection?: string
}

interface MarginCButtonProps {
  isMargin?: boolean
}

export const DialogContainer = styled.div`
  display:flex;
  justify-content:flex-start;
  align-content: center;
  align-items: center;
  flex-direction: column;
  height:100%;
  max-width: 720px;
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: ${props => props.justifyContent};
  flex-direction: ${props => props.flexDirection?? 'row'};
  width:100%;

  h3 {
    font-family: 'Titillium Web', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
  }

  p{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media only screen and (min-width: 735px) {
    flex-direction:row-reverse;
  }
`;

export const MarginCButton = styled.div<MarginCButtonProps>`
  width: 344px;
  margin-bottom: 16px;

  @media only screen and (min-width: 735px) {
    margin-right: 16px;
  }
`;
