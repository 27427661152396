import { listOfPatientRelations } from "../schema";
import { FormSelect } from "../../../shared/components/FormSelect/FormSelect";
import { FormProps } from "./types";

export const AboutPatientForm = ({
  register,
  errors,
  defaultValue,
  label,
  name,
}: FormProps | any) => {
  return (
    <FormSelect
      label={label}
      name={name}
      register={register}
      errors={errors}
      options={listOfPatientRelations}
      defaultValue={defaultValue?.value ?? undefined}
    />
  );
};
