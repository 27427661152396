
//Css
import {
  Text
} from './IsaH4TextStyle'

export function IsaH4Text(props: any) {
  return (
    <Text 
      color={props.color} 
      style={{
        textAlign:props.textAlign??'start',
        fontWeight:props.fontWeight
      }}
    >
      {props.children}
    </Text>
  );
}