//Css
import {
  ContainerSelect,
  Select,
  OptionDiv
} from './IsaSelectorStyle'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

export function IsaSelector(props: any) {
  return (
    <>
      <h5 style={{textAlign:'start',width:'100%'}}>
        {props.inputTitle}
      </h5>
      <div style={{marginBottom:8}} />
      <Select
        background={props.isValid ? 'rgba(0, 198, 67, 0.05);': '#ffffff'}
        borderColor={props.isValid ? '#00C643': '#DDDDDD'}
        onClick={props.onClick} 
        disabled={props.disabled}
        placeholder={props.placeholder}
        justifyContent={props.value ? 'space-between' : 'flex-end'}
      >
        {props.value} 
        <KeyboardArrowDownRoundedIcon />
      </Select>
      {props.openSelector === true ?
        <ContainerSelect>
          {props.children}
        </ContainerSelect>
        : ''
      }
    </>
  );
}

export function IsaOption(props: any) {
  return (
    <OptionDiv onClick={props.onClick}>
      {props.children}
    </OptionDiv>
  )
}