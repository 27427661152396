import { SAVE_ADDRESS_DATA } from "./addressConstants";

const INITIAL_STATE = {
  getAddressData: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SAVE_ADDRESS_DATA:
      var _getAddressData;
      _getAddressData = action.data;
      let stringlify = JSON.stringify(action.data);
      localStorage.setItem("getAddressData", stringlify);

      return {
        ...state,
        getAddressData: _getAddressData,
      };
  }

  return state;
};
