/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { styled } from "@mui/material/styles";

import { useHistory } from "react-router";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { saveDocumentsImage } from "./../sendDocuments/redux/sendDocumentsActions";

//Local Imports
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";
import { IsaButton } from "../../shared/components/IsaButton/IsaButton";
import { IsaPText } from "../../shared/components/IsaPText/IsaPText";
import {
  IsaBreadcrumbs,
  Balls,
} from "../../shared/components/IsaBreadcrumbs/isaBreadcrumbs";

//Logo
import IcoBack from "../../shared/assets/ico_back.svg";
import IcoUpload from "../../shared/assets/ico_upload.svg";
import IcoDelete from "../../shared/assets/ico_delete.svg";

//Css
import {
  DragNDropContainer,
  SupportedFilesContainer,
} from "./sendVaccinationCardStyle";
import resizeImage from "../../shared/validators/resizeImage";
import IsaLoader from "../../shared/components/IsaLoader/isaLoader";
import { isExpValid } from "../account/redux/accountActions";
import { JpgIco, PdfIco, PngIco } from "../../shared/assets/isaIcons";

declare const window: any;

export function SendVaccinationCard(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [vaccinationCard, setVaccinationCard] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const isTokenValid = useSelector(
    (state: any) => state.AccountReducer?.isTokenValid
  );

  const subRoute = localStorage.getItem("subRoute") ?? "";
  const setServiceAttributesStorage = localStorage.getItem(
    "setServiceAttributes"
  );
  const setServiceAttributes = setServiceAttributesStorage
    ? JSON.parse(setServiceAttributesStorage ?? "")
    : "";

  useEffect(() => {
    dispatch(isExpValid());
    dispatch(saveDocumentsImage([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isTokenValid) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVaccinationCard = async (e: any) => {
    setIsOpen(true);
    let type = e.target.files[0].type.split("/");
    if (type[0] === "image") {
      resizeImage(e.target.files[0], (res: any) => {
        setVaccinationCard([...vaccinationCard, { file: res }]);
      });
    } else {
      setVaccinationCard([...vaccinationCard, { file: e.target.files[0] }]);
    }
    setIsOpen(false);
  };

  const handleNext = () => {
    let data = {
      vaccinationCard: vaccinationCard
    };
    dispatch(saveDocumentsImage(data));
    history.push("/carrinho");
  }


  const handleNextWithouWallet = () => {
    history.push("/carrinho");
  };

  const vaccinationCardIsEmpty = () => {
    return vaccinationCard?.length === 0
  }

  const handleRemoveVaccinationCard = (archive: any) => {
    setVaccinationCard(vaccinationCard.filter((res: any) => res !== archive));
  };

  const Input = styled("input")({
    display: "none",
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IsaLoader open={isOpen} />
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/dados-do-paciente")}
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName={setServiceAttributes.pageName ?? ""}
      />
      <Grid
        style={{ height: "100%" }}
        container
        direction="column"
        justifyContent="start"
        alignItems="center"
      >
        <IsaContainer>
          <IsaBreadcrumbs>
            <Balls
              marginRight="43px"
              isHaveHeader={true}
              header=""
              width="10px"
            />
            <Balls
              marginRight="43px"
              isHaveHeader={true}
              header=""
              width="10px"
            />
            <Balls
              marginRight="-10px"
              isHaveHeader={true}
              header=""
              width="10px"
            />
            <Balls
              marginRight="-10px"
              isHaveHeader={true}
              header="Envio da Caderneta de Vacinação"
              width="20px"
            />
            <Balls
              marginRight="0px"
              isHaveHeader={false}
              header=""
              width="10px"
            />
          </IsaBreadcrumbs>
          <div style={{ marginTop: 40 }} />
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              container
              maxWidth={720}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <IsaPText color="#666666" textAlign="start">
                <>
                  Use o campo abaixo para enviar sua <b>caderneta de vacinação.</b>
                  <br />
                  Nossa equipe verificará tudo com atenção. Se algo não estiver certo, nós te avisaremos.
                  <br />
                  Por favor, certifique-se de que <b>as imagens estejam legíveis</b>
                </>
              </IsaPText>
              <SupportedFilesContainer>
                <IsaPText color="#666666" textAlign="start">
                  Arquivos suportados
                </IsaPText>
                <PdfIco />
                <PngIco />
                <JpgIco />
              </SupportedFilesContainer>

              <div style={{ marginBottom: 24 }} />

              {/* Caderneta de Vacinação */}

              {(subRoute === 'particular' && setServiceAttributes.alias === 'vaccine') ?
                <>
                  <div style={{ marginBottom: 24 }} />
                  <h5 style={{ textAlign: "start", width: "100%" }}>
                    Foto da caderneta de vacinação
                  </h5>
                  <div style={{ marginBottom: 8 }} />
                  <label htmlFor="vaccinationCard" style={{ width: "100%" }}>
                    {vaccinationCard.length === 0 ? (
                      <Input
                        accept="image/png, image/jpeg, image/jpg,application/pdf"
                        id="vaccinationCard"
                        type="file"
                        onChange={(e: any) => handleVaccinationCard(e)}
                      />
                    ) : (
                      ""
                    )}
                    <DragNDropContainer
                      background={
                        vaccinationCard.length > 0
                          ? "rgba(0, 198, 67, 0.05);"
                          : "#ffffff"
                      }
                      borderColor={vaccinationCard.length > 0 ? "#00C643" : "#DDDDDD"}
                    >
                      <p>
                        {vaccinationCard.length > 0
                          ? vaccinationCard[0]?.file.name
                          : "Selecionar arquivo"}
                      </p>
                      {vaccinationCard.length > 0 ? (
                        <img
                          alt="ico"
                          src={IcoDelete}
                          onClick={() => handleRemoveVaccinationCard(vaccinationCard[0])}
                        />
                      ) : (
                        <img
                          alt="ico"
                          src={IcoUpload}
                        // onClick={onImageUpload}
                        />
                      )}
                    </DragNDropContainer>
                  </label>
                  <div style={{ marginBottom: 24 }} />
                </>
                : <></>
              }

              <div style={{ marginTop: 24 }} />
              <IsaButton
                disabled={!vaccinationCardIsEmpty()}
                onClick={() => handleNextWithouWallet()}
              >
                Avançar sem enviar a caderneta de vacinação
              </IsaButton>
              <div style={{ marginTop: 32 }} />
              <IsaButton
                disabled={vaccinationCardIsEmpty()}
                onClick={() => handleNext()}
              >
                Avançar
              </IsaButton>
              <div style={{ marginTop: 38 }} />
            </Grid>
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
