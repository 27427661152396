import { Box } from "@material-ui/core";
import * as S from "./styles";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { IsaPText } from "../IsaPText/IsaPText";
export function FreeShipping() {
  return (
    <S.Box data-test="freeShipping">
      <MonetizationOnOutlinedIcon fontSize="large" sx={{ color: "#004474" }} />
      <Box>
        <IsaPText color="#004474" textAlign="start" fontWeight="700">
          Serviço de coleta grátis
        </IsaPText>
        <IsaPText color="#666666" textAlign="start" fontSize="14px">
          R$0,00
        </IsaPText>
        <IsaPText
          color="#666666"
          textAlign="start"
          fontSize="12px"
          fontWeight="400"
        >
          Os serviços de coleta e aplicação dos produtos da ISA são gratuitos.
        </IsaPText>
      </Box>
    </S.Box>
  );
}
