import { Box, BoxTypeMap, TextField } from "@material-ui/core";
import styled from "styled-components";

interface IBoxWrapper {
  showOrHide?: boolean;
}

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #c4c4c4;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ContainerCepButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;

  div {
    width: 100%;
  }

  button {
    width: 74px;
    height: 50px;
    background: #fafafa;
    border: 1px solid #ececec;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 16px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    color: #666666;
  }
`;
//
export const Label = styled.label`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #666666;
`;

export const BoxWrapper = styled<IBoxWrapper | any>(Box)`
  display: ${(props) => (props.showOrHide ? "none" : "flex")};
  flex-direction: column;
  justify-content: flex-end;
  gap: 6px;
`;

export const InputTextField = styled(TextField)`
  background: transparent;
  & .MuiOutlinedInput-root {
    background: ${(props) => (props.error ? "#FEF5F6" : "transparent")};
  }
  & .MuiFormHelperText-root {
    margin-left: 0px;
  }
`;
