import {
  SAVE_DATE_DATA,
  GET_SCHEDULE_HOUR,
} from './schedulingConstants';

const INITIAL_STATE = {
  getDateData: [],
  listScheduleHour: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SAVE_DATE_DATA:
      var _getDateData
      _getDateData = action.data
      const data = JSON.stringify(action.data)
      localStorage.setItem('getDateData',data)

      return {
        ...state,
        getDateData: _getDateData
      }
      
    case GET_SCHEDULE_HOUR:
      var _listScheduleHour
      _listScheduleHour = action.data

      return {
        ...state,
        listScheduleHour: _listScheduleHour
      }
  }

  return state;
};