/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { Box, Grid } from "@material-ui/core";
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { useHistory } from "react-router";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  setProductsToCart,
  setTeste,
} from "../../shared/redux/shop/shopActions";

//Logo
import IcoBack from "../../shared/assets/ico_back.svg";
import { formatMoney } from "../../shared/validators/formatMoney";

//Css
import { Button, RemoveProduct } from "./initialCartStyle";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";
import { IsaH4Text } from "../../shared/components/IsaH4Text/IsaH4Text";
import { IsaPText } from "../../shared/components/IsaPText/IsaPText";
import { IsaButton } from "../../shared/components/IsaButton/IsaButton";
import { isExpValid } from "../account/redux/accountActions";
import { mixPanelOn } from "../../core/services/api";
import mixpanel from "mixpanel-browser";
import { TrashIco } from "../../shared/assets/isaIcons";
import { FreeShipping } from "../../shared/components/FreeShipping/FreeShipping";
import { FloatingButton } from "../../shared/components/FloatingButton/FloatingButton";

declare const window: any;

export function InitialCart(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();

  const listOfProducts = JSON.parse(
    localStorage.getItem("listOfProducts") ?? "[]"
  );
  const testee = useSelector((state: any) => state.ShopReducer?.teste ?? 0);
  const [list] = useState(listOfProducts);
  const isTokenValid = useSelector(
    (state: any) => state.AccountReducer?.isTokenValid
  );
  const mixToken: string = localStorage.getItem("mixPanelToken") ?? "";
  const partnerName = localStorage.getItem("partnerName");

  useEffect(() => {
    dispatch(isExpValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isTokenValid) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemove = (select: any) => {
    dispatch(setTeste(testee - 1));
    const found = list.findIndex((item: any) => item.id === select.id);

    if (list[found].count <= 1) {
      list.splice(found, 1);
    } else {
      list[found].count = list[found].count - 1;
    }
    dispatch(setProductsToCart(list));
  };

  const handleNext = () => {
    if (mixPanelOn === "true") {
      mixpanel.identify(mixToken);
      mixpanel.track("Carrinho", {
        fluxo: `${partnerName ?? "Isa lab"}`,
      });
      mixpanel.people.set({ Carrinho: "Carrinho" });
      let dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "carrinho",
        ecommerce: {
          fluxo: `${partnerName ?? "Isa lab"}`,
        },
      });
    }
    // history.push('/agendamento')
    history.push("/pre-cadastro");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/servico-selecionado")}
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName="Carrinho"
      />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ width: "100%" }}
      >
        <div style={{ marginTop: 32 }} />
        <IsaContainer display="flex" justifyContent="center">
          <div style={{ marginTop: 32 }} />
          <Grid
            container
            maxWidth={720}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <IsaH4Text>Carrinho</IsaH4Text>
            {listOfProducts.map((item: any, index: number) => {
              return (
                <Button key={index} data-test={`product-${index}-test-id`}>
                  <div style={{ width: "80%" }}>
                    <h5>{item.name}</h5>
                    <p>
                      <span>R$</span>
                      {item.price > 0
                        ? formatMoney(item.price * item.count)
                        : "0,00"}
                      { }
                    </p>
                  </div>
                  <RemoveProduct onClick={() => handleRemove(item)}>
                    <TrashIco />
                  </RemoveProduct>
                </Button>
              );
            })}
            {list.length === 0 ? (
              <div style={{ marginTop: 32 }}>
                <IsaPText data-test="emptyTextCart-test-id">
                  Seu carrinho está vazio
                </IsaPText>
              </div>
            ) : (
              ""
            )}
            <div style={{ marginTop: 32 }} />
            <FreeShipping />

            <FloatingButton
              dataTest={`nextButtonCart-test-id`}
              buttonName="Seguir para agendamento"
              disabled={list.length > 0 ? false : true}
              onClick={() => handleNext()}
            />
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
