import styled from 'styled-components';

export const Button = styled.div`
  width:100%;
  height: 100px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  border-width:0px;
  background:transparent;
  border-bottom-width:1px;
  border-color:#C4C4C4;
  border-style:solid;

  p{
    text-align:start;
    font-style: bold;
    color: #004474;
    margin-top:8px;
  }
`;

export const AddOrRemove = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:82px;

  button{
    width: 20px;
    height: 20px;
    border: 2px solid #004474;
    border-radius:50%;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  p{
    margin-top:0px;
  }
`;

export const TotalContainer = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:flex-end;
  width:100%;
  margin-top:24px;
  color: #004474;
  
  p{
    font-size:1.5rem;
  }

  h2{
    font-size:2.2rem;
    font-weight:bold;
  }

  span{
    font-size:1.5rem
  }
`;

export const SubtotalContainer = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:flex-end;
  width:100%;
  margin-top:16px;
  color: #666666;

  p{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 18px;
  }

  b{
    text-transform: uppercase;
    font-size: 12px;
  }

  h4{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const CouponContainer = styled.div`
  display:flex;
  width:100%;

  button{
    background: #FAFAFA;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #666666;
    padding:17px 11px;
    margin-left:17px;
  }
`
