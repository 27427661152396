import {
  LIST_PRODUCTS,
  SET_SERVICE_ATTRIBUTES,
  GET_FIND_PRODUCT
} from './selectedConstants';

const INITIAL_STATE = {
  listProducts: [],
  listFindExam: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LIST_PRODUCTS:
      const _listProducts = action.data?.map((item: any) => {
        return (
          {
            ...item,
            count: 1, //todo - why? 
          }
        )
      })

      return {
        ...state,
        listProducts: _listProducts,
      }
    case SET_SERVICE_ATTRIBUTES:
      var _setServiceAttributes

      _setServiceAttributes = {
        pageName : action.data.product.description,
        pageHeader : '',
        subtextPage : '',
        serviceId : action.data.product.id,
        medicalRequestNeeded: action.data.product.medicalRequestNeeded,
        alias: action.data.product.alias,
      }

      const data = JSON.stringify(_setServiceAttributes)
      localStorage.setItem('setServiceAttributes', data)
      
      return {
        ...state,
        setServiceAttributes: _setServiceAttributes
      }
    
    case GET_FIND_PRODUCT:
      var _listFindProduct
   
      _listFindProduct = action.data
      
      return {
        ...state,
        listFindProduct: _listFindProduct
      }
  }

  return state;
};