import { CREATE_FINAL_APPOINTMENT } from "./confirmationConstants";

import { apiIsa } from "../../../core/services/api";

const createAppointmentEnd = (dispatch: any, data: any) => {
  dispatch({ type: CREATE_FINAL_APPOINTMENT, data });
};

export const createAppointment = (
  data: any,
  authToken: string,
  callback: any
) => {
  return (dispatch: any) => {
    apiIsa
      .post("/appointment", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken}`,
        },
      })
      .then((responseJson) => {
        createAppointmentEnd(dispatch, responseJson?.data);
        callback(responseJson);
      })
      .catch((err) => {
        callback(err.response);
      });
  };
};
