import {
  CREATE_FINAL_APPOINTMENT
} from './confirmationConstants';

const INITIAL_STATE = {
dataAppointments: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CREATE_FINAL_APPOINTMENT:
      var _dataAppointments
      _dataAppointments = action.data

      return {
        ...state,
        dataAppointments: _dataAppointments
      }
  }

  return state;
};