import axios from "axios";

const baseUrl = process.env.REACT_APP_URL;

const baseUrlIsa = process.env.REACT_APP_API;

export const mixPanelInit = "17929802e4853f0522a4a5da62606eb0";

export const mixPanelOn = process.env.REACT_APP_MIX_PANEL_ON;

export const api = axios.create({
  baseURL: baseUrl,
});

export const authToken = process.env.REACT_APP_AUTHTOKEN ?? "";

export const apiIsa = axios.create({
  baseURL: baseUrlIsa,
});

export const apiIsaV2 = axios.create({
  baseURL: process.env.REACT_APP_API_V2,
});
