import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { apiIsa } from "../../../../core/services/api";
import { IsaButton } from "../../../../shared/components/IsaButton/IsaButton";
import { IsaDialog } from "../../../../shared/components/IsaDialog/isaDialog";
import { IsaInput } from "../../../../shared/components/IsaInput/isaInput";
import IsaLoader from "../../../../shared/components/IsaLoader/isaLoader";
import { IsaPText } from "../../../../shared/components/IsaPText/IsaPText";
import { phoneMask } from "../../../../shared/validators/phoneMask";
import { ButtonContainer } from "../../accountStyle";
import { changePageView } from "../../redux/accountActions";
import { DisplayPage } from "../telAuth";

interface MessageInterface {
  messageBody: string;
  messageButton: string;
  messageHeader: string;
}

export function SendCodeValidate() {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const telephoneNumber: string = useSelector(
    (state: any) => state.AccountReducer?.telephoneNumber
  );

  const [cellPhone, setCellPhone] = useState(phoneMask(telephoneNumber) || "");

  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [message, setMessage] = useState<MessageInterface>({
    messageBody: "",
    messageButton: "",
    messageHeader: "",
  });

  const handleSetTelephone = (e: any) => {
    if (e.length <= 15) {
      setCellPhone(e);
    }
  };

  const disableSendButton = () => {
    return cellPhone.length !== 15;
  };

  const handleSendCodeToValidatePhone = async () => {
    const cellPhoneNumber = cellPhone.replace(/\D/g, "");
    setIsLoading(true);
    try {
      await apiIsa.post(
        "/user/validate/request",
        {
          phone: cellPhoneNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );
      const splittedNumber = {
        ddd: cellPhoneNumber.slice(0, 2),
        beforeDash: cellPhoneNumber.slice(2, 7),
        afterDash: cellPhoneNumber.slice(7, 11),
      };
      const { ddd, beforeDash, afterDash } = splittedNumber;
      const formattedCellPhone = `(${ddd}) ${beforeDash}-${afterDash}`;

      dispatch(changePageView(formattedCellPhone, DisplayPage.SMS_VALIDATE));
    } catch (err: any) {
      setIsDialogOpen(true);
      setMessage({
        messageBody: err.message,
        messageButton: "Tentar novamente",
        messageHeader: "Erro ao enviar código",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <IsaLoader open={isLoading} />
      <IsaDialog
        open={isDialogOpen}
        header={message.messageHeader}
        bodyText={message.messageBody}
        buttonText={message.messageButton}
        closeDialog={() => setIsDialogOpen(false)}
      />
      <IsaPText color="#666666" textAlign="start">
        Para completar seu cadastro, informe seu número de celular para
        confirmação via SMS
      </IsaPText>
      <div style={{ marginBottom: 26 }} />
      <IsaInput
        isValid={cellPhone !== "" ? true : false}
        inputTitle="Telefone(Celular)"
        onChange={(e: any) => handleSetTelephone(e.target.value)}
        value={phoneMask(cellPhone)}
        type="tel"
      />
      <ButtonContainer>
        <IsaButton
          disabled={disableSendButton()}
          onClick={() => handleSendCodeToValidatePhone()}
        >
          Enviar código
        </IsaButton>
      </ButtonContainer>
    </>
  );
}
