/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import {
  apiIsa,
  authToken,
  mixPanelInit,
  mixPanelOn,
} from "../../core/services/api";
import mixpanel from "mixpanel-browser";
import ReactPixel from "react-facebook-pixel";
import CryptoJS from "crypto-js";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { getPartnerToken, savePartnerInfo } from "./redux/initialActions";

//Local Imports
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { IsaButton } from "../../shared/components/IsaButton/IsaButton";
import { IsaH3Text } from "../../shared/components/IsaH3Text/IsaH3Text";
import { useWindowSize } from "../../shared/components/hooks/useWindowSize";
import { IsaDialog } from "../../shared/components/IsaDialog/isaDialog";

//Logo
import IsaLogo from "../../shared/assets/LOGO_ISA2022-02.png";
import Bg from "../../shared/assets/splash.jpeg";
import InitialImg from "../../shared/assets/initial_img.svg";

//Stamps
import Ona from "../../shared/assets/stamps/Ona.png";

//Css
import "./initial.css";
import { DivLogo, TextComponent } from "./initialStyle";
import IsaLoader from "../../shared/components/IsaLoader/isaLoader";
import { enc } from "crypto-js";

const fbPixelAdvancedMatching = undefined;
const fbPixelOptions = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init("1077569959337668", fbPixelAdvancedMatching, fbPixelOptions);

enum ModalitiesId {
  ONLY_PRIVATE = 2,
  ONLY_HEALTH_INSURANCE = 1,
}

declare const window: any;

export function Initial() {
  const size = useWindowSize();
  const history = useHistory();
  const dispatch = useDispatch();

  const partnerInfo = useSelector(
    (state: any) => state.InitialReducer?.partnerInfo ?? ""
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState("");
  const partnerName = localStorage.getItem("partnerName");

  useEffect(() => {
    localStorage.setItem("walletNumber", "");
    localStorage.setItem("partnerDiscount", "null");
    localStorage.setItem("partnerName", "Isa lab");
    if (mixPanelOn === "true") {
      mixpanel.init(mixPanelInit, {
        debug: false,
        loaded: function (mixpanel) {
          mixpanel.track("Início fluxo", {
            fluxo: `${partnerName ?? "Isa lab"}`,
          });
          localStorage.setItem("mixPanelToken", mixpanel.get_distinct_id());
        },
      });
      let dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "origem",
        ecommerce: {
          origem: window.location.href,
        },
      });
    }
    localStorage.setItem("subRoute", "");
    setIsOpen(true);
    let _url = window.location.href.split("/");
    const url = _url[3].replaceAll("#", "");
    const foundParamsInUrl = url.search("params=");

    if (foundParamsInUrl === 0) {
      setIsDisabled(false);
      setIsOpen(false);
      return decryptParams(window.location.href);
    }
    if (url === "vacinadagripe") {
      localStorage.setItem("subRoute", "particular");
      localStorage.setItem(
        "setServiceAttributes",
        JSON.stringify({
          pageName: "Vacina da gripe",
          pageHeader: "",
          subtextPage: "",
          serviceId: 3,
        })
      );
      localStorage.setItem("listOfProducts", "[]");
      localStorage.setItem("patientData", '""');
      localStorage.setItem("discount", '""');
      localStorage.setItem("planInfos", '""');
      localStorage.setItem("partnerInfo", '""');
      return history.push("/pre-cadastro");
    } else {
      const partner = url === "pague_menos" ? url.toUpperCase() : url;
      dispatch(
        getPartnerToken(partner, (res: any) => {
          if (res?.status === 200) {
            const obj = JSON.stringify(res?.data);
            setImage(res?.data?.logo);
            dispatch(savePartnerInfo(res?.data));
            localStorage.setItem("route", "partner");
            localStorage.setItem(
              "partnerDiscount",
              res?.data.discountPercentage
            );

            localStorage.setItem("partnerName", res?.data.tradeName);
            localStorage.setItem("partnerInfo", obj);

            if (
              res?.data.partnerSquares &&
              res?.data.partnerSquares.length === 1
            ) {
              const squareId = res?.data.partnerSquares[0].squareId;
              localStorage.setItem(
                "operationInfo",
                JSON.stringify({ id: squareId, origin: "partner" })
              );
            }

            if (
              res?.data.partnerSquares &&
              res?.data.partnerSquares.length > 1
            ) {
              localStorage.setItem(
                "operationInfo",
                JSON.stringify({
                  id: process.env.REACT_APP_STANDARD_SQUARE,
                  origin: "partner",
                })
              );
            }

            setIsDisabled(false);
            setIsOpen(false);
            var date1: any = new Date(
              parseInt(localStorage.getItem("exp") ?? "0")
            );
            let loginExp = Math.round(date1);
            let dateNow: any = new Date();
            let dateNowRound = Math.round(dateNow / 1000);
            if (date1 !== "Invalid Date") {
              if (loginExp < dateNowRound) {
                localStorage.setItem("subRoute", "");
                localStorage.setItem("setServiceAttributes", "");
                localStorage.setItem("exp", "");
                localStorage.setItem("getAddressData", "[]");
                localStorage.setItem("listOfProducts", "[]");
                localStorage.setItem("token", "");
                localStorage.setItem("route", "partner");
                localStorage.setItem("planInfos", '""');
                localStorage.setItem("loginEmail", "");
                localStorage.setItem("getDateData", "");
              }
            }
          } else {
            setIsDisabled(false);
            setIsDialogOpen(false);
            setIsOpen(false);
            dispatch(savePartnerInfo("0"));
            localStorage.setItem(
              "operationInfo",
              JSON.stringify({
                id: process.env.REACT_APP_STANDARD_SQUARE,
                origin: "b2c",
              })
            );

            var _date1: any = new Date(
              parseInt(localStorage.getItem("exp") ?? "0")
            );
            let loginExp = Math.round(_date1);
            let dateNow: any = new Date();
            let dateNowRound = Math.round(dateNow / 1000);
            if (_date1 !== "Invalid Date") {
              if (loginExp < dateNowRound) {
                localStorage.setItem("subRoute", "");
                localStorage.setItem("setServiceAttributes", "");
                localStorage.setItem("exp", "");
                localStorage.setItem("getAddressData", "[]");
                localStorage.setItem("listOfProducts", "[]");
                localStorage.setItem("token", "");
                localStorage.setItem("route", "isaUser");
                localStorage.setItem("planInfos", '""');
                localStorage.setItem("loginEmail", "");
                localStorage.setItem("getDateData", "");
                localStorage.setItem(
                  "operationInfo",
                  JSON.stringify({
                    id: process.env.REACT_APP_STANDARD_SQUARE,
                    origin: "b2c",
                  })
                );
              }
            }
          }
        })
      );
    }
  }, []);

  const handleNextStep = () => {
    let _url = window.location.href.split("/");
    const url = _url[3].replaceAll("#", "");
    if (url === "paguemenos") {
      localStorage.setItem("subRoute", "particular");
      localStorage.setItem(
        "setServiceAttributes",
        JSON.stringify({
          pageName: "Vacinas",
          pageHeader: "Vacinas que aplicamos em casa",
          subtextPage: "",
          serviceId: "62745fe4-bffa-4174-8a6f-7a7ce79d17a5",
        })
      );
      localStorage.setItem("listOfProducts", "[]");
      localStorage.setItem("patientData", '""');
      localStorage.setItem("discount", '""');
      localStorage.setItem("planInfos", '""');
      localStorage.setItem("partnerInfo", '""');
      return history.push("/pre-cadastro");
    } else if (url === "PAGUE_MENOS") {
      localStorage.setItem("subRoute", "particular");
      localStorage.setItem("listOfProducts", "[]");
      localStorage.setItem("patientData", '""');
      localStorage.setItem("discount", '""');
      localStorage.setItem("planInfos", '""');
      return history.push("/categorias");
    } else {
      advanceAccordingId();
    }
  };

  const advanceAccordingId = () => {
    const modalitiesWithoutB2B = partnerInfo?.modalities?.filter(
      (modality: any) => modality.id !== 3
    );
    const modalities =
      modalitiesWithoutB2B?.length === 1 ? partnerInfo.modalities[0].id : null;
    switch (modalities) {
      case ModalitiesId.ONLY_PRIVATE:
        return (
          localStorage.setItem("subRoute", "particular"),
          localStorage.setItem("listOfProducts", "[]"),
          localStorage.setItem("patientData", '""'),
          localStorage.setItem("discount", '""'),
          localStorage.setItem("planInfos", '""'),
          history.push("/categorias")
        );
      case ModalitiesId.ONLY_HEALTH_INSURANCE:
        return (
          localStorage.setItem("subRoute", "plano de saude"),
          localStorage.setItem("setServiceAttributes", ""),
          localStorage.setItem("listOfProducts", "[]"),
          localStorage.setItem("patientData", '""'),
          localStorage.setItem("discount", '""'),
          history.push("/selecionar-convenio")
        );
      default:
        return history.push("/selecionar-convenio");
    }
  };

  const decryptParams = (params: any) => {
    let getUrlWithParams = params.split(`=`);
    let replaceUrl = params
      .replace(`${getUrlWithParams[0]}=`, "")
      .replace("#/", "");

    const decryptParams = CryptoJS.AES.decrypt(
      `${replaceUrl}`,
      "params"
    ).toString(enc.Utf8);
    const objParams = JSON.parse(decryptParams !== "" ? decryptParams : "{}");
    getProductDetails(objParams);
  };

  const getProductDetails = async (objParams: any) => {
    setIsOpen(true);
    try {
      const pricingTableId =
        process.env.REACT_APP_PRICING_TABLE_ID ||
        "939a3803-9128-4a60-83d9-3758040fd96d";
      const res = await apiIsa.get(
        `/product?productIds=${objParams?.listOfProducts.map(
          (item: { id: string }) => item.id
        )}&pricingTableId=${pricingTableId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authToken}`,
          },
        }
      );
      let listOfProducts = res.data.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          price: item.price,
          productExam: item.productGroup,
          count: 1,
        };
      });
      localStorage.setItem("planInfos", '""');
      localStorage.setItem("subRoute", objParams.subRoute);
      localStorage.setItem("route", objParams.route);
      localStorage.setItem(
        "partnerInfo",
        JSON.stringify(objParams.partnerInfo)
      );
      localStorage.setItem(
        "setServiceAttributes",
        JSON.stringify(objParams.setServiceAttributes)
      );
      localStorage.setItem(
        "partnerName",
        JSON.stringify(objParams.partnerName)
      );
      localStorage.setItem("listOfProducts", JSON.stringify(listOfProducts));
      localStorage.setItem(
        "getAddressData",
        JSON.stringify(objParams.getAddressData)
      );
      localStorage.setItem("discount", '""');
      if (objParams?.siteOrigin === "schedulePill")
        return history.push("/categorias");
      history.push("/pre-cadastro");
    } catch (err: any) {
    } finally {
      setIsOpen(true);
    }
  };

  return (
    <Box
      style={{
        height: "100vh",
        backgroundImage: `url(${Bg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      sx={{ flexGrow: 1 }}
    >
      <IsaLoader open={isOpen} />
      <IsaDialog
        open={isDialogOpen}
        header="Algo deu errado"
        bodyText="Infelizmente algo deu errado com a conexão, tente novamente mais tarde"
        buttonText="Fechar"
        closeDialog={() => setIsDialogOpen(false)}
      />
      <Grid
        className="gridContainerStyle"
        container
        direction="row"
        justifyContent="start"
        alignItems="center"
      >
        <Grid
          style={{ width: "50%" }}
          sx={{ display: { lg: "flex", md: "none", sm: "none", xs: "none" } }}
        >
          <img alt="img" src={InitialImg} />
        </Grid>
        <IsaContainer
          display={size.width < 940 && size.width !== 0 ? "" : "flex"}
          width={size.width < 940 && size.width !== 0 ? "100%" : "35%"}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <DivLogo style={{ marginTop: "220px" }}>
              {image ? (
                <img
                  alt="logo01"
                  src={image}
                  style={{
                    marginRight: 55,
                    height:
                      partnerInfo?.document === "20527493000120" // todo - pague-menos fortaleza
                        ? "80px"
                        : "49px",
                    maxWidth: size.width > 600 ? "auto" : "170px",
                  }}
                />
              ) : (
                ""
              )}
              <img alt="logo02" src={IsaLogo} style={{ height: "49px" }} />
            </DivLogo>
            <div style={{ marginTop: 48 }} />
            <IsaH3Text color="#004474" textAlign="center">
              A ISA vai até você!
            </IsaH3Text>
            <div style={{ marginTop: 24 }} />
            <Grid
              container
              maxWidth={720}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {partnerInfo === "0" || partnerInfo?.description === null ? (
                <TextComponent>
                  A <b>ISA</b> está aqui para proporcionar mais agilidade e
                  facilidade à forma que você cuida da sua saúde.É fácil, clique
                  e agende!
                </TextComponent>
              ) : (
                <TextComponent>{partnerInfo?.description}</TextComponent>
              )}

              <div style={{ marginTop: 18 }} />
              <IsaButton onClick={() => handleNextStep()} disabled={isDisabled}>
                Quero agendar
              </IsaButton>
            </Grid>

            <Divider style={{ width: "100%", margin: "32px 0" }} />

            <p
              style={{
                fontWeight: 700,
                color: "#666666",
                fontSize: 12,
                textTransform: "uppercase",
                marginBottom: 16,
              }}
            >
              Nosso certificado de qualidade
            </p>

            <Grid
              style={{
                width: "100%",
                maxWidth: 350,
                display: "flex",
                justifyContent: "center",
                gap: "35px",
              }}
            >
              <img src={Ona} alt="Certificado ONA" />
            </Grid>
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
