import { Dialog, Grid } from '@mui/material';
import { IsaButton } from '../IsaButton/IsaButton';
import { IsaContainer } from '../IsaContainer/IsaContainer';
import { IsaH3Text } from '../IsaH3Text/IsaH3Text';
import { IsaPText } from '../IsaPText/IsaPText';

//Css
import './isaDialog.css'
import {
  DialogContainer
} from './isaDialogStyle'

export function IsaDialog(props: any) {
  return (
    <div>
      <Dialog 
        fullScreen={true} 
        open={props.open} 
        onClose={props.handleClose}
      >
        <IsaContainer display='flex' justifyContent='center'>
          <Grid 
            container maxWidth={720}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <DialogContainer>
              <IsaH3Text textAlign={props.textAlign??'center'} color='#fff'>
                {props.header}
              </IsaH3Text>
              <div style={{marginTop:24}} />
              {props.bodyText ? 
                <IsaPText color='#fff'>{props.bodyText}</IsaPText> : props.body
              }
              <div style={{marginTop:24}} />
              <IsaButton 
                onClick={props.closeDialog}
                background='#004474'
              >
                {props.buttonText}
              </IsaButton>
            </DialogContainer>
          </Grid>
        </IsaContainer>
      </Dialog>
    </div>
  );
}
