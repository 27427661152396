import styled from 'styled-components';

export const Button = styled.div`
  width:100%;
  display:flex;
  align-items:center;
  text-align:start;
  justify-content:space-between;
  border-width:0px;
  background:transparent;
  border-bottom-width:1px;
  border-color:#C4C4C4;
  border-style:solid;
  transition: 0.5s;
  
  p{
    text-align:start;
    font-style: bold;
    font-weight: 700;
    font-size: 24px;
    color: #004474;
  }

  span{
    font-size:0.875rem;
  }

  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    color: #666666;
    text-transform: capitalize !important;
  }

  h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    display:flex;
    padding-left:5px;
  }
`;

export const BorderIcon = styled.button`
  display:flex;
  justify-content:center;
  align-items:center;
  max-width: 46px;
  max-height: 46px;
  padding:14px;
  background: #004474;
  border-radius: 74px;
  border-style:solid;
  border-width:0px;
`;  

export const ButtonDiv = styled.div`
  bottom: 10px;
  width: 100%;
  position: sticky;
`;

export const ShowMore = styled.button`
  margin-top: 20px;
  border: 1px solid #004474;
  box-sizing: border-box;
  border-radius: 32px;
  border-style: solid;
  background:transparent;
  padding: 4px 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #004474;
`;

export const DescriptionText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px;
  color: #666666 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; 
`

export const DiscountContainer = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 100%;
  text-align: center;
  color: #FFFFFF;
  background: #ED1E79;
  border-radius: 14px;
  padding: 8px;
  margin-left:16px;
  text-transform:capitalize;
`;

export const NotificationBox = styled.div`
  background: #F2F2F2;
  border-radius: 4px;
  padding:16px;
  margin-top:32px;
  color: #666666;
`;