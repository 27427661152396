import { Box, Grid } from "@material-ui/core";
import jwt_decode from "jwt-decode";
import { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  changePageView,
  createUser,
  isExpValid,
  loginUser,
} from "./redux/accountActions";

//Local Imports
import { IsaButton } from "../../shared/components/IsaButton/IsaButton";
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { IsaH3Text } from "../../shared/components/IsaH3Text/IsaH3Text";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";
import { IsaInput } from "../../shared/components/IsaInput/isaInput";
import IsaLoader from "../../shared/components/IsaLoader/isaLoader";
import { IsaPText } from "../../shared/components/IsaPText/IsaPText";
import { validateEmail } from "../../shared/validators/emailValidator";
import { validateName } from "../../shared/validators/validateName";

//Logo
import IcoBack from "../../shared/assets/ico_back.svg";
import IcoEyeClosed from "../../shared/assets/ico_eyeClosed.svg";
import IcoEyeOpen from "../../shared/assets/ico_eyeOpen.svg";

//Css
import mixpanel from "mixpanel-browser";
import { apiIsa, mixPanelOn } from "../../core/services/api";
import { IsaDialog } from "../../shared/components/IsaDialog/isaDialog";
import { UserOriginEnum } from "../../shared/enums/UserOriginEnum";
import { phoneMask } from "../../shared/validators/phoneMask";
import { ButtonAnchor, DivInput, Input } from "./accountStyle";
import { LoginResponseProps } from "./accountsInterface";
import { DisplayPage } from "./telAuth/telAuth";

interface MessageInterface {
  messageBody: string;
  messageButton: string;
  messageHeader: string;
}

declare const window: any;

export function Account() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const setServiceAttributesStorage = localStorage.getItem(
    "setServiceAttributes"
  );
  const setServiceAttributes = setServiceAttributesStorage
    ? JSON.parse(setServiceAttributesStorage ?? "")
    : "";

  const [colorInput, setColorInput] = useState({
    background: "#FFF",
    borderColor: "#DDDDDD",
    border: "#DDDDDD",
    color: "#DDDDDD",
  });
  const [validEmail, setValidEmail] = useState({
    background: "#FFF",
    borderColor: "#DDDDDD",
    border: "#DDDDDD",
    color: "#DDDDDD",
  });
  const [name, setName] = useState("");
  const [validName, setValidName] = useState({
    background: "#FFF",
    borderColor: "#DDDDDD",
    border: "#DDDDDD",
    color: "#DDDDDD",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isEqualPass, setIsEqualPass] = useState(false);
  const [loginFields, setLoginFields] = useState(false);
  const [isShowEye, showEye] = useState(true);
  const [isShowEyeConfirm, setShowEyeConfirm] = useState(true);

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<MessageInterface>({
    messageBody: "",
    messageButton: "",
    messageHeader: "",
  });
  const [password, setPassword] = useState("");
  const [againstPassword, setAgainstPassword] = useState("");

  const loginByButton = useRef(false);

  const [cellPhone, setCellPhone] = useState("");
  const [validPhone, setValidPhone] = useState({
    background: "#FFF",
    borderColor: "#DDDDDD",
    border: "#DDDDDD",
    color: "#DDDDDD",
  });

  const phoneValidate = (phone: any) => {
    const isValid = phone.length <= 15;
    if (isValid) {
      setCellPhone(phone);
    }

    if (phone !== "") {
      setValidPhone({
        background: "rgba(0,198,67,0.05)",
        borderColor: "#00C643",
        border: "#00C643",
        color: "#000000",
      });
    } else {
      setValidPhone({
        background: "rgba(233, 59, 90, 0.05)",
        borderColor: "#E93B5A",
        border: "#E93B5A",
        color: "#E93B5A",
      });
    }
  };

  const isTokenValid = useSelector(
    (state: any) => state.AccountReducer?.isTokenValid
  );
  const mixToken: string = localStorage.getItem("mixPanelToken") ?? "";
  const partnerName = localStorage.getItem("partnerName");

  useEffect(() => {
    dispatch(isExpValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isTokenValid) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loginFields) {
      disableEqual();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, againstPassword]);
  useEffect(() => {
    setColorInput({
      background: "#FFFFFF",
      borderColor: "#DDDDDD",
      border: "#DDDDDD",
      color: "#DDDDDD",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginFields]);

  const Disabled = () => {
    if (!loginFields) {
      if (
        validateEmail(email) &&
        password !== "" &&
        isEqualPass !== false &&
        cellPhone.replace(/\D/g, "").length === 11 &&
        cellPhone !== "" &&
        validateName(name)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (validateEmail(email) && password.length >= 6) {
        return false;
      } else {
        return true;
      }
    }
  };

  const reSendCode = async (userId: string, userToken: string) => {
    setIsOpen(true);
    try {
      await apiIsa.post(
        "user/resend-validate",
        {
          userId: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userToken}`,
          },
        }
      );
      setIsDialogOpen(true);
      setMessage({
        messageBody: "Mensagem reenviada com sucesso",
        messageButton: "Fechar",
        messageHeader: "Sucesso",
      });
    } catch (err: any) {
      setIsDialogOpen(true);
      setMessage({
        messageBody: err?.message ?? "Erro ao enviar código",
        messageButton: "Tentar novamente",
        messageHeader: "Erro ao enviar código",
      });
    } finally {
      setIsOpen(false);
    }
  };

  const doLogin = () => {
    const data = {
      email,
      password,
    };

    dispatch(
      loginUser(data, async (res: any) => {
        setIsOpen(false);
        if (res?.data?.message) {
          setIsDialogOpen(true);
          setMessage({
            messageBody: res.data.message,
            messageButton: "Fechar",
            messageHeader: "Algo deu errado",
          });
        } else {
          if (res?.data?.errors?.length > 0) {
            setIsDialogOpen(true);
            setMessage({
              messageBody: "O email deve ser um email válido",
              messageButton: "Fechar",
              messageHeader: "Algo deu errado",
            });
          } else {
            const decript: LoginResponseProps = jwt_decode(res?.token);

            //! VOLTAR
            if (decript.data.origin === UserOriginEnum.CUSTOMER) {
              // ! VOLTAR
              if (mixPanelOn === "true") {
                mixpanel.identify(mixToken);
                mixpanel.track("Acessar conta", {
                  email: email,
                  fluxo: `${partnerName ?? "Isa lab"}`,
                });
                mixpanel.people.set({ "Acessar conta": "Logado" });
                let dataLayer = window.dataLayer || [];
                dataLayer.push({
                  event: "acessar_conta",
                  ecommerce: {
                    email: email,
                    fluxo: `${partnerName ?? "Isa lab"}`,
                  },
                });
              }

              const validated = decript.data?.isValidated;

              const birthDateSplittable = decript.data?.birthDate
                ? decript.data?.birthDate.split("-")
                : null;
              const birthDate = birthDateSplittable
                ? `${birthDateSplittable[2]}/${birthDateSplittable[1]}/${birthDateSplittable[0]}`
                : "";
              const phone =
                decript.data?.phone?.length <= 11 ? decript.data?.phone : null;

              localStorage.setItem(
                "patientData",
                JSON.stringify({
                  birthDate,
                  ddd: decript.data?.ddd ?? null,
                  document: decript.data?.document ?? null,
                  documentType: decript.data?.documentType ?? null,
                  email: decript.data?.email ?? null,
                  fullName: decript.data?.fullName ?? "",
                  phone,
                })
              );

              if (validated) return history.push("/dados-do-paciente");

              if (phone) {
                dispatch(
                  changePageView(
                    cellPhone.replace(/\D/g, "") || phone.replace(/\D/g, ""),
                    DisplayPage.SMS_VALIDATE
                  )
                );
              }

              if (loginByButton.current)
                await reSendCode(decript.data?.id, res?.token);

                return history.push("/dados-do-paciente");

              // history.push("/auth");

              //! VOLTAR
            } else {
              setIsDialogOpen(true);
              setMessage({
                messageBody:
                  "Você não tem permissão para continuar nessa aplicação",
                messageButton: "Fechar",
                messageHeader: "Algo deu errado",
              });
            }
          }
        }
      })
    );
  };

  const handleLogin = () => {
    setLoginFields(true);

    loginByButton.current = true;
  };

  const handleUser = () => {
    setIsOpen(true);

    const cellPhoneNumber = cellPhone.replace(/\D/g, "");

    localStorage.setItem("loginEmail", email);
    localStorage.setItem("loginPhone", cellPhoneNumber);

    const data = {
      name,
      email,
      password,
      confirmPassword: againstPassword,
      phone: cellPhoneNumber,
    };

    if (loginFields) {
      doLogin();
    } else {
      loginByButton.current = false;

      dispatch(
        createUser(data, (res: any) => {
          if (res?.data) {
            setIsOpen(false);
            setIsDialogOpen(true);
            setMessage({
              messageBody: res?.data?.message,
              messageButton: "Fechar",
              messageHeader: "Algo deu errado",
            });
          } else {
            // ! VOLTAR
            if (mixPanelOn === "true") {
              mixpanel.identify(mixToken);
              mixpanel.track("Criar conta", {
                email: email,
                fluxo: `${partnerName ?? "Isa lab"}`,
              });
              mixpanel.people.set({
                "Criar conta": `conta criada para o usuario ${email}`,
              });
              let dataLayer = window.dataLayer || [];
              dataLayer.push({
                event: "criar_conta",
                ecommerce: {
                  email: email,
                  fluxo: `${partnerName ?? "Isa lab"}`,
                },
              });
            }

            doLogin();
          }
        })
      );
    }
  };

  const handleSetPassword = (e: any, pass: string) => {
    if (pass === "password") {
      setPassword(e);
    } else if (pass === "againstPassword") {
      setAgainstPassword(e);
    }
  };

  const disableEqual = () => {
    if (password !== "" && againstPassword !== "") {
      if (password !== againstPassword) {
        setColorInput({
          background: "rgba(233, 59, 90, 0.05)",
          borderColor: "#E93B5A",
          border: "#E93B5A",
          color: "#E93B5A",
        });
        setIsEqualPass(false);
      } else if (password === againstPassword && password.length >= 6) {
        setColorInput({
          background: "rgba(0,198,67,0.05)",
          borderColor: "#00C643",
          border: "#00C643",
          color: "#00C643",
        });
        setIsEqualPass(true);
      }
    }
  };

  const emailValidate = (email: any) => {
    setEmail(email);
    if (validateEmail(email) && email !== "") {
      setValidEmail({
        background: "rgba(0,198,67,0.05)",
        borderColor: "#00C643",
        border: "#00C643",
        color: "#000000",
      });
    } else if (email !== "") {
      setValidEmail({
        background: "rgba(233, 59, 90, 0.05)",
        borderColor: "#E93B5A",
        border: "#E93B5A",
        color: "#E93B5A",
      });
    }
  };
  const nameValidate = (name: any) => {
    setName(name);
    if (validateName(name) && name !== "") {
      setValidName({
        background: "rgba(0,198,67,0.05)",
        borderColor: "#00C643",
        border: "#00C643",
        color: "#000000",
      });
    } else {
      setValidName({
        background: "rgba(233, 59, 90, 0.05)",
        borderColor: "#E93B5A",
        border: "#E93B5A",
        color: "#E93B5A",
      });
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IsaLoader open={isOpen} />
      <IsaDialog
        open={isDialogOpen}
        header={message.messageHeader}
        bodyText={message.messageBody}
        buttonText={message.messageButton}
        closeDialog={() => setIsDialogOpen(false)}
      />
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() =>
                !loginFields
                  ? history.push("/agendamento")
                  : setLoginFields(false)
              }
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName={setServiceAttributes.pageName ?? ""}
      />
      <Grid
        style={{ height: "100%" }}
        container
        direction="column"
        justifyContent="start"
        alignItems="center"
      >
        <IsaContainer>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <div style={{ marginTop: 67 }} />
            <Grid
              container
              maxWidth={720}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <IsaH3Text color="#004474" textAlign="start">
                {!loginFields ? "Vamos criar sua conta" : "Login"}
              </IsaH3Text>
              <div style={{ marginBottom: loginFields ? 24 : 6 }} />
              {!loginFields ? (
                <>
                  <IsaPText color="#666666" textAlign="start">
                    É necessário uma conta para continuar o agendamento.
                  </IsaPText>
                  <div style={{ marginBottom: 16 }} />
                  <IsaPText color="#666666" textAlign="start">
                    Já tem uma conta?{" "}
                    <ButtonAnchor onClick={handleLogin}>
                      Clique aqui
                    </ButtonAnchor>
                  </IsaPText>
                  <div style={{ marginTop: 24 }} />

                  <IsaInput
                    background={validName.background}
                    borderColor={validName.borderColor}
                    border={validName.border}
                    color={validName.color}
                    inputTitle="Nome"
                    onChange={(e: any) => nameValidate(e.target.value)}
                    value={name}
                  />
                  <div style={{ marginTop: 24 }} />
                </>
              ) : (
                ""
              )}

              <IsaInput
                background={validEmail.background}
                borderColor={validEmail.borderColor}
                border={validEmail.border}
                color={validEmail.color}
                inputTitle="Email"
                onChange={(e: any) =>
                  emailValidate(
                    e.target.value.toLowerCase().replaceAll(" ", "")
                  )
                }
                value={email}
              />
              <div style={{ marginTop: 24 }} />

              <DivInput>
                <h5 style={{ textAlign: "start" }}>
                  {!loginFields ? "Senha " : "Senha"}
                </h5>
                {!loginFields ? (
                  <p>A senha deve conter no mínimo 6 caracteres</p>
                ) : (
                  <IsaPText color="#666666" textAlign="end">
                    <ButtonAnchor
                      style={{ color: "#666666" }}
                      onClick={() => history.push("/resetar-senha")}
                    >
                      Esqueceu sua senha ?
                    </ButtonAnchor>
                  </IsaPText>
                )}
              </DivInput>

              <div style={{ marginBottom: 8 }} />

              <Input
                background={colorInput.background}
                borderColor={colorInput.borderColor}
                border={colorInput.border}
                color={colorInput.color}
              >
                <input
                  type={isShowEye === true ? "password" : "text"}
                  onChange={(e: any) =>
                    handleSetPassword(e.target.value, "password")
                  }
                />
                <img
                  onClick={() => showEye(!isShowEye)}
                  alt="icon"
                  src={isShowEye === true ? IcoEyeOpen : IcoEyeClosed}
                />
              </Input>

              {!loginFields ? (
                <>
                  <div style={{ marginBottom: 24 }} />
                  <h5 style={{ textAlign: "start", width: "100%" }}>
                    Confirmar senha
                  </h5>
                  <div style={{ marginBottom: 8 }} />
                  <Input
                    background={colorInput.background}
                    borderColor={colorInput.borderColor}
                    border={colorInput.border}
                    color={colorInput.color}
                  >
                    <input
                      type={isShowEyeConfirm === true ? "password" : "text"}
                      onChange={(e: any) =>
                        handleSetPassword(e.target.value, "againstPassword")
                      }
                    />
                    <img
                      onClick={() => setShowEyeConfirm(!isShowEyeConfirm)}
                      alt="icon"
                      src={
                        isShowEyeConfirm === true ? IcoEyeOpen : IcoEyeClosed
                      }
                    />
                  </Input>
                </>
              ) : (
                ""
              )}

              {!loginFields ? (
                <>
                  <div style={{ marginBottom: 26 }} />

                  <IsaInput
                    background={validPhone.background}
                    borderColor={validPhone.borderColor}
                    border={validPhone.border}
                    color={validPhone.color}
                    isValid={cellPhone !== "" ? true : false}
                    inputTitle="Telefone (Celular)"
                    onChange={(e: any) => phoneValidate(e.target.value)}
                    value={phoneMask(cellPhone)}
                    type="tel"
                  />
                </>
              ) : (
                ""
              )}

              <div style={{ marginTop: loginFields ? 50 : 40 }} />

              <IsaButton disabled={Disabled()} onClick={() => handleUser()}>
                Avançar
              </IsaButton>
              <div style={{ marginTop: 38 }} />
            </Grid>
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
