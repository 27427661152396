import * as yup from "yup";
import { cpfValidator } from "../../shared/validators/cpfValidator";
import { validateName } from "../../shared/validators/validateName";
import { validateCellPhone } from "../../shared/validators/phoneMask";
import { dataValidate } from "../../shared/validators/dateMask";
import { dynamicValidation } from "../../shared/validators/dynamicValidation";

export const patientSchema = yup.object().shape({
  fullName: yup
    .string()
    .required("Preencha esse campo para continuar")
    .test("isValidName", "Nome inválido", (value) => {
      return value ? validateName(value) : true;
    }),
  genderOption: yup.string().required("Preencha esse campo para continuar"),
  patientOption: yup.string().required("Preencha esse campo para continuar"),
  documentType: yup.string().required("Preencha esse campo para continuar"),
  document: yup
    .string()
    .test("Campo inválido", "Documento inválido", (value) =>
      dynamicValidation(value)
    )
    .required("Documento obrigatório"),

  email: yup
    .string()
    .email("Email inválido")
    .required("Preencha esse campo para continuar"),
  cellphone: yup
    .string()
    .required("Preencha esse campo para continuar")
    .test(
      "is-cellphone-valid",
      "Celular inválido. Use o formato (00) 00000-0000",
      (value) => {
        return validateCellPhone(value);
      }
    ),
  birthday: yup
    .string()
    .test(
      "birthday",
      "A data deve ser  válida e maior do que 01/01/1890",
      (date: string | any) => {
        return dataValidate(date);
      }
    )
    .required("A data de nascimento é obrigatória"),
});

export const responsibleSchema = yup.object().shape({
  fullName: yup
    .string()
    .required("Preencha esse campo para continuar")
    .test("isValidName", "Nome inválido", (value) => {
      return value ? validateName(value) : true;
    }),
  genderOption: yup.string().required("Preencha esse campo para continuar"),
  patientOption: yup.string().required("Preencha esse campo para continuar"),
  documentType: yup.string().required("Preencha esse campo para continuar"),
  document: yup
    .string()
    .test("cpf", "CPF inválido", (value) => cpfValidator(value))
    .required("CPF obrigatório"),
  birthday: yup
    .string()
    .test(
      "birthday",
      "A data deve ser  válida e maior do que 01/01/1890",
      (date: string | any) => {
        return dataValidate(date);
      }
    )
    .required("A data de nascimento é obrigatória"),

  responsibleFullName: yup
    .string()
    .required("Preencha esse campo para continuar")
    .test("isValidName", "Nome inválido", (value) => {
      return value ? validateName(value) : true;
    }),
  responsibleDocumentType: yup
    .string()
    .required("Preencha esse campo para continuar"),
  responsibleDocument: yup
    .string()
    .test("Campo inválido", "Documento inválido", (value) =>
      dynamicValidation(value)
    )
    .required("Documento obrigatório"),
  responsibleEmail: yup
    .string()
    .email("Email inválido")
    .required("Preencha esse campo para continuar"),
  responsibleCellphone: yup
    .string()
    .required("Preencha esse campo para continuar")
    .matches(
      /^\(\d{2}\)\s\d{4,5}-\d{4}$/,
      "Celular inválido. Use o formato (00) 00000-0000"
    ),
  responsibleRelationship: yup
    .string()
    .required("Preencha esse campo para continuar"),
});

export const otherPersonSchema = yup.object().shape({
  fullName: yup
    .string()
    .required("Preencha esse campo para continuar")
    .test("isValidName", "Nome inválido", (value) => {
      return value ? validateName(value) : true;
    }),
  genderOption: yup.string().required("Preencha esse campo para continuar"),
  patientOption: yup.string().required("Preencha esse campo para continuar"),
  documentType: yup.string().required("Preencha esse campo para continuar"),
  document: yup
    .string()
    .test("cpf", "CPF inválido", (value) => cpfValidator(value))
    .required("CPF obrigatório"),
  birthday: yup
    .string()
    .test(
      "birthday",
      "A data deve ser  válida e maior do que 01/01/1890",
      (date: string | any) => {
        return dataValidate(date);
      }
    )
    .required("A data de nascimento é obrigatória"),
  email: yup
    .string()
    .email("Email inválido")
    .required("Preencha esse campo para continuar"),
  cellphone: yup
    .string()
    .required("Preencha esse campo para continuar")
    .matches(
      /^\(\d{2}\)\s\d{4,5}-\d{4}$/,
      "Celular inválido. Use o formato (00) 00000-0000"
    ),

  responsibleFullName: yup
    .string()
    .required("Preencha esse campo para continuar")
    .test("isValidName", "Nome inválido", (value) => {
      return value ? validateName(value) : true;
    }),
  responsibleDocumentType: yup
    .string()
    .required("Preencha esse campo para continuar"),
  responsibleDocument: yup
    .string()
    .test("cpf", "CPF inválido", (value) => cpfValidator(value))
    .required("CPF obrigatório"),
  responsibleEmail: yup
    .string()
    .email("Email inválido")
    .required("Preencha esse campo para continuar"),
  responsibleCellphone: yup
    .string()
    .required("Preencha esse campo para continuar")
    .matches(
      /^\(\d{2}\)\s\d{4,5}-\d{4}$/,
      "Celular inválido. Use o formato (00) 00000-0000"
    ),
});

export const otherPersonSchemaWithNoDoc = yup.object().shape({
  fullName: yup
    .string()
    .required("Preencha esse campo para continuar")
    .test("isValidName", "Nome inválido", (value) => {
      return value ? validateName(value) : true;
    }),
  genderOption: yup.string().required("Preencha esse campo para continuar"),
  patientOption: yup.string().required("Preencha esse campo para continuar"),
  documentType: yup.string().required("Preencha esse campo para continuar"),
  birthday: yup
    .string()
    .test(
      "birthday",
      "A data deve ser  válida e maior do que 01/01/1890",
      (date: string | any) => {
        return dataValidate(date);
      }
    )
    .required("A data de nascimento é obrigatória"),

  responsibleFullName: yup
    .string()
    .required("Preencha esse campo para continuar")
    .test("isValidName", "Nome inválido", (value) => {
      return value ? validateName(value) : true;
    }),
  responsibleDocumentType: yup
    .string()
    .required("Preencha esse campo para continuar"),
  responsibleDocument: yup
    .string()
    .test("Campo inválido", "Documento inválido", (value) =>
      dynamicValidation(value)
    )
    .required("Documento obrigatório"),
  responsibleEmail: yup
    .string()
    .email("Email inválido")
    .required("Preencha esse campo para continuar"),
  responsibleCellphone: yup
    .string()
    .required("Preencha esse campo para continuar")
    .matches(
      /^\(\d{2}\)\s\d{4,5}-\d{4}$/,
      "Celular inválido. Use o formato (00) 00000-0000"
    ),
  responsibleRelationship: yup
    .string()
    .required("Preencha esse campo para continuar"),
});

export const listOfGender = [
  { value: "MALE", label: "Homem" },
  { value: "MALE_TRANS", label: "Homem Trans" },
  { value: "FEMALE", label: "Mulher" },
  { value: "FEMALE_TRANS", label: "Mulher Trans" },
];

export const listOfPatientRelations = [
  { value: "PATIENT", label: "Eu sou o paciente" },
  {
    value: "RESPONSIBLE",
    label: "Sou responsável legal pelo paciente",
  },
  // {
  //   value: "OTHER_PERSON",
  //   label: "O paciente é outra pessoa não dependente",
  // },
];

export const listOfDocumentTypes = [
  { value: "CPF", label: "CPF" },
  { value: "PASSPORT", label: "Passaporte" },
];
export const listOfDocumentTypesByLegalResponsible = [
  { value: "CPF", label: "CPF" },
  // { value: "RG", label: "RG" },
  { value: "NO_DOC", label: "Sem documento" },
];

export const listOfPatientRelationships = [
  { value: "FATHER", label: "Pai" },
  { value: "MOTHER", label: "Mae" },
  { value: "CHILD", label: "Filho" },
  { value: "TUTOR", label: "Tutor" },
  { value: "GUARDIAN", label: "Guardião" },
];
