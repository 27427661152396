
//Css
import {
  Container
} from './IsaContainerStyle'

export function IsaContainer(props: any) {
  return (
    <Container style={{
      width:props.width??'100%',
      maxWidth:props.width??'100%',
      display: props.display,
      alignContent: props.alignContent,
      alignItems: props.alignItems,
      flexDirection: props.flexDirection,
      justifyContent: props.justifyContent,
    }}>
      {props.children}
    </Container>
  );
}