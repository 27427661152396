/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";
import PixIcon from "@mui/icons-material/Pix";
import AWS from "aws-sdk";
import { useEffect, useState } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import ReactPixel from "react-facebook-pixel";
import { useHistory } from "react-router";

//Redux
import { useDispatch, useSelector } from "react-redux";

//Json
import parcelas from "../../shared/json/parcelas.json";

//Local Imports
import { IsaButton } from "../../shared/components/IsaButton/IsaButton";
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";
import { IsaInput } from "../../shared/components/IsaInput/isaInput";
import IsaLoader from "../../shared/components/IsaLoader/isaLoader";
import {
  IsaOption,
  IsaSelector,
} from "../../shared/components/IsaSelector/IsaSelector";

//Logo
import IcoBack from "../../shared/assets/ico_back.svg";
import MastercardIco from "../../shared/assets/mastercard_ico.svg";

//Stamps
import PaymentSecure from "../../shared/assets/stamps/PaymentSecure.png";

//Css
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import mixpanel from "mixpanel-browser";
import { mixPanelOn } from "../../core/services/api";
import { IsaDialog } from "../../shared/components/IsaDialog/isaDialog";
import { IsaH3Text } from "../../shared/components/IsaH3Text/IsaH3Text";
import { IsaInputWButton } from "../../shared/components/IsaInputWButton/IsaInputWButton";
import { IsaPText } from "../../shared/components/IsaPText/IsaPText";
import { useWindowSize } from "../../shared/components/hooks/useWindowSize";
import { creditcardMask } from "../../shared/validators/creditcardMask";
import { dateMaskMMYY } from "../../shared/validators/dateMask";
import { getFlagCard } from "../../shared/validators/flagCard";
import { formatMoney } from "../../shared/validators/formatMoney";
import { isExpValid } from "../account/redux/accountActions";
import { createAppointment } from "../confirmation/redux/confirmationActions";
import { RowDiv, SelectorRow } from "./paymentStyle";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_AWS,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY_AWS,
});

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_AWS;
const REGION = process.env.REACT_APP_REGION_AWS;

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

declare const window: any;

export function Payment() {
  const history = useHistory();
  const size = useWindowSize();
  const dispatch = useDispatch();

  const getAddressData = JSON.parse(
    localStorage.getItem("getAddressData") ?? ""
  );
  const getDateData = JSON.parse(localStorage.getItem("getDateData") ?? "{}");
  const patientData = JSON.parse(localStorage.getItem("patientData") ?? "{}");
  const priceValue = JSON.parse(localStorage.getItem("priceValue") ?? "{}");
  const partnerInfo = JSON.parse(localStorage.getItem("partnerInfo") ?? "{}");
  const listOfProducts = JSON.parse(
    localStorage.getItem("listOfProducts") ?? "[]"
  );
  const sessionToken = localStorage.getItem("token") ?? "";
  const discount = JSON.parse(localStorage.getItem("discount") ?? "");

  const [textDialog, setTextDialog] = useState<any>({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [portion, setCPortion] = useState("");
  //
  const [openPortionSelector, setOpenPortionSelector] = useState(false);
  const [nrCardIcon, setNrCardIcon] = useState("");

  const [securityCard, setSecurityCard] = useState("");
  // Card
  const [expiry, setExpiry] = useState("");
  const [focused, setFocused] = useState<any>(undefined);
  const [cardName, setCardName] = useState("");
  const [nrCard, setNrCard] = useState("");
  const [appointmentItems, setAppointmentItems] = useState<any>([]);
  const isTokenValid = useSelector(
    (state: any) => state.AccountReducer?.isTokenValid
  );
  const mixToken: string = localStorage.getItem("mixPanelToken") ?? "";
  const partnerName = localStorage.getItem("partnerName");
  const operationInfo = JSON.parse(
    localStorage.getItem("operationInfo") ??
    JSON.stringify({ id: process.env.REACT_APP_STANDARD_SQUARE })
  );

  const setServiceAttributesStorage = localStorage.getItem(
    "setServiceAttributes"
  );
  const setServiceAttributes = setServiceAttributesStorage
    ? JSON.parse(setServiceAttributesStorage ?? "")
    : "";

  const listDocumentsImage = useSelector(
    (state: any) => state.SendDocumentsReducer?.listDocumentsImage ?? []
  );
  const [files, setFiles] = useState<any>([]);
  const [paymentType, setPaymentType] = useState("credit_card");

  useEffect(() => {
    dispatch(isExpValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isTokenValid) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAppointmentItems([]);
    const oldArray = appointmentItems; //todo não vai ser usado
    for (var i = 0; i < listOfProducts.length; i++) {
      for (var x = 0; x < listOfProducts[i].count; x++) {
        const newArray = {
          examId: listOfProducts[i].productExam?.examId,
          description: listOfProducts[i].productExam?.name,
        };
        oldArray.push(newArray);
        setAppointmentItems(oldArray);
      }
    }
  }, []);

  const s3Upload = async (fileName: string, fileDoc: any) => {
    var bucketName = "";
    if (fileName === "medicRequest") {
      bucketName = "/pedido medico";
    } else {
      bucketName = "/carteirinha";
    }
    const params = {
      Body: fileDoc.file,
      Bucket: `${S3_BUCKET}/${patientData.fullName}${bucketName}`,
      Key: fileDoc.file.name,
    };

    return myBucket
      .putObject(params)
      .on("httpDone", async () => { })
      .send(() => { });
  };

  const handleSelectPortion = (e: any) => {
    setOpenPortionSelector(false);
    setCPortion(e);
  };

  const isDisabled = () => {
    if (
      nrCard !== "" &&
      nrCard.length <= 20 &&
      cardName !== "" &&
      securityCard !== "" &&
      portion !== "" &&
      expiry !== ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleChangeNrCard = (e: any) => {
    if (e.length <= 19) {
      setNrCard(e);
      const result = getFlagCard(e);
      setIco(result);
    }
  };

  const setIco = (card: any) => {
    switch (card) {
      case "mastercard":
        return setNrCardIcon(MastercardIco);
      default:
        return setNrCardIcon("");
    }
  };

  const setValidate = (date: any) => {
    if (date.length < 6) {
      setExpiry(dateMaskMMYY(date));
    }
  };

  const setSecurityCode = (code: any) => {
    if (code.length < 5) {
      setSecurityCard(code);
    }
  };

  const handleConfirm = async () => {
    setIsOpen(true);
    if (setServiceAttributes?.medicalRequestNeeded) {
      if (listDocumentsImage.medicRequest?.length > 0) {
        const resS3Upload: any = await s3Upload(
          "medicRequest",
          listDocumentsImage.medicRequest[0]
        );
        const data = {
          path: `https://documentos-isa.s3.sa-east-1.amazonaws.com${resS3Upload?.request?.httpRequest?.path.replaceAll(
            "%20",
            "+"
          )}`,
          description:
            partnerInfo.document !== "35268347000102"
              ? "pedido médico"
              : "documento",
        };
        const newArray = files;
        newArray.push(data);
        setFiles(newArray);
      }
    }

    if (setServiceAttributes?.alias === 'vaccine') {
      if (listDocumentsImage.vaccinationCard?.length > 0) {
        const resS3Upload: any = await s3Upload(
          "vaccinationCard",
          listDocumentsImage.vaccinationCard[0]
        );
        const data = {
          path: `https://documentos-isa.s3.sa-east-1.amazonaws.com${resS3Upload?.request?.httpRequest?.path.replaceAll(
            "%20",
            "+"
          )}`,
          description: "carteirinha de vacinacao",
        };
        const newArray = files;
        newArray.push(data);
        setFiles(newArray);
      }
    }

    var fullDate = getDateData != "" ? getDateData?.date.split("/") : "";
    var birth = patientData ? patientData?.birthDate.split("/") : "";

    const obj = {
      date: `${fullDate[2]}-${fullDate[1]}-${fullDate[0]}T00:00:00Z`,
      scheduleId: getDateData?.dateId,
      type: "private",
      partnerId: partnerInfo?.id ?? undefined,
      voucherId: discount !== "" ? discount?.voucherId : undefined,
      customer: {
        type: patientData?.customerType,
        fullName: patientData?.customer?.fullName,
        documentType: patientData?.customer?.documentType,
        document:
          patientData?.customer?.document !== ""
            ? patientData?.customer?.document
              .replaceAll(".", "")
              .replaceAll("-", "")
            : undefined,
        ddd: patientData?.customer?.ddd,
        phone: patientData?.customer?.phone,
        email: patientData?.customer?.email,
      },
      person: {
        fullName: patientData?.fullName,
        birthDate: `${birth[2]}-${birth[1]}-${birth[0]}T00:00:00Z`,
        documentType:
          patientData?.documentType === "Passaporte"
            ? "PASSPORT"
            : patientData?.documentType,
        document:
          patientData?.document !== ""
            ? patientData?.document.replaceAll(".", "").replaceAll("-", "")
            : undefined,
        ddd:
          patientData?.ddd !== ""
            ? patientData?.ddd
            : patientData?.customer?.ddd,
        phone:
          patientData?.phone !== ""
            ? patientData?.phone
            : patientData?.customer?.phone,
        email:
          patientData?.email !== ""
            ? patientData?.email
            : patientData?.customer?.email,
        zipCode: patientData?.zipCode.replaceAll("-", ""),
        street: patientData?.street,
        streetNumber: patientData?.streetNumber,
        neighborhood: patientData?.neighborhood,
        complement: patientData?.complement,
        city: patientData?.city,
        state: patientData?.state,
        gender: patientData?.gender,
      },
      appointmentItems: listOfProducts.map((item: any) => {
        return {
          productId: item.id,
          quantity: 1,
        };
      }),
      privateData: {
        payment: {
          cardNumber: nrCard !== "" ? nrCard.replaceAll(" ", "") : undefined,
          cardCvv: securityCard !== "" ? securityCard : undefined,
          installments:
            portion !== "" ? portion.replaceAll("x", "") : undefined,
          cardExpirationDate:
            expiry !== "" ? expiry.replaceAll("/", "") : undefined,
          cardHolderName: cardName !== "" ? cardName : undefined,
          paymentMethod: paymentType,
        },
      },
      files: files,
      squareId: operationInfo.id,
    };

    dispatch(
      createAppointment(JSON.stringify(obj), sessionToken, (response: any) => {
        setIsOpen(false);
        if (response?.status === 200 || response?.status === 201) {
          if (mixPanelOn === "true") {
            mixpanel.identify(mixToken);
            mixpanel.track("Pagamento", {
              fluxo: `${partnerName ?? "Isa lab"}`,
              "tipo de pagamento": paymentType,
            });
            mixpanel.people.set({ Pagamento: "Pagamento" });
            mixpanel.track("Status do pagamento", {
              pagamento: "Bem sucedido",
            });
            mixpanel.people.set({
              "Status do pagamento": "Status do pagamento",
            });
            let dataLayer = window.dataLayer || [];
            dataLayer.push({
              event: "pagamento",
              ecommerce: {
                fluxo: `${partnerName ?? "Isa lab"}`,
                tipo_de_pagamento: paymentType,
                status_do_pagamento: "Bem sucedido",
              },
            });
          }
          ReactPixel.track("Purchase", {
            currency: "R$",
            value: parseFloat(formatMoney(priceValue)),
          });

          history.push("/agendamento-confirmado");
        } else {
          setIsOpen(false);
          setIsDialogOpen(true);
          setTextDialog({
            header: response?.data?.message
              ? "Algo deu errado"
              : "Algo deu errado com o pagamento",
            text:
              response?.data?.message ??
              "Houve um problema com os dados enviados. Favor preencher novamente.",
            buttonText: "Voltar",
          });
          if (mixPanelOn === "true") {
            mixpanel.identify(mixToken);
            mixpanel.track("Status do pagamento", {
              pagamento: "Mal sucedido",
            });
            mixpanel.people.set({
              "Status do pagamento": "Status do pagamento",
            });
            let dataLayer = window.dataLayer || [];
            dataLayer.push({
              event: "pagamento",
              ecommerce: {
                status_do_pagamento: "Mal sucedido",
              },
            });
          }
        }
      })
    );
  };

  const handleClose = () => {
    if (textDialog.header === "Sucesso") {
      window.location.href = "https://isalab.com.br/";
    } else {
      setIsDialogOpen(false);
    }
  };

  const handleChangeCardName = (e: any) => {
    if (e.target.value.length <= 18) {
      setCardName(e.target.value.toUpperCase());
    }
  };

  const handleSelectPaymentType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentType((event.target as HTMLInputElement).value);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IsaDialog
        open={isDialogOpen}
        header={textDialog.header}
        bodyText={textDialog.text}
        buttonText={textDialog.buttonText}
        closeDialog={() => handleClose()}
      />
      <IsaLoader open={isOpen} />
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/carrinho")}
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName={"Pagamento"}
      />
      <Grid
        style={{ height: "100%" }}
        container
        direction="column"
        justifyContent="start"
        alignItems="center"
      >
        <IsaContainer>
          <div style={{ marginTop: 24 }} />
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              maxWidth={720}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <img src={PaymentSecure} alt="Ambiente Seguro" />

              <div style={{ marginTop: 26 }} />
              <IsaPText color="#666666" textAlign="start" fontWeight="700">
                Selecione a forma de pagamento
              </IsaPText>
              <div style={{ marginTop: 24 }} />
              <FormControl sx={{ marginBottom: "32px" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={paymentType}
                  onChange={handleSelectPaymentType}
                >
                  <FormControlLabel
                    value="credit_card"
                    control={<Radio />}
                    label={<IsaPText> Cartão de crédito </IsaPText>}
                  />
                  <FormControlLabel
                    value="pix"
                    control={<Radio />}
                    label={
                      <Box sx={{ display: "flex" }}>
                        <IsaPText> Pix </IsaPText>
                        <PixIcon sx={{ color: "#32BCAD", marginLeft: "8px" }} />
                      </Box>
                    }
                  />
                </RadioGroup>
              </FormControl>
              {paymentType === "credit_card" ? (
                <>
                  <Cards
                    cvc={securityCard}
                    expiry={expiry}
                    name={cardName}
                    number={nrCard}
                    focused={focused}
                  />
                  <div style={{ marginTop: 24 }} />
                  <IsaInputWButton
                    justIcon={true}
                    // icon={nrCardIcon}
                    inputTitle="Número do cartão"
                    value={creditcardMask(nrCard)}
                    onChange={(e: any) => handleChangeNrCard(e.target.value)}
                    onFocus={() => setFocused("number")}
                    onBlur={() => setFocused(undefined)}
                    type="tel"
                  />
                  <div style={{ marginTop: 16 }} />
                  <IsaInput
                    inputTitle="Nome e sobrenome no cartão"
                    value={cardName}
                    onChange={(e: any) => handleChangeCardName(e)}
                    onFocus={() => setFocused("name")}
                    onBlur={() => setFocused(undefined)}
                  />
                  <div style={{ marginTop: 16 }} />
                  <RowDiv>
                    <div style={{ marginRight: 24 }}>
                      <IsaInput
                        justIcon={true}
                        icon={nrCardIcon}
                        inputTitle="Validade"
                        value={expiry}
                        onChange={(e: any) => setValidate(e.target.value)}
                        onFocus={() => setFocused("expiry")}
                        onBlur={() => setFocused(undefined)}
                        type="tel"
                      />
                    </div>
                    <div>
                      <IsaInput
                        inputTitle="Código de segurança"
                        type={
                          size.width < 940 && size.width !== 0
                            ? "number"
                            : "tel"
                        }
                        value={securityCard}
                        onChange={(e: any) => setSecurityCode(e.target.value)}
                        onFocus={() => setFocused("cvc")}
                        onBlur={() => setFocused(undefined)}
                      />
                    </div>
                  </RowDiv>
                  <div style={{ marginTop: 16 }} />
                  <SelectorRow width="100%" marginRight="0px">
                    <IsaSelector
                      inputTitle="Parcela em..."
                      value={portion}
                      onClick={() =>
                        setOpenPortionSelector(!openPortionSelector)
                      }
                      openSelector={openPortionSelector}
                    >
                      {openPortionSelector === true ? (
                        <>
                          {priceValue <= 6000 ? (
                            <IsaOption
                              onClick={() => handleSelectPortion("1x")}
                            >
                              1x
                            </IsaOption>
                          ) : (
                            parcelas.list.map((item: any) => {
                              return (
                                <IsaOption
                                  onClick={() =>
                                    handleSelectPortion(item.parcela)
                                  }
                                >
                                  {item.parcela}
                                </IsaOption>
                              );
                            })
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </IsaSelector>
                  </SelectorRow>
                </>
              ) : (
                <>
                  <IsaH3Text color="#666666"> Pagamento via Pix</IsaH3Text>
                  <br />
                  <br />
                  <IsaPText
                    fontSize="16px"
                    fontWeight="normal"
                    textAlign="start"
                  >
                    É seguro e fácil fazer seu pagamento via Pix na ISA.
                    <br />
                    <br />
                    Ao confirmar o pagamento com Pix você visualizará duas
                    opções de pagamento:
                    <br />
                    <br />
                    <b>
                      <ul>
                        ● Pelo QR code, que você pode escanear com a câmera do
                        seu celular.
                      </ul>
                      <ul>● Ou copiar e colar o código Pix</ul>
                    </b>
                    <br />
                    <br />
                    Para pagar basta acessar seu aplicativo de pagamentos ou o
                    aplicativo do banco e escolher a opção de pagar com Pix
                    escaneando o QR Code ou colando o código Pix.
                    <br />
                    <br />A transação é segura e feita em segundos.
                  </IsaPText>
                </>
              )}

              <div style={{ marginTop: 32 }} />
              <IsaButton
                disabled={isDisabled() && paymentType === "credit_card"}
                onClick={() => handleConfirm()}
              >
                Confirmar
              </IsaButton>
              <div style={{ marginTop: 21 }} />
            </Grid>
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
