import {
  LIST_PRODUCTS,
  SET_SERVICE_ATTRIBUTES,
  GET_FIND_PRODUCT
} from "./selectedConstants";

import { apiIsa, authToken } from '../../../core/services/api'

const listProductsEnd = (dispatch: any, data: any) => {
  dispatch({ type: LIST_PRODUCTS, data })
};

const getFindProductEnd = (dispatch: any, data: any) => {
  dispatch({ type: GET_FIND_PRODUCT, data })
};

export const setServiceAttributes = (data: any) => {
  return (dispatch: any) => {
    dispatch({ type: SET_SERVICE_ATTRIBUTES, data })
  }
};

export const listProducts = (productGroupId: any, callback: any) => {
  return (dispatch: any) => {
    apiIsa.get(`/product?groupIds=${productGroupId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${authToken}`,
      }
    })
      .then((responseJson: any) => {
        listProductsEnd(dispatch, responseJson?.data)
        callback(responseJson?.data)
      })
      .catch((err) => {
        callback(err.message);
      });
  };
};

export const getFindProduct = (product: any, callback: any) => {
  return (dispatch: any) => {
    getFindProductEnd(dispatch, product)
    callback(product)
  };
};