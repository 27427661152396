import { combineReducers } from 'redux';
import ShopReducer from '../../shared/redux/shop/shopReducer'
import SelectedReducer from '../../features/selectedService/redux/selectedReducer';
import InitialReducer from '../../features/initial/redux/initialReducer';
import SelectHealthInsuranceReducer from '../../features/selectHealthInsurance/redux/selectHealthInsuranceReducer';
import AddressReducer from '../../features/addressCep/redux/addressReducer';
import SchedulingReducer from '../../features/scheduling/redux/schedulingReducer';
import PatientReducer from '../../features/patientData/redux/patientReducer';
import SendDocumentsReducer from '../../features/sendDocuments/redux/sendDocumentsReducer';
import AccountReducer from '../../features/account/redux/accountReducer';
import CreateNewPasswordReducer from '../../features/createNewPassword/redux/createNewPasswordReducer'
import CartReducer from '../../features/cart/redux/cartReducer';
import ConfirmationReducer from '../../features/confirmation/redux/confirmationReducer';

export default combineReducers({ 
  ShopReducer: ShopReducer,
  SelectedReducer: SelectedReducer,
  InitialReducer: InitialReducer,
  SelectHealthInsuranceReducer: SelectHealthInsuranceReducer,
  AddressReducer: AddressReducer,
  SchedulingReducer: SchedulingReducer,
  PatientReducer: PatientReducer,
  SendDocumentsReducer: SendDocumentsReducer,
  AccountReducer: AccountReducer,
  CreateNewPasswordReducer: CreateNewPasswordReducer,
  CartReducer: CartReducer,
  ConfirmationReducer: ConfirmationReducer
});