import {
  SET_DESTINATION_PATH,
  GET_OPERATOR,
  SAVE_PLAN_INFOS
} from './selectHealthInsuranceConstants';

const INITIAL_STATE = {
  getDestinationPath: '',
  listOperators: [],
  planInfos: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_DESTINATION_PATH:
      var _getDestinationPath
      _getDestinationPath = action.data

      return {
        ...state,
        getDestinationPath: _getDestinationPath
      }

    case GET_OPERATOR:
      var _listOperators
      _listOperators = action.data
      return {
        ...state,
        listOperators: _listOperators
      }
      
    case SAVE_PLAN_INFOS:
      var _planInfos
      _planInfos = action.data
      const session = JSON.stringify(_planInfos)
      localStorage.setItem('planInfos', session)
      return {
        ...state,
        planInfos: _planInfos
      }
  }

  return state;
};