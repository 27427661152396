/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { apiIsaV2, authToken, mixPanelOn } from "../../core/services/api";

//Interface
import { ShowMoreProps } from "./selectedServiceInterface";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  setProductsToCart,
  setTeste,
} from "../../shared/redux/shop/shopActions";
import { isExpValid } from "../account/redux/accountActions";
import { getFindProduct } from "./redux/selectedActions";

//Local Imports
import { IsaButton } from "../../shared/components/IsaButton/IsaButton";
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";
import IsaLoader from "../../shared/components/IsaLoader/isaLoader";
import { IsaSearch } from "../../shared/components/IsaSearch/IsaSearch";
import { formatMoney } from "../../shared/validators/formatMoney";

//Logo
import IcoBack from "../../shared/assets/ico_back.svg";
import IcoCompra from "../../shared/assets/ico_compra.svg";

//Css
import { ShowMoreDialog } from "./components/showMore/showMore";
import "./selectedService.css";
import {
  BorderIcon,
  Button,
  ButtonDiv,
  DescriptionText,
  DiscountContainer,
  ShowMore,
} from "./selectedServiceStyle";

interface ProductByModalitiesProps {
  id: string;
  name: string;
  price: number;
  description: null | undefined;
  exams: [
    {
      id: number;
      name: string;
      code: string;
      tuss: null | undefined;
      description: string;
      result_time: null | undefined;
      preparation: string;
      is_required_prescription: boolean;
      prevention: null | undefined;
      method_application: null | undefined;
      indication: null | undefined;
      against_indication: null | undefined;
      need_fasting: null | undefined;
      product_exam: {
        created_at: string;
        updated_at: string;
        product_id: string;
        exam_id: number;
      };
    }
  ];
  productGroup: string;
}

declare const window: any;

export function SelectedService() {
  const history = useHistory();
  const dispatch = useDispatch();
  const testee = useSelector((state: any) => state.ShopReducer?.teste ?? 0);
  const listOfProducts = JSON.parse(
    localStorage.getItem("listOfProducts") ?? "[]"
  );
  const getListProducts = useSelector(
    (state: any) => state.SelectedReducer?.listProducts ?? []
  );
  const setServiceAttributes =
    localStorage.getItem("setServiceAttributes") &&
    JSON.parse(localStorage.getItem("setServiceAttributes") ?? "");
  const partnerDiscount: any = localStorage.getItem("partnerDiscount");
  const partnerName = localStorage.getItem("partnerName") ?? null;
  const partnerInfo = JSON.parse(localStorage.getItem("partnerInfo") ?? "null");
  const operationInfo = JSON.parse(
    localStorage.getItem("operationInfo") ??
    JSON.stringify({ id: process.env.REACT_APP_STANDARD_SQUARE })
  );
  const [list, setList] = useState(listOfProducts);
  const [listToShow, setListToShow] = useState<any>([]);
  const [listToShowV2, setListToShowV2] = useState<any>([]);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const mixToken: string = localStorage.getItem("mixPanelToken") ?? "";
  const [showMore, setShowMore] = useState(false);
  const [productsByModalities, setProductsByModalities] = useState([]);

  useEffect(() => {
    dispatch(isExpValid());
  }, []);

  useEffect(() => {
    getListOfProductsByModalities();
  }, []);

  // useEffect(() => {
  //   setIsLoaderOpen(true);
  //   const serviceId =
  //     partnerName === "OYA Care"
  //       ? "32f77171-d62a-470c-a0c2-ff8c9ca1d141"
  //       : setServiceAttributes.serviceId;
  //   dispatch(
  //     listProducts(serviceId, (res: any) => {
  //       setIsLoaderOpen(false);
  //     })
  //   );
  // }, []);

  useEffect(() => {
    setListToShow(getListProducts);
  }, [getListProducts]);

  useEffect(() => {
    dispatch(setProductsToCart(list));
    dispatch(setTeste(testee + 1));
  }, [list]);

  const getListOfProductsByModalities = async () => {
    setIsLoaderOpen(true);
    try {
      const res = await apiIsaV2.get(`/operations/${operationInfo.id}`, {
        params: {
          include: "products",
          "filter[partnerId]": partnerInfo?.id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken ?? "",
        },
      });

      const listOfProducts = res.data.included
        .filter((include: { type: string }) => include.type === "product")
        .filter(
          (include: any) =>
            include.relationships?.product_groups?.data?.id ===
            setServiceAttributes.serviceId
        )
        .map((product: any) => {
          return product.attributes.is_active
            ? {
              id: product?.id,
              name: product?.attributes?.name,
              price: product?.attributes?.price,
              groupId: product?.productGroup?.id,
              description: product?.attributes?.description,
              exams: product?.attributes?.exams,
              productGroup: product?.relationships?.product_groups?.data?.id,
            }
            : null;
        });

      // setProductsByModalities(listOfProducts);
      setProductsByModalities(listOfProducts);
      setListToShowV2(listOfProducts);
    } catch (err: any) {
      console.log(err.response);
    } finally {
      setIsLoaderOpen(false);
    }
  };

  const filterList = (text: any) => {
    const removeAccents = (str: string) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    let newText = removeAccents((text?.toUpperCase() ?? "") as string);
    let filteredList = newText
      ? productsByModalities.filter((item: any) => {
        const itemNameWithoutAccents = removeAccents(item.name.toUpperCase());
        return itemNameWithoutAccents.includes(newText);
      })
      : productsByModalities;

    setTimeout(() => {
      setListToShowV2(filteredList);
    }, 500);
  };

  const handleAddToCart = async (select: any, price: any) => {
    const found = list.find((item: any) => item.id === select.id);
    if (!found) {
      const array = list;
      const nArray = {
        id: select.id,
        name: select.name,
        price: price,
        productExam: select.exams[0],
        productGroupId: select.productGroup,
        count: 1,
      };
      array.push(nArray);
      setList(array);
      setSnackOpen(true);
    }
    dispatch(setProductsToCart(list));
  };

  const handleClick = () => {
    let _url = window.location.href.split("/");
    const url = _url[3].replaceAll("#", "");
    if (url === "paguemenos") {
      history.push("/");
    } else {
      history.push("/categorias");
    }
  };

  const handleNext = () => {
    if (mixPanelOn === "true") {
      mixpanel.identify(mixToken);
      mixpanel.track(
        "Lista de produtos",
        {
          fluxo: `${partnerName !== "null" ?? "Isa lab"}`,
        },
        list.map((item: any) => {
          return {
            produto: item.name,
            valor: formatMoney(item.price),
          };
        })
      );
      mixpanel.people.set({ "Lista de produtos": "Lista de produtos" });
      let dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "lista_de_produtos",
        ecommerce: {
          fluxo: `${partnerName ?? "Isa lab"}`,
          lista_de_produtos: list.map((item: any) => {
            return {
              produto: item.name,
              valor: formatMoney(item.price),
            };
          }),
        },
      });
    }
    history.push("/carrinho-inicial");
  };

  const handleShowMore = (item: ShowMoreProps | any) => {
    setIsLoaderOpen(true);
    dispatch(
      getFindProduct(item, (res: any) => {
        setIsLoaderOpen(false);
        setShowMore(true);
      })
    );
  };

  const setValue = (item: any) => {
    if (item > 0) {
      return formatMoney(item - item * (partnerDiscount / 100));
    } else {
      return "0,00";
    }
  };

  type Product = {
    name: string;
    price: number;
    description: string;
    exams: Exam[];
  };

  type Exam = {
    description: string;
    resultTime: string;
  };

  const getProductDescription = ({ description, exams }: Product) => {
    return description ?? [...exams].shift()?.description;
  };

  const listProductsByModalities = () => {
    return (
      <Grid
        container
        maxWidth={720}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {listToShowV2.map((item: ProductByModalitiesProps, index: number) => {
          return (
            <Button key={index}>
              <div style={{ marginTop: 24, width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 8,
                  }}
                >
                  <div style={{ marginRight: 28 }}>
                    <h5>{item.name}</h5>
                    {item?.description || item?.exams[0].description ? (
                      <h6>
                        •
                        <div style={{ paddingLeft: 2 }}>
                          <DescriptionText>
                            {item?.description || item?.exams[0].description}
                          </DescriptionText>
                        </div>
                      </h6>
                    ) : (
                      ""
                    )}
                  </div>
                  <BorderIcon
                    key={index}
                    onClick={() =>
                      handleAddToCart(
                        item,
                        partnerDiscount !== "null" && partnerDiscount !== "0"
                          ? item.price - item.price * (partnerDiscount / 100)
                          : item.price
                      )
                    }
                  >
                    <img alt="icon" src={IcoCompra} />
                  </BorderIcon>
                </div>
                <ShowMore
                  onClick={() => {
                    handleShowMore(item);
                  }}
                >
                  Saiba Mais
                </ShowMore>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 18,
                  }}
                >
                  <p>
                    <span>R$</span>
                    {partnerDiscount !== "null" && partnerDiscount !== "0"
                      ? setValue(item.price)
                      : formatMoney(item.price)}
                  </p>
                  {partnerDiscount !== "null" && partnerDiscount !== "0" ? (
                    <DiscountContainer>
                      {`${partnerDiscount}% OFF ${partnerName}`}
                    </DiscountContainer>
                  ) : (
                    ""
                  )}
                </div>
                <div style={{ marginTop: 24 }} />
              </div>
            </Button>
          );
        })}
      </Grid>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ShowMoreDialog
        open={showMore}
        handleClose={() => {
          setShowMore(false);
        }}
      />
      <IsaLoader open={isLoaderOpen} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackOpen}
        autoHideDuration={3000}
        onClose={() => setSnackOpen(false)}
        message="Item adicionado ao carrinho"
        action={
          <>
            <IconButton
              style={{ color: "#00C643" }}
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() => setSnackOpen(false)}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </>
        }
      />
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => handleClick()}
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName={setServiceAttributes.pageName ?? ""}
      />
      <Grid
        style={{ marginTop: 32 }}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box style={{ width: "700px", padding: "0px 26px" }}>
          <IsaSearch
            placeholder="Busque pelo nome ou termo"
            onChange={(e: any) => filterList(e.target.value)}
          />
        </Box>
      </Grid>
      {listToShowV2.length > 0 ? (
        <Grid
          style={{ height: "100%" }}
          container
          direction="column"
          justifyContent="start"
          alignItems="center"
        >
          <Grid
            container
            maxWidth={720}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <IsaContainer>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                {/* {listProductsByPartner()} */}
                {listProductsByModalities()}
              </Grid>
              <div style={{ marginBottom: "32px" }} />
              <ButtonDiv>
                <IsaButton
                  disabled={listOfProducts.length <= 0}
                  onClick={() => handleNext()}
                >
                  Ir para o carrinho
                </IsaButton>
              </ButtonDiv>
            </IsaContainer>
          </Grid>
        </Grid>
      ) : (
        <>
          {!isLoaderOpen && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <h5>Nenhum produto encontrado...</h5>
            </Grid>
          )}
        </>
      )}
    </Box>
  );
}
