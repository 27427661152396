/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { Box, Grid } from "@material-ui/core";
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { useHistory } from "react-router";
import { formatMoney } from "../../shared/validators/formatMoney";

//Redux
import { useDispatch, useSelector } from "react-redux";

//Logo
import IcoBack from "../../shared/assets/ico_back.svg";

//Css
import {
  Button,
  // AddOrRemove,
  TotalContainer,
  SubtotalContainer,
  CouponContainer,
} from "./cartStyle";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";
import { IsaH4Text } from "../../shared/components/IsaH4Text/IsaH4Text";
import { IsaPText } from "../../shared/components/IsaPText/IsaPText";
import { IsaButton } from "../../shared/components/IsaButton/IsaButton";
import {
  savePriceValue,
  // setProductsToCart,
  setTeste,
} from "../../shared/redux/shop/shopActions";
import { isExpValid } from "../account/redux/accountActions";
import { mixPanelOn } from "../../core/services/api";
import mixpanel from "mixpanel-browser";
import { IsaInput } from "../../shared/components/IsaInput/isaInput";
import { setValidateCoupon } from "./redux/cartActions";
import IsaLoader from "../../shared/components/IsaLoader/isaLoader";
import { FreeShipping } from "../../shared/components/FreeShipping/FreeShipping";
import { FloatingButton } from "../../shared/components/FloatingButton/FloatingButton";
import { Divider } from "../patientData/patientDataStyle";

declare const window: any;

export function Cart(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();

  const listOfProducts = JSON.parse(
    localStorage.getItem("listOfProducts") ?? "[]"
  );
  const testee = useSelector((state: any) => state.ShopReducer?.teste ?? 0);

  const [finalValue, setFinalValue] = useState(0);
  const [list] = useState(listOfProducts);
  const isTokenValid = useSelector(
    (state: any) => state.AccountReducer?.isTokenValid
  );
  const mixToken: string = localStorage.getItem("mixPanelToken") ?? "";
  const discount = JSON.parse(localStorage.getItem("discount") ?? "");
  const partnerDiscount: any = localStorage.getItem("partnerDiscount");
  const [coupon, setCoupon] = useState("");
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [colorInput, setColorInput] = useState({
    background: "#FFF",
    borderColor: "#DDDDDD",
    border: "#DDDDDD",
    color: "#666666",
  });
  const partnerName = localStorage.getItem("partnerName");
  useEffect(() => {
    dispatch(isExpValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isTokenValid) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let sum = 0;
    for (let i in listOfProducts) {
      sum += listOfProducts[i].price * listOfProducts[i].count;
    }
    return setFinalValue(sum);
  }, [listOfProducts]);

  // Função paraadicionar ou remover produtos multiplos
  // const handleAdd = (select:any) => {
  //   dispatch(setTeste(testee+1))
  //   const found = list.findIndex((item: any) => item.id === select.id);
  //   list[found].count = list[found].count + 1
  //   dispatch(setProductsToCart(list))
  // }
  // const handleRemove = (select:any) => {
  //   dispatch(setTeste(testee-1))
  //   const found = list.findIndex((item: any) => item.id === select.id);

  //   if(list[found].count <= 1){
  //     list.splice(found, 1)
  //   }else{
  //     list[found].count = list[found].count - 1
  //   }
  //   dispatch(setProductsToCart(list))
  // }

  const setValidate = () => {
    setLoading(true);
    dispatch(
      setValidateCoupon(coupon.toUpperCase(), (res: any) => {
        setLoading(false);
        if (res.status !== 200) {
          setColorInput({
            background: "rgba(233, 59, 90, 0.05)",
            borderColor: "#E93B5A",
            border: "#E93B5A",
            color: "#E93B5A",
          });
          setIsCouponValid(false);
          setCoupon("Cupom inválido");
          localStorage.setItem("discount", '""');
        } else {
          if (res.data.isValid) {
            setColorInput({
              background: "rgba(0, 198, 67, 0.05)",
              borderColor: "#00C643",
              border: "#00C643",
              color: "#666666",
            });
            setIsCouponValid(true);
          } else {
            setColorInput({
              background: "rgba(233, 59, 90, 0.05)",
              borderColor: "#E93B5A",
              border: "#E93B5A",
              color: "#E93B5A",
            });
            setIsCouponValid(false);
            setCoupon("Cupom inválido");
          }
        }
      })
    );
  };

  const setFocusFunction = () => {
    setCoupon("");
    localStorage.setItem("discount", '""');
    setIsCouponValid(false);
    dispatch(setTeste(testee - 1));
    dispatch(savePriceValue(finalValue));
  };

  const handleContinue = () => {
    setLoading(true);
    dispatch(
      savePriceValue(
        discount !== ""
          ? finalValue - finalValue * (discount.percentage / 100)
          : finalValue
      )
    );
    if (mixPanelOn === "true") {
      mixpanel.identify(mixToken);
      mixpanel.track(
        "Carrinho resumo",
        {
          fluxo: `${partnerName ?? "Isa lab"}`,
          cupom: `${coupon !== "" ? coupon : undefined}`,
          "valor final": formatMoney(finalValue),
        },
        listOfProducts.map((item: any) => {
          return {
            produto: item.name,
            valor: item.price,
          };
        })
      );
      mixpanel.people.set({ "Carrinho resumo": "Carrinho resumo" });

      let dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "carrinho_resumo",
        ecommerce: {
          fluxo: `${partnerName ?? "Isa lab"}`,
          cupom: `${coupon !== "" ? coupon : undefined}`,
          valor_final: formatMoney(finalValue),
          lista_de_produtos: {
            fluxo: `${partnerName ?? "Isa lab"}`,
            lista_de_produtos: JSON.stringify(
              list.map((item: any) => {
                return {
                  produto: item.name,
                  valor: formatMoney(item.price),
                };
              })
            ),
          },
        },
      });
    }
    setTimeout(() => {
      history.push("/confirmacao");
    }, 1500);
  };
  const couponCalculate: any = finalValue * (discount.percentage / 100);
  const couponValue = parseInt(couponCalculate);
  const finalValueWithDiscountCalculate: any =
    finalValue - finalValue * (discount.percentage / 100);
  const finalValueWithDiscount = parseInt(finalValueWithDiscountCalculate);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IsaLoader open={loading} />
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/dados-do-paciente")}
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName="Carrinho"
      />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ width: "100%" }}
      >
        <div style={{ marginTop: 32 }} />
        <IsaContainer display="flex" justifyContent="center">
          <div style={{ marginTop: 32 }} />
          <Grid
            container
            maxWidth={720}
            direction="column"
            justifyContent="start"
            alignItems="center"
          >
            <IsaH4Text>Seu Pedido</IsaH4Text>
            {listOfProducts.map((item: any, index: number) => {
              return (
                <Button key={index} data-test={`product-${index}-test-id`}>
                  <div style={{ width: "80%" }}>
                    <h5>{item.name}</h5>
                    <p>
                      <span>R$</span>
                      {item.price > 0
                        ? formatMoney(item.price * item.count)
                        : "0,00"}
                    </p>
                  </div>
                  {/* <AddOrRemove>
                    <button onClick={() => handleRemove(item)}>-</button>
                    <p>{item.count}</p>
                    <button onClick={() => handleAdd(item)}>+</button>
                  </AddOrRemove> */}
                </Button>
              );
            })}
            {list.length === 0 ? (
              <div style={{ marginTop: 32 }}>
                <IsaPText data-test="emptyTextCart-test-id">
                  Seu carrinho está vazio
                </IsaPText>
              </div>
            ) : (
              ""
            )}
            <div style={{ marginTop: 32 }} />
            <FreeShipping />
            <Divider />
            {partnerDiscount === "null" || partnerDiscount === "0" ? (
              <>
                <IsaPText textAlign="start">
                  Possui um código de desconto?
                  <br />
                  <b>Informe abaixo</b>
                </IsaPText>
                <div style={{ marginTop: 8 }} />
                <CouponContainer>
                  <IsaInput
                    value={coupon}
                    onChange={(e: any) => setCoupon(e.target.value)}
                    background={colorInput.background}
                    borderColor={colorInput.borderColor}
                    border={colorInput.border}
                    color={colorInput.color}
                    onFocus={() => setFocusFunction()}
                    data-test="coupon-test-id"
                  />
                  <button
                    data-test="validate-coupon-test-id"
                    onClick={() => setValidate()}
                  >
                    Validar
                  </button>
                </CouponContainer>
              </>
            ) : (
              ""
            )}

            {isCouponValid ? (
              <>
                <SubtotalContainer>
                  <p style={{ fontSize: 10 }}>
                    Cupom <b>{coupon}</b>
                  </p>
                  <p style={{ fontSize: 10 }}>
                    {discount !== ""
                      ? `-R$ ${formatMoney(couponValue)}`
                      : `-R$ ${formatMoney(finalValue)}`}
                  </p>
                </SubtotalContainer>
                <SubtotalContainer>
                  <h4>Subtotal</h4>
                  <h4>R$ {formatMoney(finalValue)}</h4>
                </SubtotalContainer>
              </>
            ) : (
              ""
            )}
            <TotalContainer>
              <p>Total</p>
              <h2 data-test="totalValue-test-id">
                <span>R$</span>
                {discount !== ""
                  ? `${formatMoney(finalValueWithDiscount)}`
                  : `${finalValue > 0 ? formatMoney(finalValue) : "0,00"}`}
              </h2>
            </TotalContainer>

            <FloatingButton
              dataTest={`nextButtonFinalCart-test-id`}
              buttonName="PRÓXIMO"
              disabled={list.length > 0 ? false : true}
              onClick={() => handleContinue()}
            />
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
