import {
  GET_OPERATOR,
  SAVE_PLAN_INFOS,
  SET_DESTINATION_PATH,
} from "./selectHealthInsuranceConstants";

import { apiIsa, authToken } from "../../../core/services/api";

export const setDestinationPath = (data: any) => {
  return (dispatch: any) => {
    dispatch({ type: SET_DESTINATION_PATH, data });
  };
};

export const savePlanInfos = (data: any) => {
  return (dispatch: any) => {
    dispatch({ type: SAVE_PLAN_INFOS, data });
  };
};

const getOperatorEnd: any = (dispatch: any, data: any) => {
  dispatch({ type: GET_OPERATOR, data });
};

export const getOperator = (callback: any) => {
  return (dispatch: any) => {
    apiIsa
      .get(`/operator?active=${true}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken}`,
        },
      })
      .then((responseJson) => {
        getOperatorEnd(dispatch, responseJson?.data);
        callback(responseJson?.data);
      })
      .catch((err) => {});
  };
};
