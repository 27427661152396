import styled from 'styled-components';

interface InputProps {
  borderColor: string,
  background: string,
}

export const DragNDropContainer = styled.div<InputProps>`
  width: 100%;
  height: 50px;
  display:flex;
  align-items:center;
  justify-content: space-between;
  background: ${props => props.background};
  border: 1px solid ${props => props.borderColor};
  box-sizing: border-box;
  border-radius: 4px;
  padding:16px;
  cursor:pointer;

  img {
    cursor:pointer;
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: normal;
    font-size: 0.875rem;
    width: 85%;
  }
  :disabled {
    background: #f3f3f3;
  }
`;

export const MessageBox = styled.div`
  background: #F2F2F2;
  border-radius: 4px;
  padding-top:16px;
  padding-bottom:16px;
  padding-left:16px;
  padding-right:30px;
  width: 100%;
  display:flex;
  align-items: center;
  justify-content:center;

  p{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    margin-left: 16px;
  }
`;

export const SupportedFilesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  margin-top: 24px;
  p{
    width:auto;
    margin-right:8px;
  }

  svg{
    margin-right:8px;
  }
`;

export const AdditionalMedicRequestButton = styled.button`
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  display:flex;
  justify-content: space-between;
  align-items:center;

  p{
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: normal;
    font-size: 0.875rem;
    width: 85%;
    color: #666666;
  }
`;