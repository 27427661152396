/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";
import { addDays, format } from "date-fns";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { savePatientData } from "./redux/patientActions";

//Local Imports
import {
  Balls,
  IsaBreadcrumbs,
} from "../../shared/components/IsaBreadcrumbs/isaBreadcrumbs";
import { IsaContainer } from "../../shared/components/IsaContainer/IsaContainer";
import { IsaHeader } from "../../shared/components/IsaHeader/isaHeader";
import IsaLoader from "../../shared/components/IsaLoader/isaLoader";

//Logo
import IcoBack from "../../shared/assets/ico_back.svg";

//Css
import mixpanel from "mixpanel-browser";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { mixPanelOn } from "../../core/services/api";
import { BaseForm } from "../../shared/components/BaseForm/BaseForm";
import { isExpValid } from "../account/redux/accountActions";
import "../scheduling/scheduling.css";
import { BoxWrapper } from "./patientDataStyle";

import { yupResolver } from "@hookform/resolvers/yup";
import { FloatingButton } from "../../shared/components/FloatingButton/FloatingButton";
import { AboutPatientForm } from "./components/aboutPatientForm";
import { PatientForm } from "./components/patientForm";
import { ResponsibleOrPaymentData } from "./components/responsibleOrPaymentData";
import {
  listOfPatientRelations,
  otherPersonSchema,
  otherPersonSchemaWithNoDoc,
  patientSchema,
  responsibleSchema,
} from "./schema";
import { DefaultValueProps, InputProps } from "./types";

interface AreTheFieldsValidProps {
  isValidFullName?: string;
}

declare const window: any;

export function PatientData() {
  const history = useHistory();
  const dispatch = useDispatch();

  const setServiceAttributesStorage = localStorage.getItem(
    "setServiceAttributes"
  );
  const setServiceAttributes = setServiceAttributesStorage
    ? JSON.parse(setServiceAttributesStorage ?? "")
    : "";

  const getAddressData = JSON.parse(
    localStorage.getItem("getAddressData") ?? ""
  );
  const patientData = JSON.parse(localStorage.getItem("patientData") ?? "");
  const loginEmail = localStorage.getItem("loginEmail") ?? "";
  const subRoute = localStorage.getItem("subRoute") ?? "";

  const [defaultValueFromPatient, setDefaultValueFromPatient] = useState<
    DefaultValueProps | undefined
  >(listOfPatientRelations[0]);
  const [isOpen, setIsOpen] = useState(false);

  const isTokenValid = useSelector(
    (state: any) => state.AccountReducer?.isTokenValid
  );
  const mixToken: string = localStorage.getItem("mixPanelToken") ?? "";
  const partnerName = localStorage.getItem("partnerName");
  //
  const [schema, setSchema] = useState<any>("");
  const [changeSchema, setChangeSchema] = useState("OTHERS");

  enum ABOUT_PATIENT {
    PATIENT = "PATIENT",
    RESPONSIBLE = "RESPONSIBLE",
    OTHER_PERSON = "OTHER_PERSON",
  }
  const combinedSchema = (selectedOption: any) => {
    if (changeSchema !== "OTHERS") {
      return otherPersonSchemaWithNoDoc;
    }
    switch (selectedOption) {
      case ABOUT_PATIENT.RESPONSIBLE:
        return responsibleSchema;
      case ABOUT_PATIENT.OTHER_PERSON:
        return otherPersonSchema;
      default:
        return patientSchema;
    }
  };

  const {
    register,
    unregister,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<InputProps>({
    resolver: yupResolver(combinedSchema(schema)),
  });

  const selectedOption = useWatch({
    control,
    name: "patientOption",
  });

  const selectedDocOption = useWatch({
    control,
    name: "documentType",
  });

  const selectedResponsibleDocumentType = useWatch({
    control,
    name: "responsibleDocumentType",
  });

  useEffect(() => {
    setValue("patientOption", "PATIENT");
  }, []);

  useEffect(() => {
    setValue("responsibleDocument", "");
  }, [selectedResponsibleDocumentType]);

  useEffect(() => {
    let findValue = listOfPatientRelations.find(
      (item) => item.value === selectedOption
    );
    setDefaultValueFromPatient(findValue);
  }, [selectedOption]);

  useEffect(() => {
    if (selectedDocOption === "PASSPORT") return setValue("document", "");
    if (selectedDocOption === "NO_DOC") {
      setChangeSchema("DOC_SCHEMA");
      setValue("document", "");
    } else {
      setSchema(selectedOption);
      setChangeSchema("OTHERS");
      clearFields();
    }
  }, [selectedOption, selectedDocOption]);

  useEffect(() => {
    dispatch(isExpValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isTokenValid) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loginEmail !== "") {
      if (patientData !== "") {
        setValue("email", patientData?.email ?? loginEmail);
      } else {
        setValue("email", loginEmail);
      }
    }
    if (patientData !== "") {
      setValue("fullName", patientData?.fullName);
      setValue("birthday", patientData?.birthDate);
      setValue("genderOption", patientData?.gender);
      setValue("document", patientData?.document);
      setValue("documentType", patientData?.documentType);
      setValue("cellphone", `${patientData?.ddd}${patientData?.phone}`);
    }
  }, []);
  const clearFields = () => {
    if (
      selectedOption === ABOUT_PATIENT.RESPONSIBLE &&
      selectedOption !== ABOUT_PATIENT.PATIENT
    ) {
      setValue("email", "");
      setValue("cellphone", "");
    }
    if (selectedOption !== ABOUT_PATIENT.RESPONSIBLE) {
      setValue("responsibleFullName", "");
      setValue("responsibleDocumentType", "");
      setValue("responsibleDocument", "");
      setValue("responsibleEmail", "");
      setValue("responsibleCellphone", "");
      setValue("responsibleRelationship", "");
    }
  };

  const showOrHideInputs = () => {
    switch (selectedOption) {
      case ABOUT_PATIENT.RESPONSIBLE:
        return [
          "fullName",
          "birthday",
          "gender",
          "documentType",
          "document",
          "responsibleFullName",
          "responsibleDocumentType",
          "responsibleDocument",
          "responsibleEmail",
          "responsibleCellphone",
          "responsibleRelationship",
        ];
      case ABOUT_PATIENT.OTHER_PERSON:
        return [
          "contactPatientEmail",
          "contactPatientCellphone",
          "fullName",
          "birthday",
          "gender",
          "documentType",
          "document",
          "email",
          "cellphone",
          "responsibleFullName",
          "responsibleDocumentType",
          "responsibleDocument",
          "responsibleEmail",
          "responsibleCellphone",
        ];
      default:
        return [
          "fullName",
          "birthday",
          "gender",
          "documentType",
          "document",
          "email",
          "cellphone",
        ];
    }
  };

  const onSubmit: SubmitHandler<InputProps> = (data) => {
    const ddd = data.cellphone.replace(/[^0-9]/g, "").substring(0, 2);
    const phone = data.cellphone.replace(/[^0-9]/g, "").substring(2, 99);
    const dateFormated = data.birthday.split("/");

    const birthday = new Date(
      `${dateFormated[2]}-${dateFormated[1]}-${dateFormated[0]}`
    );
    const newBirthdayDate = addDays(birthday, 1);

    const responsibleDDD =
      data?.responsibleCellphone !== ""
        ? data.responsibleCellphone.replace(/[^0-9]/g, "").substring(0, 2)
        : null;
    const responsiblePhone =
      data?.responsibleCellphone !== ""
        ? data.responsibleCellphone.replace(/[^0-9]/g, "").substring(2, 99)
        : null;

    const patientData = {
      fullName: data.fullName,
      birthDate: format(newBirthdayDate, "dd/MM/yyyy"),
      documentType: data.documentType,
      document: data.document,
      ddd: ddd,
      phone: phone,
      email: data.email,
      zipCode: getAddressData.address.cep,
      street: getAddressData.address.logradouro,
      streetNumber: getAddressData.number,
      neighborhood: getAddressData.address.bairro,
      complement: getAddressData.complement,
      city: getAddressData.address.localidade,
      state: getAddressData.address.uf,
      gender: data.genderOption,
      customerType: selectedOption,
      customerPatientRelation: data?.responsibleRelationship,
      customer: data.responsibleFullName
        ? {
          fullName: data.responsibleFullName,
          documentType: data.responsibleDocumentType,
          document: data.responsibleDocument,
          ddd: responsibleDDD,
          phone: responsiblePhone,
          email: data.responsibleEmail,
        }
        : null,
    };
    dispatch(savePatientData(patientData));
    if (subRoute !== "plano de saude") {
      if (mixPanelOn === "true") {
        mixpanel.identify(mixToken);
        mixpanel.track("Dados do paciente", {
          fluxo: `${partnerName ?? "Isa lab"}`,
        });
        mixpanel.people.set({ "Dados do paciente": "Particular" });
        let dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "dados_do_paciente",
          ecommerce: {
            fluxo: `${partnerName ?? "Isa lab"}`,
            tipo: "Particular",
            patientData,
          },
        });
      }
      if (setServiceAttributes?.medicalRequestNeeded) {
        history.push("/enviar-documentos");
      } else {
        history.push("/carrinho");
      }
    } else {
      if (mixPanelOn === "true") {
        mixpanel.identify(mixToken);
        mixpanel.track("Dados do paciente", {
          fluxo: `${partnerName ?? "Isa lab"}`,
        });
        mixpanel.people.set({ "Dados do paciente": "Plano de saúde" });
        let dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "dados_do_paciente",
          ecommerce: {
            fluxo: `${partnerName ?? "Isa lab"}`,
            tipo: "Plano de saúde",
            patientData,
          },
        });
      }
      history.push("/enviar-documentos");
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IsaLoader open={isOpen} />
      <IsaHeader
        backButton={
          <>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/agendamento")}
              alt="compra"
              src={IcoBack}
            />
          </>
        }
        pageName={setServiceAttributes.pageName ?? ""}
      />
      <Grid
        style={{ height: "100%" }}
        container
        direction="column"
        justifyContent="start"
        alignItems="center"
      >
        <IsaContainer>
          <IsaBreadcrumbs>
            <Balls
              marginRight="43px"
              isHaveHeader={true}
              header=""
              width="10px"
            />
            <Balls
              marginRight="0px"
              isHaveHeader={true}
              header=""
              width="10px"
            />
            <Balls
              marginRight="0px"
              isHaveHeader={true}
              header="Dados do paciente"
              width="20px"
            />
            <Balls
              marginRight="43px"
              isHaveHeader={false}
              header=""
              width="10px"
            />
            <Balls
              marginRight="0px"
              isHaveHeader={false}
              header=""
              width="10px"
            />
          </IsaBreadcrumbs>
          <div style={{ marginTop: 40 }} />
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              container
              maxWidth={720}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <BaseForm onSubmit={handleSubmit(onSubmit)}>
                <BoxWrapper>
                  <AboutPatientForm
                    label="Sobre o paciente"
                    name="patientOption"
                    register={register}
                    errors={errors}
                    defaultValue={defaultValueFromPatient}
                  />
                </BoxWrapper>
                {selectedOption !== "" && (
                  <>
                    <PatientForm
                      errors={errors}
                      register={register}
                      control={control}
                      showOrHideInputs={showOrHideInputs()}
                      defaultValue={patientData}
                      changeLabels={selectedOption}
                    />
                    {selectedOption !== ABOUT_PATIENT.PATIENT && (
                      <ResponsibleOrPaymentData
                        errors={errors}
                        register={register}
                        control={control}
                        showOrHideInputs={showOrHideInputs()}
                        defaultValue={patientData}
                      />
                    )}

                    <FloatingButton
                      dataTest={`nextButton-test-id`}
                      buttonName="Avançar"
                    />
                  </>
                )}
              </BaseForm>
            </Grid>
          </Grid>
        </IsaContainer>
      </Grid>
    </Box>
  );
}
