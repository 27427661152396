/* eslint-disable react-hooks/exhaustive-deps */
import { ClickAwayListener, Divider, Grid, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QRCode from "react-qr-code";
import ReactPixel from 'react-facebook-pixel';

//Redux
import { useDispatch, useSelector } from "react-redux";
import { setProductsToCart } from "../../../shared/redux/shop/shopActions";

//Local Imports
import { IsaButton } from "../../../shared/components/IsaButton/IsaButton";
import { IsaH3Text } from "../../../shared/components/IsaH3Text/IsaH3Text";
import { IsaPText } from "../../../shared/components/IsaPText/IsaPText";

//Stamps
import PaymentSecure from '../../../shared/assets/stamps/PaymentSecure.png'

//Logo
import IcoCalendar from '../../../shared/assets/ico_calendar.svg';
import IcoClock from '../../../shared/assets/ico_clock.svg';

//Css
import {
  CopyPixButton,
  HourDateContainer,
  QrCodeContainer
} from '../confirmedAppointmentStyle'

import { formatMoney } from "../../../shared/validators/formatMoney";
import { mixPanelOn } from "../../../core/services/api";
import mixpanel from "mixpanel-browser";

declare const window: any;

export function PixConfirmation() {
  const history = useHistory();
  const dispatch = useDispatch();

  const getDateData = JSON.parse(localStorage.getItem('getDateData') || '{}')
  const [maskDay, setMaskDay] = useState('')
  const dataAppointments = useSelector((state:any) => state.ConfirmationReducer?.dataAppointments?? [])
  const listOfProducts = JSON.parse(localStorage.getItem('listOfProducts') || '[]')
  const priceValue = JSON.parse(localStorage.getItem('priceValue') || '0')
  const loginEmail = localStorage.getItem('loginEmail') || ''
  const mixToken: string  = localStorage.getItem('mixPanelToken')??''

  const [pixCodeOpen, setPixCodeOpen] = useState(false)

  useEffect(() => {
    if(mixPanelOn === 'true'){
      mixpanel.identify(mixToken);
      mixpanel.track('Resumo',{
        'tipo de finalização': 'particular - PiX'
      });
      mixpanel.people.set({ "Resumo": `particular - PiX` });
      let dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: 'confirmacao',
        ecommerce: {
          tipo: 'particular - PiX',
        }
      });
    }
  },[])

  useEffect(() => {
    ReactPixel.trackCustom('Confirmation', {
      confirmation: 'PiX confirmation'
    })

    if(getDateData){
      const maskDay = getDateData.date?.split('/')
      if (!maskDay) return;

      setMaskDay(`${maskDay[0]}/${maskDay[1]}`)
    }
  }, []);

  const resetValues = () => {
    localStorage.setItem('route','')
    localStorage.setItem('setServiceAttributes','')
    localStorage.setItem('getAddressData','')
    localStorage.setItem('planInfos','')
    localStorage.setItem('patientData','')
    localStorage.setItem('token','')
    localStorage.setItem('exp', '')
    localStorage.setItem('subRoute','')
    localStorage.setItem('listOfProducts','')
    localStorage.setItem('priceValue','')
    localStorage.setItem('getDateData','')
    localStorage.setItem('partnerInfo','')
    dispatch(setProductsToCart([]))

    history.push('/')
  }


  const copyTextToClipboard = () => {
    setPixCodeOpen(true)
    navigator.clipboard.writeText(dataAppointments?.personId)
  }

  const closePixCodeTooltip = () => {
    setPixCodeOpen(false)
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <div style={{marginTop:24}} />
      <img src={PaymentSecure} alt="Ambiente Seguro" />
      <div style={{marginTop:32}} />
      <Grid 
        container maxWidth={720}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <IsaH3Text color='#666666'>Pagamento via Pix</IsaH3Text>
        <br/>
        <IsaPText color='#666666' fontSize='16px' fontWeight='normal' textAlign='start'>
          Acesse seu aplicativo do banco, escolha  a opção pagar via Pix e escaneie 
          o QR Code abaixo para realizar o pagamento:
        </IsaPText>
        <div style={{marginTop:24}} />
        <QrCodeContainer>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={dataAppointments?.pixQrCode}
            viewBox={`0 0 256 256`}
          />
        </QrCodeContainer>
        <div style={{marginTop:32}} />
        <IsaPText 
          color='#666666' 
          fontSize='16px' 
          fontWeight='normal' 
          textAlign='start'
        >
          Ou copie e cole o código PIX abaixo:
        </IsaPText>
        <div style={{marginTop:16}} />
        <ClickAwayListener onClickAway={closePixCodeTooltip}>
          <Tooltip
            PopperProps={{
              disablePortal: false,
            }}
            onClose={closePixCodeTooltip}
            open={pixCodeOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Código copiado com sucesso"
          >
            <CopyPixButton onClick={copyTextToClipboard}>
              <p>
                {dataAppointments?.pixQrCode}
              </p>
              <ContentCopyIcon />
            </CopyPixButton>
          </Tooltip>
        </ClickAwayListener>
        <Divider style={{ width: '100%', margin: '48px 0px' }}/>
        <IsaPText 
          color='#666666' 
          fontSize='16px' 
          fontWeight='normal' 
          textAlign='start'
        >
          Após a confirmação do pagamento, nossos coletores estarão no local, 
          horário e data informados em seu agendamento.
        </IsaPText>

        <div style={{marginTop:24}} />
        {getDateData !== '' ?
          <>
            <HourDateContainer>
            <img alt='ico' src={IcoCalendar} />
            <p>{maskDay}</p>
            </HourDateContainer>
            <div style={{marginTop:16}} />
            <HourDateContainer>
              <img alt='ico' src={IcoClock} />
              <p>{getDateData.hour}</p>
            </HourDateContainer>
          </>
          :
          <div></div>
        }
        <div style={{position: 'absolute',visibility: 'hidden',left:'-9999px'}}>
          <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 0' }}>
            <label style={{ fontSize: 18 }}>Agendamento:</label>
            <input style={{ fontSize: 14, outline: 'none', border: 'none', margin: '0 5px' }} name='AgendamentoID' value={`${dataAppointments.id}`}></input>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 0' }}>
            <label style={{ fontSize: 18 }}>Email:</label>
            <input style={{ fontSize: 14, outline: 'none', border: 'none', margin: '0 5px' }} name='Email' value={`${loginEmail}`}></input>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 0' }}>
            <label style={{ fontSize: 18 }}>Produtos:</label>
            <div>
              {
                listOfProducts.map((product: any) => {
                  return <input style={{ fontSize: 14, display: 'block', outline: 'none', border: 'none', margin: '0 5px' }} name='Produtos' value={`${product.name} - R$ ${formatMoney(product.price)}`}></input>
                })
              }
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 0' }}>
            <label style={{ fontSize: 18 }}>Total:</label>
            <input style={{ fontSize: 14, outline: 'none', border: 'none', margin: '0 5px' }} name='Total' value={`R$ ${formatMoney(priceValue)}`}></input>
          </div>
        </div>
        <div style={{marginTop:32}} />
        <IsaButton id='isaClose' onClick={() => resetValues()}>
          Voltar para home
        </IsaButton>
        <div style={{marginTop:38}} />
      </Grid>
    </Grid>
  );
}