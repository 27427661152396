export const phoneMask = (v: any) => {
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
  return v;
};

export const validateCellPhone = (cell: string) => {
  const cellLength = 11;
  const cleanedCell = cell.replace(/[^\d]/g, "");

  if (cleanedCell.length < cellLength) return false;
  switch (cell) {
    case "11111111111":
      return false;
    case "22222222222":
      return false;
    case "33333333333":
      return false;
    case "44444444444":
      return false;
    case "55555555555":
      return false;
    case "66666666666":
      return false;
    case "77777777777":
      return false;
    case "88888888888":
      return false;
    case "99999999999":
      return false;
    case "(11) 11111-1111":
      return false;
    case "(22) 22222-2222":
      return false;
    case "(33) 33333-3333":
      return false;
    case "(44) 44444-4444":
      return false;
    case "(55) 55555-5555":
      return false;
    case "(66) 66666-6666":
      return false;
    case "(77) 77777-7777":
      return false;
    case "(88) 88888-8888":
      return false;
    case "(99) 99999-9999":
      return false;
    default:
      return true;
  }
};
