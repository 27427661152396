import styled from 'styled-components';

export const DivLogo = styled.div`
  display: flex;
  flex-direction:row;
  margin-top:60px;
  align-items: center;
`;

export const TextComponent = styled.p`
  font-size:1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  text-align: center;
  color: #666666;
`;