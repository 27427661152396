import { apiIsa, authToken } from "../../../core/services/api";
import { SEND_EMAIL_TO_RECOVER } from "./resetPasswordConstants";

const sendEmailToRecoverEnd = (dispatch: any, data: any) => {
  dispatch({ type: SEND_EMAIL_TO_RECOVER, data });
};

export const sendEmailToRecover = (email: any, callback: any) => {
  return (dispatch: any) => {
    apiIsa
      .post("/user/password/reset", email, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authToken}`,
        },
      })
      .then((responseJson) => {
        sendEmailToRecoverEnd(dispatch, responseJson?.data);
        callback(responseJson?.data);
      })
      .catch((err) => {
        callback(err.response);
      });
  };
};
