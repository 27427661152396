import styled from 'styled-components'

interface InputProps {
  borderColor: string,
  background: string,
  border: string,
  color: string
}

export const Input = styled.div<InputProps>`
  width: 100%;
  height: 50px;
  background: ${props => props.background};
  border: 1px solid ${props => props.border ?? '#DDDDDD'} ;
  border-color: ${props => props.borderColor ?? '#DDDDDD'};
  box-sizing: border-box;
  border-radius: 4px;
  padding:0px 10px;
  align-content:center;
  display:flex;
  justify-content:space-between;
  color: ${props => props.color};

  input {
    border-width:0px;
    margin:10px 0px;
    outline: none;
    width:90%;
    background: transparent;
  }

  img{
    cursor:pointer;
  }

  :disabled {
    background: #f3f3f3;
  }
`;